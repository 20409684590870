import { useContext } from 'react';
import CellWithTwoLines from 'storybook-components/table/CellWithTwoLines/CellWithTwoLines';
import { getPropertyCellSubtitleInList } from 'lib/utilGetPropertyCellSubtitleInList';
import TooltipForTableCell from 'storybook-components/table/TooltipForTableCell/TooltipForTableCell';
import { LanguageContext } from '../../contexts/LanguageContext';
import { translations } from '../../elements/Translation/translations';

export const useDistributionKeyTableColumns = () => {
  const { tl } = useContext(LanguageContext);

  return [
    {
      title: tl(translations.pages.distributionKeyTable.table.headers.property),
      dataKey: 'name',
      render: (_, record) => (
        <TooltipForTableCell title={`${record.name || '—'}\n${getPropertyCellSubtitleInList(record, 'administrationType')}`}>
          <CellWithTwoLines
            firstElement={record.name || '—'}
            secondElement={getPropertyCellSubtitleInList(record, 'administrationType')}
          />
        </TooltipForTableCell>
      ),
    },
    {
      title: tl(translations.pages.distributionKeyTable.table.headers.accountant),
      dataKey: 'accountantName',
    },
    {
      title: tl(translations.pages.distributionKeyTable.table.headers.administrator),
      dataKey: 'supervisorName',
    },

  ];
};
