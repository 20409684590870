import { useContext } from 'react';
import SelectInput from 'elements/Inputs/SelectInput/SelectInput';
import { Col, Row } from 'antd';
import SmartSearch from 'elements/Inputs/SmartSearch/SmartSearch';
import { SPACE_SIZES } from 'lib/constants';
import { translations } from '../../../translations';
import { LanguageContext } from '../../../../../contexts/LanguageContext';
import {
  SpecialContributionDetailsDto, FindFilteredPropertiesUsingGETAdministrationTypesEnum,
} from '../../../../../api/accounting';
import './Section1.scss';
import { useSection1 } from './useSection1';


export default function Section1(
  setDirty: (dirty: boolean) => void,
  setLoading: (value: boolean) => void,
  specialContributionDetails: SpecialContributionDetailsDto | undefined,
  setSpecialContributionDetails: React.Dispatch<React.SetStateAction<SpecialContributionDetailsDto>>,
) {
  const { tl } = useContext(LanguageContext);
  const {
    watermarkOptions,
    onClearValidationError,
    managementCompanyEmployeesOptions,
    propertyOptions,
    searchForProperty,
    onLoadProperty,
    isSectionDisabled,
    validationStates,
    save,
    goBack,
    checkPropertyValidity,
    getValidationState,
    getValidationMessage,
    specialContributionId,
  } = useSection1(setDirty, setLoading, specialContributionDetails, setSpecialContributionDetails);

  const options = propertyOptions.map(propertyOption => ({
    key: propertyOption?.value,
    value: propertyOption?.value,
    label: propertyOption?.simpleLabel,
  }));

  return {
    sectionTitle: tl(translations.specialContribution.section.generalDataSection.title),
    sectionId: 'propertySectionId',
    sectionNumber: 1,
    openingNumber: 0,
    onSubmit: save,
    content: [
      {
        sectionId: 'PropertySelection',
        content: (
          <div className="GeneralData">
            <Row gutter={[SPACE_SIZES.xLarge, SPACE_SIZES.xLarge]}>
              <Col span={12}>
                <SmartSearch
                  label={tl(translations.specialContribution.section.generalDataSection.selectProperty)}
                  value={specialContributionDetails?.propertyHrId}
                  required
                  autoFocus
                  searchFunction={(filter: string) => { searchForProperty(filter, [FindFilteredPropertiesUsingGETAdministrationTypesEnum.WEG]); }}
                  getOneFunction={onLoadProperty}
                  onChange={(newValue: string) => {
                    if (newValue) {
                      checkPropertyValidity({ propertyHrId: newValue, onCancel: goBack });
                    }
                    if (newValue !== specialContributionDetails?.propertyHrId) {
                      setDirty(true);
                    }
                    setSpecialContributionDetails({
                      ...specialContributionDetails,
                      propertyHrId: newValue,
                    });
                  }}
                  options={options}
                  disabled={!!specialContributionId}
                />
              </Col>
              <Col span={12}>
                <SelectInput
                  className="signing-person-selection"
                  value={specialContributionDetails?.signingPersonId}
                  validationState={getValidationState(validationStates.signingPersonId)}
                  validationMessage={getValidationMessage(validationStates.signingPersonId)}
                  onChange={(value) => {
                    if (value !== specialContributionDetails?.signingPersonId) {
                      setDirty(true);
                      onClearValidationError('signingPersonId');
                    }
                    setSpecialContributionDetails({
                      ...specialContributionDetails,
                      signingPersonId: value,
                    });
                  }}
                  options={managementCompanyEmployeesOptions}
                  label={tl(translations.specialContribution.section.generalDataSection.signingPerson)}
                  allowClear
                  disabled={isSectionDisabled}
                />
              </Col>
              <Col span={12}>
                <SelectInput
                  className="watermark-selection"
                  onChange={(value) => {
                    if (value !== specialContributionDetails?.watermarkId) {
                      setDirty(true);
                    }
                    setSpecialContributionDetails({
                      ...specialContributionDetails,
                      watermarkId: value,
                    });
                  }}
                  value={specialContributionDetails?.watermarkId}
                  options={watermarkOptions}
                  label={tl(translations.specialContribution.section.generalDataSection.watermark)}
                  allowClear
                  disabled={isSectionDisabled}
                />
              </Col>
            </Row>
          </div>),
      },
    ],
  };
}
