import { useContext } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import { PropertyWegAndMvAndSevMvAdministrationTypes } from 'lib/utilMapPropertyAdministrationTypeToFrontend';
import { WkaProjectionDtoHeatingCostManagementTypeEnum } from 'api/accounting';
import { Filter } from '../../../components/Header/components/SearchBar/services/GlobalSearchContext';
import { translations as wkaTranslations } from './translations';

export default function useWkaFilters(): Filter[] {
  const { tl } = useContext(LanguageContext);
  const filters: Filter[] = [
    {
      type: 'text',
      name: tl(wkaTranslations.wkaList.filter.property),
      key: 'property',
    },
    {
      type: 'text',
      name: tl(wkaTranslations.wkaList.filter.title),
      key: 'title',
    },
    {
      type: 'date',
      name: tl(wkaTranslations.wkaList.filter.startDate),
      key: 'startDate',
    },
    {
      type: 'date',
      name: tl(wkaTranslations.wkaList.filter.endDate),
      key: 'endDate',
    },
    {
      type: 'enum',
      name: `${tl(wkaTranslations.wkaList.filter.propertyAdministrationTypes)}`,
      key: 'propertyAdministrationTypes',
      options: Object.keys(PropertyWegAndMvAndSevMvAdministrationTypes).map(value => ({
        label: tl(wkaTranslations.wkaList.filter.administrationTypeValues[value]),
        value,
      })),
      multiValue: true,
      visibleAllOptions: true,
    },
    {
      type: 'text',
      name: tl(wkaTranslations.wkaList.filter.administrator),
      key: 'supervisorName',
    },
    {
      type: 'text',
      name: tl(wkaTranslations.wkaList.filter.accountant),
      key: 'accountantName',
    },
    {
      type: 'text',
      name: tl(wkaTranslations.wkaList.filter.createdBy),
      key: 'createdBy',
    },
    {
      type: 'enum',
      name: `${tl(wkaTranslations.wkaList.filter.heatingCostManagementTypes)}`,
      key: 'heatingCostManagementTypes',
      options: Object.keys(WkaProjectionDtoHeatingCostManagementTypeEnum).map(value => ({
        label: tl(wkaTranslations.wkaList.filter.heatingCostManagementTypeValues[value]),
        value,
      })),
      multiValue: true,
      visibleAllOptions: true,
    },
    {
      type: 'text',
      name: tl(wkaTranslations.wkaList.filter.heatingCenterName),
      key: 'heatingCenterName',
    },
  ];
  return filters;
}
