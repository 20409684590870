import { useContext } from 'react';

import {
  getPropertyCellSubtitleInList,
} from 'lib/utilGetPropertyCellSubtitleInList';
import CellWithTwoLines
  from 'storybook-components/table/CellWithTwoLines/CellWithTwoLines';

import TooltipForTableCell from 'storybook-components/table/TooltipForTableCell/TooltipForTableCell';
import { LanguageContext } from '../../contexts/LanguageContext';
import {
  Column,
  FORMAT,
} from '../../elements/SmartTable/SmartTable';
import { translations } from '../../elements/Translation/translations';
import { PaymentStateCell } from './components/PaymentStateCell';

export const usePaymentListColumns = (): Column[] => {
  const { tl } = useContext(LanguageContext);

  return [
    {
      title: tl(translations.pages.payment.table.headers.state),
      dataKey: 'translatedPaymentState',
      render: (_, record) => <PaymentStateCell record={record} />,
    },
    {
      title: tl(translations.pages.payment.table.headers.property),
      dataKey: 'propertyName',
      render: (_, record) => (
        <TooltipForTableCell title={`${record.propertyName || '—'}\n${getPropertyCellSubtitleInList(record, 'propertyAdministrationType')}`}>
          <div>
            <CellWithTwoLines
              firstElement={record.propertyName || '—'}
              secondElement={getPropertyCellSubtitleInList(record, 'propertyAdministrationType')}
            />
          </div>
        </TooltipForTableCell>
      ),
    },
    {
      title: tl(translations.pages.payment.table.headers.invoiceHrId),
      dataKey: 'invoiceHrId',
    },
    {
      title: tl(translations.pages.payment.table.headers.statement),
      dataKey: 'statement',
    },
    {
      title: tl(translations.pages.payment.table.headers.businessPartner),
      dataKey: 'counterpartName',
      render: (_, record) => (
        <TooltipForTableCell title={`${record.counterpartName || '—'}\n${record.counterpartIban || '—'}`}>
          <div>
            <CellWithTwoLines
              firstElement={record.counterpartName || '—'}
              secondElement={record.counterpartIban || '—'}
            />
          </div>
        </TooltipForTableCell>
      ),
    },
    {
      title: tl(translations.pages.payment.table.headers.executionDate),
      dataKey: 'executionDate',
      format: FORMAT.DATE,
    },
    {
      title: tl(translations.pages.payment.table.headers.amount),
      format: FORMAT.CURRENCY,
      dataKey: 'amount',
    },
    {
      title: tl(translations.pages.payment.table.headers.propertyIban),
      dataKey: 'propertyIban',
      width: 230,
      defaultVisible: false,
    },
    {
      title: tl(translations.pages.payment.table.headers.propertyAccountInstitute),
      dataKey: 'propertyAccountInstitute',
      defaultVisible: false,
      sortable: false,
      render: (_, record) => (
        <TooltipForTableCell title={`${record.propertyAccountInstitute || '—'}\n${record.propertyBic || '—'}`}>
          <div>
            <CellWithTwoLines
              firstElement={record.propertyAccountInstitute || '—'}
              secondElement={record.propertyBic || '—'}
            />
          </div>
        </TooltipForTableCell>
      ),
    },
    {
      title: tl(translations.pages.payment.table.headers.accountant),
      dataKey: 'accountantName',
      defaultVisible: false,
      sortable: false,
    },
    {
      title: tl(translations.pages.payment.table.headers.administrator),
      dataKey: 'supervisorName',
      defaultVisible: false,
      sortable: false,
    },
    {
      title: tl(translations.pages.payment.table.headers.createdDate),
      dataKey: 'created',
      format: FORMAT.DATE,
      defaultVisible: false,
    },
    {
      title: tl(translations.pages.payment.table.headers.createdBy),
      dataKey: 'createdBy',
      sortable: false,
      defaultVisible: false,
    },
  ];
};
