import React, { useContext } from 'react';
import { ColorDot, DotColorEnum } from 'storybook-components/ColorDot/ColorDot';
import { LanguageContext } from 'contexts/LanguageContext';
import { BankConnection, BankConnectionInterfaceLastManualUpdateResultEnum, BankConnectionUpdateStatusEnum } from 'api/accounting';
import TooltipForTableCell from 'storybook-components/table/TooltipForTableCell/TooltipForTableCell';
import { translations } from './translations';

function getDotColor(state: BankConnectionUpdateStatusEnum) {
  switch (state) {
    case BankConnectionUpdateStatusEnum.READY:
      return DotColorEnum.GREEN;
    case BankConnectionUpdateStatusEnum.IN_PROGRESS:
      return DotColorEnum.GRAY;
    default:
      return DotColorEnum.GRAY;
  }
}


export const useBankConnectionColumns = () => {
  const { tl } = useContext(LanguageContext);

  return [
    {
      title: tl(translations.columns.state),
      dataKey: 'updateStatus',
      sortable: false,
      defaultWidth: 130,
      render: (state: any, connection: BankConnection) => {
        const hasError = connection?.interfaces[0]?.lastManualUpdate?.result
          !== BankConnectionInterfaceLastManualUpdateResultEnum.OK;
        const errorString = JSON.stringify(connection?.interfaces[0]?.lastManualUpdate);
        return (
          <TooltipForTableCell
            style={{ visibility: hasError ? 'visible' : 'hidden' }}
            title={`${tl(translations.sateErrorTooltip)}: ${errorString}`}
            placement="bottom"
          >
            <ColorDot color={hasError ? DotColorEnum.RED : getDotColor(state)}>
              {hasError ? tl(translations.states.error) : tl(translations.states[connection.updateStatus])}
            </ColorDot>
          </TooltipForTableCell>
        );
      },
    },
    {
      title: tl(translations.columns.name),
      dataKey: 'name',
      sortable: false,
    },
    {
      title: tl(translations.columns.interface),
      dataKey: 'interfaces[0].interface',
      sortable: false,
      defaultWidth: 180,
      render: (_, bankConnection: BankConnection) => (<>{bankConnection?.interfaces?.map(i => tl(translations.interfaces[i._interface])).join(', ')}</>),
    },
    {
      title: tl(translations.columns.accounts),
      dataKey: 'accountIds.length',
      sortable: false,
      defaultWidth: 170,
      render: (_, bankConnection: BankConnection) => (<>{bankConnection.accountIds?.length}</>),
    },
  ];
};
