import { UnitProjectionDto } from 'api/accounting';
import DEFAULT_DATA, { DefaultDataInterface } from 'lib/data';
import React, { createContext, useState } from 'react';


type Props = {
  children: React.ReactNode;
};

interface ParamOfCachedValue {
  propertyId: number,
  isOwnedByWeg?: boolean,
}


export const UnitsListContext = createContext<{
  unitsList: DefaultDataInterface<UnitProjectionDto[]>,
  setUnitsList: React.Dispatch<React.SetStateAction<DefaultDataInterface<UnitProjectionDto[]>>>,
  paramOfCachedValue: ParamOfCachedValue|undefined,
  setParamOfCachedValue: React.Dispatch<React.SetStateAction<ParamOfCachedValue|undefined>>,
  updateUnitsListInProgress: boolean,
  setUpdateUnitsListInProgress: React.Dispatch<React.SetStateAction<boolean>>,
  resetUnitListContext:() => void,
    } | undefined>(undefined);


const UnitsListContextProvider = ({ children }: Props) => {
  const [unitsList, setUnitsList] = useState(DEFAULT_DATA<UnitProjectionDto[]>([]));
  const [paramOfCachedValue, setParamOfCachedValue] = useState<ParamOfCachedValue>(undefined);
  const [updateUnitsListInProgress, setUpdateUnitsListInProgress] = useState<boolean>(false);

  const resetUnitListContext = () => {
    setUnitsList(prev => prev.resetToInitial());
    setParamOfCachedValue(undefined);
    setUpdateUnitsListInProgress(false);
  };

  // don't need to export paramOfFetchInProgress because it's value is stale,
  // we're using the setState(previuesValue => {...}) version of setState to get the up-to-date value
  const providerValue = {
    unitsList,
    setUnitsList,
    paramOfCachedValue,
    setParamOfCachedValue,
    updateUnitsListInProgress,
    setUpdateUnitsListInProgress,
    resetUnitListContext,
  };

  return (
    <UnitsListContext.Provider value={providerValue}>
      {children}
    </UnitsListContext.Provider>
  );
};

export default UnitsListContextProvider;
