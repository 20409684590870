import { ManualXCPCreationByServiceCompanyAllocationDtoLaborCostTypeEnum } from 'api/accounting';

export enum ServiceCompanyAllocationValidationErrorTypes {
  MISSING_VALUES,
  LABOR_COST,
  DUPLICATES,
}


export const translations = {
  notifications: {
    loadListError: {
      message: {
        en: 'Error loading transactions',
        de: 'Fehler beim Laden der Überweisungen',
      },
    },
    wontBeAllocatedSuccess: {
      message: {
        en: 'Transaction set to status ‘Won´t be allocated’.',
        de: 'Die Transaktion wurde in den Status ‘Wird nicht zugewiesen’ gesetzt.',
      },
    },
    notBookableError: {
      message: {
        en: 'Failed to mark transaction as not bookable',
        de: 'Transaktion konnte nicht als nicht buchbar markiert werden',
      },
    },
    loadError: {
      message: {
        en: 'Error loading transaction',
        de: 'Fehler beim Laden der Überweisung',
      },
    },
    loadPreviousAllocationsError: {
      message: {
        en: 'Error loading previously allocated amounts',
        de: 'Fehler beim Laden der zugeordneten Transaktionen',
      },
    },
    loadAllocationProposalsError: {
      message: {
        en: 'Error loading allocation proposals',
        de: 'Fehler beim Laden der Zuweisungs-Vorschläge.',
      },
    },
    saveError: {
      en: 'Allocation could not be made',
      de: 'Zuweisung nicht möglich',
    },
    allocationToDifferentPropertiesError: {
      en: 'Transaction can only be allocated to accounts of one property.',
      de: 'Transaktion kann nur zu Konten eines Objektes zugewiesen werden.',
    },
    partialSuccess: {
      en: 'Transaction allocated to the selected accounts',
      de: 'Die Transaktion wurde den ausgewählten Konten zugewiesen',
    },
    success: {
      en: 'Bank transaction allocated and moved to done',
      de: 'Transaktion zugewiesen und in Status "Zugewiesen" verschoben',
    },
    successOnTabChange: {
      en: 'The data you inserted was allocated successfully',
      de: 'Die von Ihnen eingegebenen Daten wurden erfolgreich zugewiesen',
    },
    errorOnTabChange: {
      en: 'The data you inserted could not be allocated',
      de: 'Die von Ihnen eingegebenen Daten konnten nicht zugewiesen werden',
    },
    loadUnitAccountError: {
      message: {
        en: 'Resource loading error',
        de: 'Fehler beim Laden der Ressource',
      },
      description: {
        en: 'Cannot load unit accounts.',
        de: 'Konten der Einheiten können nicht geladen werden.',
      },
    },
    loadBankAccountError: {
      message: {
        en: 'Failed to load bank account',
        de: 'Fehler beim Laden der Bankkonto',
      },
    },
    loadHouseMoneyAccountError: {
      message: {
        en: 'Failed to load housemoney account',
        de: 'Fehler beim Laden der Hausgeldkonto',
      },
    },
    loadReserveAccountError: {
      message: {
        en: 'Failed to load reserve accounts',
        de: 'Fehler beim Laden der Rücklagenkonten',
      },
    },
    resetAllocationError: {
      message: {
        en: 'Failed to reset allocation',
        de: 'Zuweisung konnte nicht zurückgesetzt werden',
      },
    },
    markTransactionsDuplicateError: {
      message: {
        en: 'Failed to mark transaction as duplicate',
        de: 'Transaktion konnte nicht als Duplikat markiert werden',
      },
    },
    loadAccountsError: {
      en: 'Failed to load accounts',
      de: 'Konten konnten nicht geladen werden',
    },
    loadContactsError: {
      en: 'Failed to load creditors',
      de: 'Kreditoren konnten nicht geladen werden',
    },
    getPreviousAllocationsError: {
      en: 'Failed to load previous allocations',
      de: 'Vorherige Zuweisungen konnten nicht geladen werden',
    },
    submitAllocationsError: {
      en: 'Allocation could not be made',
      de: 'Zuweisung nicht möglich',
    },
    validationErrors: {
      [ServiceCompanyAllocationValidationErrorTypes.MISSING_VALUES]: {
        en: 'You cannot submit an exchange with missing values',
        de: 'Eine Zuweisung mit fehlenden Werten kann nicht ausgeführt werden',
      },
      [ServiceCompanyAllocationValidationErrorTypes.DUPLICATES]: {
        en: 'You cannot submit two identical exchanges',
        de: 'Eine Zuweisung mit zwei identischen Werten kann nicht ausgeführt werden',
      },
      [ServiceCompanyAllocationValidationErrorTypes.LABOR_COST]: {
        en: 'Labor cost cannot be greater than allocation amount',
        de: 'Die Lohnkosten dürfen den zuzuweisenden Betrag nicht überschreiten',
      },
    },
  },
  table: {
    status: {
      en: 'Status',
      de: 'Status',
    },
    propertyHrId: {
      en: 'Property ID',
      de: 'Objekt ID',
    },
    property: {
      en: 'Property',
      de: 'Objekt',
    },
    multipleProperties: {
      en: 'Multiple properties',
      de: 'Mehrere Objekte',
    },
    administrator: {
      en: 'Administrator',
      de: 'Objektbetreuer',
    },
    internalNr: {
      en: 'Property-Nr.',
      de: 'Objekt-Nr.',
    },
    name: {
      en: 'Property name',
      de: 'Objektbezeichnung',
    },
    accountantName: {
      en: 'Accountant',
      de: 'Objektbuchhalter',
    },
    accountType: {
      en: 'Account Type',
      de: 'Kontoart',
    },
    date: {
      en: 'Date',
      de: 'Datum',
    },
    amount: {
      en: 'Amount',
      de: 'Betrag',
    },
    receipt: {
      en: 'Receipt',
      de: 'Beleg',
    },
    allocate: {
      en: 'Allocate',
      de: 'Zuweisen',
    },
    dontAllocate: {
      en: 'Ignore',
      de: 'Nicht zuweisen',
    },
    resetAllocation: {
      en: 'Reset allocation',
      de: 'Zuweisung zurücksetzen',
    },
    markDuplicate: {
      en: 'Mark duplicate',
      de: 'Duplikat markieren',
    },
    counterpartName: {
      en: 'Counterpart',
      de: 'Kontoinhaber',
    },
    propertyIban: {
      en: 'Property IBAN',
      de: 'Objekt IBAN',
    },
    bankAccountReceiver: {
      en: 'Bank Account',
      de: 'Bankkonto',
    },
    totalAmount: {
      en: 'Amount',
      de: 'Betrag',
    },
    incoming: {
      en: 'Incoming',
      de: 'Zufluss',
    },
    outgoing: {
      en: 'Outgoing',
      de: 'Abfluss',
    },
    statement: {
      en: 'Statement',
      de: 'Verwendungszweck',
    },
    noResultTitle: {
      en: 'No results found that match the filter set',
      de: 'Keine Ergebnisse gefunden, die dem Filter entsprechen',
    },
    noResultsSubtitle: {
      en: 'Good job, there are no open bank transactions left for the filter you applied',
      de: 'Gute Arbeit, es gibt keine offenen Transaktionen für den Filter, den Sie angewendet haben',
    },
  },
  accountTypes: {
    HOUSE: {
      en: 'House',
      de: 'Hausgeld',
    },
    RESERVE: {
      en: 'Reserve',
      de: 'Rücklage',
    },
    RENT: {
      en: 'Rent',
      de: 'Mietenkonto',
    },
    DEPOSIT: {
      en: 'Deposit',
      de: 'Kautionskonto',
    },
  },
  statuses: {
    UNASSIGNED: {
      en: 'Open',
      de: 'Offen',
    },
    ASSIGNED: {
      en: 'Matched',
      de: 'Zugewiesen',
    },
    WONT_BE_ALLOCATED: {
      en: 'Won\'t be allocated',
      de: 'Wird nicht zugewiesen',
    },
    PARTIALLY_BOOKED: {
      en: 'Partially',
      de: 'Teilweise zugewiesen',
    },
    DUPLICATE: {
      en: 'Duplicate',
      de: 'Duplikat',
    },
  },
  directions: {
    incoming: {
      en: 'Incoming',
      de: 'Eingehend',
    },
    outgoing: {
      en: 'Outgoing',
      de: 'Ausgehend',
    },
  },
  searchBar: {
    filter: {
      counterpartIban: {
        en: 'Counterpart IBAN',
        de: 'Kontoinhaber IBAN',
      },
      counterpartName: {
        en: 'Person / Company',
        de: 'Kontoinhaber',
      },
      wegIban: {
        en: 'Property IBAN',
        de: 'Objekt IBAN',
      },
      fromDate: {
        en: 'Date from',
        de: 'Datum von',
      },
      untilDate: {
        en: 'Date until',
        de: 'Datum bis',
      },
      direction: {
        en: 'Direction',
        de: 'Richtung',
      },
    },
  },
  bankTransactionAllocation: {
    allocateButtonLabel: {
      en: 'Execute',
      de: 'Ausführen',
    },
    allocationTypes: {
      unit_owner: {
        en: 'Owner',
        de: 'Eigentümer',
      },
      tenant: {
        en: 'Tenant',
        de: 'Mieter',
      },
      serviceCompany: {
        en: 'Service Company',
        de: 'Dienstleistungen',
      },
      prpOwner: {
        en: 'Property Owner',
        de: 'Objekteigentümer',
      },
    },
    typeSelector: {
      en: 'Define the type of the transaction',
      de: 'Wählen Sie den Typ der Transaktion ',
    },
    showMarked: {
      en: 'Show marked',
      de: 'Ausgewählte',
    },
    markedAccounts: {
      en: 'accounts',
      de: 'Konten',
    },
    markedInvoices: {
      en: 'invoices',
      de: 'Rechnungen',
    },
    unitAccountTableHeader: {
      property: {
        en: 'Property',
        de: 'Objekt',
      },
      unitName: {
        en: 'Unit-Nr.',
        de: 'Einheit-Nr.',
      },
      owner: {
        en: 'Name',
        de: 'Name',
      },
      accountCode: {
        en: 'Account code',
        de: 'Kontonummer',
      },
      accountName: {
        en: 'Account name',
        de: 'Kontobezeichnung',
      },
      vat: {
        en: 'VAT',
        de: 'MWSt.',
      },
      lastDebit: {
        en: 'Last debit amount',
        de: 'Letzte Sollstellung',
      },
      lastCredit: {
        en: 'Last credit amount',
        de: 'Letzte Habenstellung',
      },
      accountType: {
        en: 'Account type',
        de: 'Kontoart',
      },
      ownerChange: {
        en: 'Owner',
        de: 'Eigentümer',
      },
      ownerChangeValues: {
        oldOwner: {
          en: 'Old',
          de: 'Vorherige',
        },
        currentOwner: {
          en: 'Current',
          de: 'Aktuelle',
        },
      },
      accountTags: {
        houseMoney: {
          en: 'House money',
          de: 'Hausgeld',
        },
        reserveFund: {
          en: 'Reserve fund',
          de: 'Instandhaltungsrücklage',
        },
        specialContribution: {
          en: 'Special contribution',
          de: 'Sonderumlage',
        },
        unitSettlement: {
          en: 'Accounting result',
          de: 'Abrechnungsergebnis VJ',
        },
        dunningFees: {
          en: 'Dunning fees',
          de: 'Mahngebühren',
        },
        debitFee: {
          en: 'Return debit fee note',
          de: 'Rücklastschriftgebühren',
        },
        additionalService: {
          en: 'Additional service manager',
          de: 'Mehrleistung Verwalter',
        },
        directLoad: {
          en: 'Direct load',
          de: 'Direkte Belastung',
        },
      },
      sumToAllocate: {
        en: 'Sum to allocate',
        de: 'Betrag',
      },
      houseMoney: {
        en: 'House money',
        de: 'WP Hausgeld',
      },
      reserveFund: {
        en: 'Reserve fund',
        de: 'WP Rücklage',
      },
      iconTooltip: {
        en: 'Original booking will be corrected',
        de: 'Ursprüngliche Buchung wird korrigiert',
      },
    },
    manualAccountTableHeader: {
      accountCode: {
        en: 'Account code',
        de: 'Kontonummer',
      },
      accountName: {
        en: 'Account name',
        de: 'Kontobezeichnung',
      },
      sumToAllocate: {
        en: 'Sum to allocate',
        de: 'Betrag',
      },
    },
    serviceCompanyAllocation: {
      headers: {
        purpose: {
          en: 'Purpose',
          de: 'Zweck',
        },
        date: {
          en: 'Date',
          de: 'Datum',
        },
        account: {
          en: 'Account',
          de: 'Konto',
        },
        counterpart: {
          en: 'Counterpart',
          de: 'Kontoinhaber',
        },
        openAmount: {
          en: 'Open Amount',
          de: 'Offener Betrag',
        },
        sumToAllocate: {
          en: 'Sum to Allocate',
          de: 'Betrag',
        },
        laborCost: {
          en: 'Labor cost',
          de: 'Lohnkosten',
        },
        vat: {
          en: 'VAT',
          de: 'MwSt',
        },
        vatEligibility: {
          en: 'Eligible VAT',
          de: 'Anrechenbare USt.',
        },
        property: {
          en: 'Property',
          de: 'Objekt',
        },
      },
      laborCostModal: {
        title: {
          en: 'Change labor cost',
          de: 'Lohnkosten ändern',
        },
        laborCost: {
          en: 'Labor cost',
          de: 'Lohnkosten',
        },
        laborCostType: {
          en: 'Labor cost type',
          de: 'Lohnkostenart',
        },
        validation: {
          required: {
            en: 'Field is required',
            de: 'Feld ist erforderlich',
          },
        },
      },
      laborCostTypes: {
        [ManualXCPCreationByServiceCompanyAllocationDtoLaborCostTypeEnum.HOUSEHOLD_RELATED_SERVICES]: {
          en: 'Household related services',
          de: 'Haushaltsnahe DL',
        },
        [ManualXCPCreationByServiceCompanyAllocationDtoLaborCostTypeEnum.TECHNICIAN_SERVICE]: {
          en: 'Technician service',
          de: 'Dl.anteil Handwerkerrechnungen',
        },
        [ManualXCPCreationByServiceCompanyAllocationDtoLaborCostTypeEnum.MARGINAL_EMPLOYMENT]: {
          en: 'Marginal employment',
          de: 'Geringf. Beschäftigung',
        },
        [ManualXCPCreationByServiceCompanyAllocationDtoLaborCostTypeEnum.INSURABLE_EMPLOYMENT]: {
          en: 'Insurable employment',
          de: 'SV.pfl. Beschäftigung',
        },
        noLaborCost: {
          en: 'No labor cost',
          de: 'Keine Lohnkosten',
        },
      },
      createNewOrder: {
        en: 'Create new order',
        de: 'Neue Buchung erstellen',
      },
      changePurpose: {
        en: 'Change purpose',
        de: 'Zweck ändern',
      },
      notifications: {
        exceededAmountErrorMessage: {
          en: (amount: string) => `Allocation amount cannot exceed ${amount}`,
          de: (amount: string) => `Der zugewiesene Betrag darf ${amount} nicht übersteigen`,
        },
        allocationErrorMessage: {
          en: 'Something went wrong during the allocation process, redirecting you shortly...',
          de: 'Während der Zuweisung ist ein Fehler aufgetreten. Sie werden in Kürze weitergeleitet...',
        },
      },
      vatEligibilityModal: {
        title: {
          en: 'Change VAT eligibility',
          de: 'Anrechenbare USt. ändern',
        },
        vatEligibilityPercentage: {
          en: 'Eligible VAT share',
          de: 'Anteil anrechenbare USt.',
        },
        vatEligibilityAmount: {
          en: 'Eligible VAT amount',
          de: 'Anrechenbare USt.',
        },
        vatEligibilityTooltip: {
          en: 'Based on the preselected value for the property. This value can be changed manually here. A general adjustment can be made in the property settings. ',
          de: 'Berechnung basiert auf vorausgewähltem Wert für das Objekt und kann hier manuell abgeändert werden. Eine generelle Anpassung kann in den Objekteinstellungen vorgenommen werden. ',
        },
        vatEligibilityTooltipLink: {
          en: 'Learn more in our support article.',
          de: 'Erfahren Sie mehr in unserem Supportartikel.',
        },
        apply: {
          en: 'Apply',
          de: 'Speichern',
        },
      }
    },
    searchBar: {
      filter: {
        label: {
          en: 'filters applied',
          de: 'filter gesetzt',
        },
        clearAll: {
          en: 'Clear All',
          de: 'Zurücksetzen',
        },
        booleanValues: {
          true: {
            en: 'True',
            de: 'Wahr',
          },
          false: {
            en: 'False',
            de: 'Falsch',
          },
        },

      },
      placeholder: {
        en: 'Search',
        de: 'Suche',
      },
      dropdown: {
        hint: {
          en: '↑↓ Select · ENTER Open · / Next Query',
          de: '↑↓ Wählen · ENTER Öffnen · / Nächste Abfrage',
        },
      },
    },
    button: {
      en: 'Allocate Cost Now',
      de: 'Kosten zuweisen',
    },
    modal: {
      cancel: {
        en: 'Cancel',
        de: 'Abbrechen',
      },
      confirm: {
        en: 'Confirm',
        de: 'Bestätigen',
      },
      title: {
        en: 'The allocations for this transaction will be reverse booked.',
        de: 'Die Zuweisungen für diese Transaktionen werden rückgebucht.',
      },
      save: {
        en: 'Apply',
        de: 'Speichern',
      },
      bookingText: {
        en: 'Booking text',
        de: 'Buchungstext',
      },
      transaction: {
        en: 'The transaction',
        de: 'Die Transaktion',
      },
    },
    header: {
      wegDetails: {
        en: 'Property Details',
        de: 'Objekt Details',
      },
      counterpartDetails: {
        en: 'Counterpart Details',
        de: 'Kontoinhaber',
      },
      accountType: {
        en: 'Account-type',
        de: 'Kontenart',
      },
      counterpartName: {
        en: 'Name / Company',
        de: 'Kontoinhaber',
      },
      bankDetails: {
        en: 'Bank Details',
        de: 'Bank Details',
      },
      allocating: {
        en: 'Allocating',
        de: 'Verteilt',
      },
      open: {
        en: 'Open',
        de: 'Offen',
      },
      exceeded: {
        en: 'Exceeded',
        de: 'Überzogen',
      },
    },
  },
};
