import React, { useContext, useRef } from 'react';
import { useLocation } from 'react-router';
import Button from 'elements/Buttons/Button/Button';
import CellWithTwoLines from 'storybook-components/table/CellWithTwoLines/CellWithTwoLines';
import { getPropertyCellSubtitleInList } from 'lib/utilGetPropertyCellSubtitleInList';
import TooltipForTableCell from 'storybook-components/table/TooltipForTableCell/TooltipForTableCell';
import { translations } from '../../../elements/Translation/translations';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { FORMAT } from '../../../elements/SmartTable/SmartTable';
import { ColorDot, DotColorEnum } from '../../../storybook-components/ColorDot/ColorDot';
import { navigateConcat } from '../../../navigation/History';


export const useStandingOrderOverviewColumns = () => {
  const { tl } = useContext(LanguageContext);
  const location = useLocation();

  const pathName = useRef(location.pathname);
  pathName.current = location.pathname;

  return [
    {
      title: tl(translations.pages.standingOrderOverview.table.headers.state),
      dataKey: 'state',
      render: (_, record) => (
        <ColorDot color={record.stopped ? DotColorEnum.BLACK : DotColorEnum.GREEN}>
          {record.stopped ? tl(translations.pages.standingOrderOverview.table.headers.deleted) : tl(translations.pages.standingOrderOverview.table.headers.active)}
        </ColorDot>
      ),
    },
    {
      title: tl(translations.pages.standingOrderOverview.table.headers.property),
      dataKey: 'propertyName',
      defaultWidth: 200,
      render: (_, record) => (
        <TooltipForTableCell title={(`${record.propertyName || '—'}\n${getPropertyCellSubtitleInList(record, 'propertyAdministrationType')}`)}>
          <CellWithTwoLines
            firstElement={record.propertyName || '—'}
            secondElement={getPropertyCellSubtitleInList(record, 'propertyAdministrationType')}
          />
        </TooltipForTableCell>
      ),
    },
    {
      title: tl(translations.pages.standingOrderOverview.table.headers.statement),
      dataKey: 'statement',
      sortable: false,
    },
    {
      title: tl(translations.pages.standingOrderOverview.table.headers.invoice),
      dataKey: 'invoiceHrId',
      sortable: false,
      defaultWidth: 150,
      className: 'middle',
      render: (invoiceHrId: any) => (invoiceHrId ? (
        <Button
          className="invoice-link"
          type="text"
          onClick={(e) => {
            navigateConcat(`/invoice/${invoiceHrId}`);
            e.stopPropagation();
          }}
        >
          {invoiceHrId}
        </Button>
      ) : <React.Fragment />),
    },
    {
      title: tl(translations.pages.standingOrderOverview.table.headers.businessPartner),
      dataKey: 'counterpartName',
      render: (_, record) => (
        <TooltipForTableCell title={`${record.counterpartName || '—'}\n${record.counterpartIban || '—'}`}>
          <CellWithTwoLines
            firstElement={record.counterpartName || '—'}
            secondElement={record.counterpartIban || '—'}
          />
        </TooltipForTableCell>
      ),
    },
    {
      title: tl(translations.pages.standingOrderOverview.table.headers.amount),
      format: FORMAT.CURRENCY,
      dataKey: 'amount',
      sortable: false,
    },
    {
      title: tl(translations.pages.standingOrderOverview.table.headers.id),
      dataKey: 'id',
      className: 'center',
    },
    {
      title: tl(translations.pages.standingOrderOverview.table.headers.administrator),
      dataKey: 'administrator',
      defaultVisible: false,
    },
    {
      title: tl(translations.pages.standingOrderOverview.table.headers.createdBy),
      dataKey: 'createdBy',
      defaultVisible: false,
      sortable: false,
    },
    {
      title: tl(translations.pages.standingOrderOverview.table.headers.createDate),
      dataKey: 'created',
      defaultVisible: false,
    },
    {
      title: tl(translations.pages.standingOrderOverview.table.headers.startDate),
      dataKey: 'startDate',
      sortable: false,
      defaultVisible: false,
    },
    {
      title: tl(translations.pages.standingOrderOverview.table.headers.endDate),
      dataKey: 'endDate',
      sortable: false,
      defaultVisible: false,
      render: (value: string, row: any) => (
        row.endedDate ? <span>{row.endedDate}</span> : <span>{row.plannedEndDate}</span>
      ),
    },
    {
      title: tl(translations.pages.standingOrderOverview.table.headers.interval),
      dataKey: 'interval',
      sortable: false,
    },
    {
      title: tl(translations.pages.standingOrderOverview.table.headers.propertyBankAccountHolderName),
      dataKey: 'propertyBankAccountHolderName',
      sortable: false,
      defaultVisible: false,
    },
    {
      title: tl(translations.pages.standingOrderOverview.table.headers.propertyIban),
      dataKey: 'propertyIban',
      defaultVisible: false,
    },
  ];
};
