import { useContext } from 'react';
import { translations } from 'elements/Translation/translations';
import { LanguageContext } from 'contexts/LanguageContext';
import { Tooltip } from 'antd';
import { getPropertyCellSubtitleInList } from 'lib/utilGetPropertyCellSubtitleInList';
import CellWithTwoLines from 'storybook-components/table/CellWithTwoLines/CellWithTwoLines';

export const useResolutionRecordPropertyListColumns = () => {
  const { tl } = useContext(LanguageContext);

  return [
    {
      title: tl(translations.pages.property.table.headers.propertyHrId),
      dataKey: 'propertyHrId',
      defaultVisible: false,
    },
    {
      title: tl(translations.pages.property.table.headers.name),
      dataKey: 'propertyName',
      render: (_, record) => (
        <Tooltip title={`${record.name || '—'}\n${getPropertyCellSubtitleInList(record, 'propertyAdministrationType')}`}>
          {/* without this div the Tooltip doesn't work */}
          <div>
            <CellWithTwoLines
              firstElement={record.name || '—'}
              secondElement={getPropertyCellSubtitleInList(record, 'propertyAdministrationType')}
            />
          </div>
        </Tooltip>
      ),
    },
    {
      title: tl(translations.pages.resolutionRecordPropertyList.table.headers.supervisorName),
      dataKey: 'supervisorName',
    },
  ];
};
