import { useContext } from 'react';
import moment from 'moment';
import { showNotification } from '../../../../lib/Notification';
import { economicPlanTranslations } from '../../economicPlanTranslations';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { AuthContext } from '../../../../contexts/AuthContext';
import {
  EconomicPlanControllerApi, EconomicPlanDto, EconomicPlanDtoStatusEnum, EconomicPlanDtoTypeEnum,
} from '../../../../api/accounting';
import { EconomicPlanContext } from '../../services/EconomicPlanContext';
import { useSimpleDirtModal } from '../../../../elements/Modals/DirtModal/SimpleDirtModal/useSimpleDirtModal';

const DEFAULT_UNIT_FIGURES_ECONOMIC_PLAN_DATA: EconomicPlanDto = {
  id: null,
  type: EconomicPlanDtoTypeEnum.UNIT_FIGURES,
  status: EconomicPlanDtoStatusEnum.DRAFT,
  title: `Wirtschaftsplan ${moment().year() + 1}`,
};

export const useLoadUnitFiguresEconomicPlan = () => {
  const { tl } = useContext(LanguageContext);
  const { apiConfiguration } = useContext(AuthContext);
  const economicPlanControllerApi = new EconomicPlanControllerApi(apiConfiguration('accounting'));

  const economicPlanContext = useContext(EconomicPlanContext);
  if (economicPlanContext === undefined) {
    throw new Error('useLoadUnitFiguresEconomicPlan can only be used withing EconomicPlanContextProvider');
  }
  const {
    setEconomicPlan,
  } = economicPlanContext;

  const {
    clearDirty,
  } = useSimpleDirtModal();

  const loadUnitFiguresEconomicPlan = (economicPlanId: number) => {
    setEconomicPlan(prevState => prevState.startLoading());
    if (economicPlanId) {
      economicPlanControllerApi.getByIdUsingGET({ id: economicPlanId })
        .then((economicPlanFromServer) => {
          setEconomicPlan(prevState => prevState.load(economicPlanFromServer));
          clearDirty();
        })
        .catch(() => {
          setEconomicPlan(prevState => prevState.failed());
          showNotification({
            key: 'loadEconomicPlanError',
            message: tl(economicPlanTranslations.notifications.loadEconomicPlanError.message),
            description: tl(economicPlanTranslations.notifications.loadEconomicPlanError.description),
            type: 'error',
          });
        });
    } else {
      setEconomicPlan(prevState => prevState.load(DEFAULT_UNIT_FIGURES_ECONOMIC_PLAN_DATA));
      clearDirty();
    }
  };

  return {
    loadUnitFiguresEconomicPlan,
  };
};
