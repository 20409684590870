import React, { useContext } from 'react';

import { Tooltip } from 'antd';
import {
  MessageListProjectionStateEnum,
} from 'api/accounting/models/MessageListProjection';
import moment from 'moment';
import CellWithTwoLines
  from 'storybook-components/table/CellWithTwoLines/CellWithTwoLines';

import { getPropertyCellSubtitleInList } from 'lib/utilGetPropertyCellSubtitleInList';
import { Column, FORMAT } from 'elements/SmartTable/SmartTable';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import {
  DATE_FORMAT,
  formatDate,
} from '../../../../lib/Utils';
import StatusCell from '../components/StatusCell/StatusCell';
import { messagesListTranslations } from '../translations';

export const useMessagesColumns = (): Column[] => {
  const { tl } = useContext(LanguageContext);

  return [
    {
      title: tl(messagesListTranslations.header.state),
      dataKey: 'state',
      defaultWidth: '10%',
      render: (_, record) => <StatusCell record={record} />,
    },
    {
      title: tl(messagesListTranslations.header.property),
      dataKey: 'propertyName',
      sortable: false,
      render: (_, record) => (
        <Tooltip title={`${record.propertyName || '—'}\n${getPropertyCellSubtitleInList(record, 'administrationType')}`}>
          {/* without this div the Tooltip doesn't work */}
          <div>
            <CellWithTwoLines
              firstElement={record.propertyName || '—'}
              secondElement={getPropertyCellSubtitleInList(record, 'administrationType')}
            />
          </div>
        </Tooltip>
      ),
    },
    {
      title: tl(messagesListTranslations.header.title),
      dataKey: 'processName',
      sortable: false,
    },
    {
      title: tl(messagesListTranslations.header.recipient),
      dataKey: 'businessPartnerName',
      sortable: false,
      render: (_, record) => (
        <CellWithTwoLines firstElement={record.businessPartnerName} secondElement={record.contactName} />),
    },
    {
      title: tl(messagesListTranslations.header.accountant),
      dataKey: 'accountantName',
      defaultVisible: false,
    },
    {
      title: tl(messagesListTranslations.header.supervisor),
      dataKey: 'supervisorName',
      defaultVisible: false,
    },
    {
      title: tl(messagesListTranslations.header.updated),
      dataKey: 'sentDate',
      defaultWidth: '10%',
      render: (_, record) => {
        let date;
        switch (record.state) {
          case MessageListProjectionStateEnum.SENT:
            date = record.sentDate;
            break;
          case MessageListProjectionStateEnum.FAILED:
            date = record.failedDate;
            break;
          default:
            date = record.updated;
        }

        return (
          <CellWithTwoLines
            firstElement={(
              /* add the Z suffix to parse it as an UTC date */
              <Tooltip title={moment(`${date}Z`).format('DD.MM.YYYY-HH:mm:ss')} placement="bottomRight">
                <span>
                  {moment(`${date}Z`).isAfter(moment().subtract(24, 'hours'))
                    ? moment(`${date}Z`).fromNow() : formatDate(date, DATE_FORMAT)}
                </span>
              </Tooltip>
            )}
            secondElement={record.applicationName}
          />
        );
      },
    },
    {
      title: tl(messagesListTranslations.header.createdOn),
      dataKey: 'created',
      format: FORMAT.DATE,
      defaultVisible: false,
    },
    {
      title: tl(messagesListTranslations.header.createdBy),
      dataKey: 'createdBy',
      defaultVisible: false,
    },
  ];
};
