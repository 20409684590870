import { useContext } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import { translations } from 'elements/Translation/translations';
import { FORMAT } from 'elements/SmartTable/SmartTable';
import { Tooltip } from 'antd';
import { getPropertyCellSubtitleInList } from 'lib/utilGetPropertyCellSubtitleInList';
import CellWithTwoLines from 'storybook-components/table/CellWithTwoLines/CellWithTwoLines';

export const useBankAccountListColumns = () => {
  const { tl } = useContext(LanguageContext);

  return [
    {
      title: tl(translations.pages.property.table.headers.name),
      dataKey: 'propertyName',
      render: (_, record) => (
        <Tooltip title={`${record.name || '—'}\n${getPropertyCellSubtitleInList(record, 'administrationType')}`}>
          {/* without this div the Tooltip doesn't work */}
          <div>
            <CellWithTwoLines
              firstElement={record.name || '—'}
              secondElement={getPropertyCellSubtitleInList(record, 'administrationType')}
            />
          </div>
        </Tooltip>
      ),
    },
    {
      title: tl(translations.pages.bankAccount.table.headers.administrator),
      dataKey: 'supervisorName',
      sortable: false,
    },
    {
      title: tl(translations.pages.bankAccount.table.headers.calculatedHouseMoneyBalance),
      dataKey: 'calculatedHouseMoneyBalance',
      sortable: false,
      format: FORMAT.CURRENCY,
    },
    {
      title: tl(translations.pages.bankAccount.table.headers.houseMoneyBalance),
      dataKey: 'houseMoneyBalance',
      sortable: false,
      format: FORMAT.CURRENCY,
    },
    {
      title: tl(translations.pages.bankAccount.table.headers.calculatedReserveMoneyBalance),
      dataKey: 'calculatedReserveMoneyBalance',
      sortable: false,
      format: FORMAT.CURRENCY,
    },
    {
      title: tl(translations.pages.bankAccount.table.headers.reserveMoneyBalance),
      dataKey: 'reserveMoneyBalance',
      sortable: false,
      format: FORMAT.CURRENCY,
    },
    {
      title: tl(translations.pages.bankAccount.table.headers.calculatedTotalBalance),
      dataKey: 'calculatedTotalBalance',
      sortable: false,
      format: FORMAT.CURRENCY,
    },
    {
      title: tl(translations.pages.bankAccount.table.headers.totalBalance),
      dataKey: 'totalBalance',
      sortable: false,
      format: FORMAT.CURRENCY,
    },
  ];
};
