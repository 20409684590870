import './InvoiceEditorForm.scss';

import React, {
  useContext,
  useEffect,
} from 'react';
import { InvoiceEditingContext } from 'contexts/InvoiceEditingContext';
import { LanguageContext } from 'contexts/LanguageContext';
import Button from 'elements/Buttons/Button/Button';
import FormSection from 'elements/FormElements/FormSection/FormSection';
import FormSidebar from 'elements/FormElements/FormSidebar/FormSidebar';
import { translations } from 'elements/Translation/translations';
import { generateMenuFromSections } from 'lib/Utils';
import _ from 'lodash';
import moment from 'moment';

import {
  Prompt,
  useLocation,
} from 'react-router';
import { useComments } from 'services/comment/useComments';
import { Note } from 'storybook-components/note/Note';
import Page from 'storybook-components/layout/Page/Page';
import PageContent from 'storybook-components/layout/PageContent/PageContent';
import PageHeader from 'storybook-components/layout/PageHeader/PageHeader';
import {
  GetCommentsOfEntityUsingGETCommentEntityTypeEnum,
  InvoiceEpoStateEnum,
  PropertyLegacyDtoAdministrationTypeEnum,
} from '../../../../api/accounting';
import { DropMenu } from '../../../../components/DropMenu/DropMenu';
import { InvoiceListContext } from '../../../../contexts/InvoiceListContext';
import DirtModal from '../../../../elements/Modals/DirtModal/DirtModal';
import {
  OverlayContext,
} from '../../../../services/OverlayContext/OverlayContext';
import { PostingWarningModal } from '../../../../storybook-components/modals/PostingWarningModal/PostingWarningModal';
import usePostingHGAValidation
  from '../../../../storybook-components/modals/PostingWarningModal/usePostingHGAValidation';
import creditorSection2 from '../sections/creditorSection2';
import {
  useIntegrationIsSupported,
  useInvoiceIntegrationsSection,
} from '../sections/integrations/useInvoiceIntegrationsSection';
import {
  useInvoiceBookingSection,
} from '../sections/invoiceBooking/useInvoiceBookingSection';
import invoiceProcessingSection1 from '../sections/invoiceProcessingSection/invoiceProcessingSection1';
import paymentInstructionSection
  from '../sections/paymentInstruction/paymentInstructionSection';
import { translations as invoiceEditingTranslations } from '../translations';
import { useRevertInvoice } from './services/useRevertInvoice';
import { useInitializeInvoiceEditor } from './services/useInitializeInvoiceEditor';
import InvoiceEditorMiscDataContextProvider, { AfterSaveAction, useInvoiceEditorMiscDataContext } from './services/InvoiceEditorMiscDataContext';
import { useInvoiceEditorForm } from './services/useInvoiceEditorForm';
import { Modal } from 'antd';


interface InvoiceEditorFormProps {
  showMenu?: boolean;
  backPath?: string;
  viewMode: 'fullscreen' | 'withPdf';
}

const InvoiceEditorFormWithoutProvider = (props: InvoiceEditorFormProps) => {
  const { showMenu, viewMode } = props;
  const { goBack } = useContext(OverlayContext);

  const invoiceEditingContext: any = useContext(InvoiceEditingContext);
  const invoiceListContext: any = useContext(InvoiceListContext);

  const { tl } = useContext(LanguageContext);
  const {
    isDirty,
    setDirty,
    editingBookings,
    setEditingBookings,
  } = invoiceEditingContext;

  const {
    dirtModalVisible, setDirtModalVisible,
    openSectionIndex,
  } = useInvoiceEditorMiscDataContext('useInitializeInvoiceEditor');

  const { onRevertInvoice } = useRevertInvoice();

  useInitializeInvoiceEditor();

  const location = useLocation();
  const {
    visible, isHgaClosed, onClickProceed, onClickReview, onClickCancel, showModal, loading,
  } = usePostingHGAValidation({
    propertyId: invoiceEditingContext.data.invoice.propertyId,
    date: invoiceEditingContext.data.invoice.invoiceDate ? moment(invoiceEditingContext.data.invoice.invoiceDate, 'YYYY-MM-DD') : undefined,
  });
  const { comments } = useComments(GetCommentsOfEntityUsingGETCommentEntityTypeEnum.INVOICE, invoiceEditingContext.data.invoice?.id);

  useEffect(() => {
    if (openSectionIndex >= 0) {
      const sectionId = sections[openSectionIndex] ? sections[openSectionIndex].sectionId : null;
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [openSectionIndex]);

  const {
    editable,
    bookingsEditable,
    isCreditNote,
    invoiceState,
    handleOpenSectionChange,
    save,
    saveAsDraft,
    handleDirtModalSave,
    handleDirtModalDiscard,
    handleConfirmationModalSave,
    handleConfirmationModalDiscard,
    onClickApproveInvoice,
    setAutoSelectCompany,
    handleStopRecurring,
  } = useInvoiceEditorForm();


  let sections: any = [
    invoiceProcessingSection1(!editable),
    creditorSection2({
      setDirty,
      setAutoSelectCompany,
      viewMode,
    }),
    useInvoiceBookingSection({
      sectionNumber: 3,
      propertyHrId: invoiceEditingContext.data.invoice.propertyHrId,
      invoiceBookings: invoiceEditingContext.invoiceBookings,
      dispatch: invoiceEditingContext.changeInvoiceBookings,
      total: invoiceEditingContext.data.invoice.totalGross,
      laborCostTypeOptions: invoiceEditingContext.laborCostTypes,
      state: invoiceState,
      disabled: !editable && !editingBookings,
      bookingDateOverwriteDisabled: invoiceEditingContext.data.invoice.state !== InvoiceEpoStateEnum.NEW && invoiceEditingContext.data.invoice.state !== InvoiceEpoStateEnum.READY_FOR_APPROVAL,
      invoiceDate: invoiceEditingContext.data.invoice.invoiceDate,
      viewMode,
      propertyVatEligibilityShare: invoiceEditingContext.vatEligibilityShare,
    }),
    paymentInstructionSection(!isCreditNote),
  ];

  const integrationSection = useInvoiceIntegrationsSection(invoiceEditingContext.data.invoice?.propertyId, invoiceEditingContext.property?.data?.administrationType as unknown as PropertyLegacyDtoAdministrationTypeEnum);
  if (useIntegrationIsSupported()) {
    // only show the integration section if the relevant connections
    // are availble on the domain
    sections.push(integrationSection);
  }


  const menuElements: any[] = generateMenuFromSections({
    sections,
    openSectionIndex,
  });


  const bookingsEditingButtonLabel = editingBookings ? tl(invoiceEditingTranslations.invoiceEditing.bookingsEditing.save) : tl(invoiceEditingTranslations.invoiceEditing.bookingsEditing.edit);
  const onClickEditingButton = () => {
    if (editingBookings) {
      if (isHgaClosed) {
        showModal(() => showConfirmationModal());
      } else {
        showConfirmationModal();
      }
    } else {
      setEditingBookings(true);
    }
  };

  const showConfirmationModal = () => {
    Modal.confirm({
      title: tl(invoiceEditingTranslations.invoiceEditing.confirmationModal.title),
        okText: tl(invoiceEditingTranslations.invoiceEditing.confirmationModal.save),
        cancelText: tl(invoiceEditingTranslations.invoiceEditing.confirmationModal.cancel),
        onOk: handleConfirmationModalSave,
        afterClose: handleConfirmationModalDiscard,
        content: tl(invoiceEditingTranslations.invoiceEditing?.confirmationModal?.description),
        okButtonProps: { className: 'Button' },
        cancelButtonProps: { className: 'Button' },
        closable: true,
    })
  }

  const bookingEditingButton = (
    <Button
      type="primary"
      loading={loading || invoiceEditingContext.loading}
      onClick={onClickEditingButton}
    >
      {bookingsEditingButtonLabel}
    </Button>
  );

  const copyButton = () => ({
    label: tl(translations.pages.invoice.copy),
    onClick: () => invoiceListContext.onCopyInvoice(invoiceEditingContext.data.invoice.id),
  });

  const deleteButton = (state: InvoiceEpoStateEnum) => ({
    label: tl(translations.pages.invoice.delete),
    onClick: () => {
      if (state === InvoiceEpoStateEnum.NEW || state === InvoiceEpoStateEnum.READY_FOR_APPROVAL) {
        invoiceListContext.onDeleteDraftInvoices([invoiceEditingContext.data.invoice.id], true);
      } else if (isHgaClosed) {
        showModal(() => invoiceListContext.onMarkDeleteInvoice(invoiceEditingContext.data.invoice.id));
      } else {
        invoiceListContext.onMarkDeleteInvoice(invoiceEditingContext.data.invoice.id);
      }
    },
  });

  const revertToDraftButton = () => ({
    label: tl(translations.pages.invoice.revertToDraft),
    onClick: () => {
      if (isHgaClosed) {
        showModal(() => onRevertInvoice(invoiceEditingContext.data.invoice.id));
      } else {
        onRevertInvoice(invoiceEditingContext.data.invoice.id);
      }
    },
  });

  const stopRecurringButton = () => ({
    label: tl(translations.pages.invoice.stopRecurring),
    onClick: () => {
      Modal.confirm({
        title: tl(invoiceEditingTranslations.invoiceEditing.confirmationModal.title),
          okText: tl(invoiceEditingTranslations.invoiceEditing.confirmationModal.save),
          cancelText: tl(invoiceEditingTranslations.invoiceEditing.confirmationModal.cancel),
          onOk: handleStopRecurring,
          content: tl(invoiceEditingTranslations.invoiceEditing?.confirmationModal?.descriptionStopRecurring),
          okButtonProps: { className: 'Button' },
          cancelButtonProps: { className: 'Button' },
          closable: true,
      })
    },
  });

  const dropMenuOptions = () => {
    switch (invoiceEditingContext.data.invoice.state) {
      case InvoiceEpoStateEnum.NEW:
        return [deleteButton(InvoiceEpoStateEnum.NEW), copyButton()];
      case InvoiceEpoStateEnum.READY_FOR_APPROVAL:
        return [deleteButton(InvoiceEpoStateEnum.READY_FOR_APPROVAL), copyButton()];
      case InvoiceEpoStateEnum.APPROVED:
        return [revertToDraftButton(), deleteButton(InvoiceEpoStateEnum.APPROVED), copyButton()];
      case InvoiceEpoStateEnum.RECURRING:
        return [stopRecurringButton(), copyButton()];
      case InvoiceEpoStateEnum.DELETED:
        return [copyButton()];
      case InvoiceEpoStateEnum.RECURRING_STOPPED:
        return [copyButton()];
      default:
        return [];
    }
  };

  const dropMenuOptionList = dropMenuOptions();

  const rightSideComponent = (
    <div className="page-header">
      <div className="top-actionbar">
        <Button
          type="text"
          className="back"
          onClick={goBack}
        >
          {tl(invoiceEditingTranslations.invoiceEditing.back)}
        </Button>
        {!_.isEmpty(dropMenuOptionList)
          && (
            <DropMenu
              options={dropMenuOptionList}
            />
          )}
        {bookingsEditable && bookingEditingButton}
        {editable && (
          <>
            <Button
              type="ghost"
              onClick={() => saveAsDraft()}
              loading={invoiceEditingContext.loading}
            >
              {tl(invoiceEditingTranslations.invoiceEditing.saveAsDraft)}
            </Button>
            <Button
              type="primary"
              disabled={isDirty || !invoiceEditingContext.approvable || invoiceEditingContext.loading}
              onClick={isHgaClosed ? () => showModal(onClickApproveInvoice) : onClickApproveInvoice}
              loading={invoiceEditingContext.loading || loading}
            >
              {invoiceEditingContext.data.invoice.paymentType === 'EXISTING' ? tl(invoiceEditingTranslations.invoiceEditing.approve) : tl(invoiceEditingTranslations.invoiceEditing.pay)}
            </Button>
          </>
        )}
      </div>
    </div>
  );


  const pageContentClassName = viewMode === 'fullscreen' ? 'form-content-fullscreen' : 'form-content-with-pdf';

  return (
    <Page className="InvoiceEditorForm">
      <Prompt
        when={isDirty}
        message={loc => (location.pathname === loc.pathname ? true : tl(translations.elements.prompt))}
      />
      <PageHeader
        rightSideComponent={rightSideComponent}
        title={showMenu && tl(invoiceEditingTranslations.invoiceEditing.title)}
        showLogo={showMenu}
      />
      <PageContent className={pageContentClassName}>
        <div className="invoice-form">
          <div id="scrollElement">
            {comments[0]?.text && <Note content={comments[0]?.text} />}
            {sections.map((section: any) => {
              const disabled = !(editable || (editingBookings && section.sectionNumber === 3));
              return (
                <FormSection
                  key={section.sectionNumber}
                  open={(openSectionIndex === section.sectionNumber - 1)}
                  addDirt={() => setDirty(true)}
                  onClickHeader={() => handleOpenSectionChange(section.sectionNumber - 1)}
                  onSubmit={() => save(AfterSaveAction.OPEN_NEXT_SECTION)}
                  validationErrors={invoiceEditingContext.validationErrors}
                  onChange={(value: any) => {
                    invoiceEditingContext.updateInvoiceState({ invoice: value }, invoiceEditingContext.validationErrors);
                  }}
                  inputsDisabled={disabled || invoiceEditingContext.loading}
                  loading={invoiceEditingContext.loading}
                  {...section}
                />
              );
            })}
          </div>
        </div>
        {showMenu && (
          <FormSidebar
            elements={menuElements}
            openSection={openSectionIndex}
            onOpenSection={handleOpenSectionChange}
            scrollTarget={document.getElementsByClassName('invoice-form')[0]}
          />
        )}
      </PageContent>
      <DirtModal
        visible={dirtModalVisible}
        onCancel={() => setDirtModalVisible(false)}
        onSave={handleDirtModalSave}
        onDiscard={handleDirtModalDiscard}
        loading={invoiceEditingContext.loading}
      />
      <PostingWarningModal
        visible={visible}
        onCancel={onClickCancel}
        onProceed={onClickProceed}
        onReview={onClickReview}
      >
        {tl(invoiceEditingTranslations.invoiceEditing.title)}
      </PostingWarningModal>
    </Page>
  );
};

const InvoiceEditorForm = (props: InvoiceEditorFormProps) => (
  <InvoiceEditorMiscDataContextProvider>
    <InvoiceEditorFormWithoutProvider {...props} />
  </InvoiceEditorMiscDataContextProvider>
);

export default InvoiceEditorForm;

InvoiceEditorForm.defaultProps = {
  showMenu: false,
  backPath: undefined,
};
