import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Configuration,
  ConfigurationParameters,
  HTTPQuery,
} from 'api/accounting';
import { Configuration as DocumentConfiguration } from 'api/document';
import { Configuration as PublicConfiguration } from 'api/public';
import { Configuration as AppConfiguration } from 'api/app';
import { JWTToken } from 'api/user';
import * as config from 'config';
import jwtDecode from 'jwt-decode';
import { useQuery } from 'lib/useQuery';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';

import backend, { endpointUrls } from '../backend_api';
import { translations } from '../elements/Translation/translations';
import DEFAULT_DATA from '../lib/data';
import { showNotification } from '../lib/Notification';
import { LanguageContext } from './LanguageContext';
import { checkUserHasPermission, Permissions } from '../lib/userPermissionUtils';

const MINUTE = 1000 * 60;
const MAX_REFRESH_TOKEN_TIMEOUT = 30 * MINUTE;

declare const ModuleTypes: ['accounting', 'document', 'user', 'app', 'public'];
declare type Module = (typeof ModuleTypes)[number];
/**
 * The payload encoded into the JWT token
 */
export interface IJwtPayload {
  // rfc standard fields - rfc7519#section-4.1
  sub?: string,
  exp?: number,
  // impower specific
  appId?: number,
  connectionId?: number,
  domainId?: number,
  permissions?: string,
  userId?: number,
}

export interface DomainConfiguration {
  heatingCenterEnabled: boolean,
  wegTenantDisabledDomain: boolean,
  groupDirectDebits: boolean,
  groupPayments: boolean,
  unbatchTransactions: boolean,
  economicPlanAccountBasedWithWkasEnabled: boolean,
}
export interface AuthContextInterface {
  token?: string;
  domainId?: number;
  configuration?: DomainConfiguration;
  isLoggedIn?: () => boolean;
  onLogin?: (email: string, password: string, domain: string, rememberMe?: boolean) => Promise<JWTToken>;
  onLogout?: () => void;
  apiConfiguration: (module: Module, tokenOverride?: string) => Configuration;
  documentApiConfiguration: (module: Module, tokenOverride?: string) => DocumentConfiguration; // TODO: Should be refactored since API Configuration does not differ based on domain
  publicApiConfiguration: (module: Module, tokenOverride?: string) => PublicConfiguration;
  appApiConfiguration: (module: Module, tokenOverride?: string) => AppConfiguration;
  customQuerystring?: (params: HTTPQuery, prefix?: string) => string;
}

export const AuthContext = React.createContext<AuthContextInterface>({
  apiConfiguration: () => new Configuration(),
  documentApiConfiguration: () => new DocumentConfiguration(),
  publicApiConfiguration: () => new PublicConfiguration(),
  appApiConfiguration: () => new AppConfiguration(),
});

/** Copied from generated runtime.ts */
export const customQuerystring = (params: HTTPQuery, prefix: string = ''): string => Object.keys(params)
  .map(key => querystringSingleKey(key, params[key], prefix))
  .filter(part => part.length > 0)
  .join('&');

/**
* Copied from generated runtime.ts
* Adapted the array type query param generation to use format 'contractIds=1,2,3' instead of 'contractIds=1&contractIds=2&contractIds=3'
*/
function querystringSingleKey(key: string, value: string | number | null | undefined | boolean | Array<string | number | null | boolean> | Set<string | number | null | boolean> | HTTPQuery, keyPrefix: string = ''): string {
  const fullKey = keyPrefix + (keyPrefix.length ? `[${key}]` : key);
  if (value instanceof Array) {
    return `${encodeURIComponent(fullKey)}=${encodeURIComponent(String(value.join(',')))}`;
  }
  if (value instanceof Set) {
    const valueAsArray = Array.from(value);
    return querystringSingleKey(key, valueAsArray, keyPrefix);
  }
  if (value instanceof Date) {
    return `${encodeURIComponent(fullKey)}=${encodeURIComponent(value.toISOString())}`;
  }
  if (value instanceof Object) {
    return customQuerystring(value as HTTPQuery, fullKey);
  }
  return `${encodeURIComponent(fullKey)}=${encodeURIComponent(String(value))}`;
}

export default function AuthProvider({ children }: any): JSX.Element {
  const defaultContext = {
    token: null,
    email: null,
  };
  const { tl, language } = useContext(LanguageContext);
  const history = useHistory();
  const queryParams = useQuery();

  const defaultAuthState = DEFAULT_DATA<any>(defaultContext).load(defaultContext);
  defaultAuthState.loaded = false;

  const [authState, setAuthState] = useState(defaultAuthState);
  /**
   * @returns decoded token payload
   */
  const getTokenContent = (jwtToken:string):IJwtPayload => {
    const payload = JSON.parse(atob(jwtToken.split('.')[1]));
    return payload;
  };
  /**
   * Checks if token does not refer to a connection of an application.
   * Don't refresh access token in case of "PERM_SUPPORT" role.
   *
   * @returns true if the token does not refer to a connection of an application
   */
  const isUserToken = (jwtToken?:string): boolean => {
    const isCustomerSupportUser = checkUserHasPermission(Permissions.PERM_SUPPORT);
    if (isCustomerSupportUser) {
      return false;
    }
    let isAUserToken = false;
    if (jwtToken || authState.data.token) {
      try {
        const payload = getTokenContent(jwtToken || authState.data.token);
        isAUserToken = !!(payload.userId) && !payload.appId && !payload.connectionId;
      } catch (e) {
        console.warn('Cannot tell if its a usertoken or not', e);
      }
    }
    return isAUserToken;
  };
  useEffect(() => {
    initLocalStorageFromQueryParams();
    initializeInMemoryToken();
    loadDomainConfiguration();
    if (isUserToken()) {
      onAutoRefreshToken();
      loadDomainConfiguration();
    }
  }, []);

  /**
   * Try to parse the authToken queryParam
   * If found & successful: set it as localStorage element
   */
  const initLocalStorageFromQueryParams = () => {
    const queryParamToken = queryParams.get('authToken');
    if (queryParamToken) {
      try {
        console.info('Authorization token detected; Resetting localStorage', queryParamToken);
        const tokenContent = getTokenContent(queryParamToken);
        const auth = {
          token: queryParamToken,
          email: tokenContent.sub,
          domain: tokenContent.domainId,
        };
        // set localStorage auth before the state, because AccountContext need the token to be able to call the /users/current endpoint
        localStorage.setItem('auth', JSON.stringify(auth));
      } catch (e) {
        console.warn('Failed autodetecting token in query parameters', queryParamToken, e);
      }
    }
  };


  const initializeInMemoryToken = (): void => {
    const rawAuth: string | null = localStorage.getItem('auth');
    if (rawAuth) {
      setAuthState(authState.startLoading());
      const auth: any = JSON.parse(rawAuth);
      const jwtObject: any = jwtDecode(auth.token);
      const expirationDate = new Date(jwtObject.exp * 1000);
      if (+expirationDate > +new Date()) {
        setAuthState(authState.load(Object.assign(authState.data, auth)));
      } else {
        setAuthState(DEFAULT_DATA<any>(defaultContext).load(defaultContext));
        showNotification({
          key: 'tokenExpiredMsg',
          message: tl(translations.notifications.authContext.tokenExpired.message),
          description: tl(translations.notifications.authContext.tokenExpired.description),
          type: 'warning',
        });
      }
    } else {
      setAuthState(DEFAULT_DATA<any>(defaultContext).load(defaultContext));
    }
  };

  const loadDomainConfiguration = (token?: JWTToken) => {
    if (token || authState.data.token) {
      backend.get(`${endpointUrls.DOMAIN}`, {})
        .then((response: any) => {
          const { configuration } = response;
          setAuthState(prev => prev.load({ ...prev.data, configuration }));
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  const onLogout = (): void => {
    // deletes all sensitive data
    // from the local localStorage
    // example: pmp authentication ; casavi auth etc.
    localStorage.clear();
    window.history.pushState({}, 'Login', '/');
    window.location.reload();
  };

  const onLogin = async (email: string, password: string, domain: string, rememberMe: boolean = false): Promise<JWTToken> => {
    setAuthState(state => state.startLoading());
    const loginPromise = new Promise<JWTToken>((resolve, reject) => {
      backend.post(`${endpointUrls.USER}/authenticate`, {
        email, password, domain, rememberMe,
      }).then((response) => {
        const tokenResponse:JWTToken = response as unknown as JWTToken;
        const auth = { ...tokenResponse, email, domain };
        // set localStorage auth before the state, because AccountContext need the token to be able to call the /users/current endpoint
        localStorage.setItem('auth', JSON.stringify(auth));
        setAuthState((state) => {
          const newState = state.load(auth);
          setTimeout(() => {
            // execute callback
            resolve(auth);
            history.push('/dashboard');
          }, 100);
          return newState;
        });

        loadDomainConfiguration(tokenResponse);
      }).catch(() => {
        setAuthState(state => state.failed());
        showNotification({
          key: 'loginError',
          message: tl(translations.notifications.authContext.loginError.message),
          description: tl(translations.notifications.authContext.loginError.description),
          type: 'warning',
        });
        reject();
      });
    });
    // we need the catch, otherwise we get a `Non-Error promise rejection captured with value: undefined` error in Sentry
    return loginPromise.catch(() => null);
  };

  const onAutoRefreshToken = (): void => {
    if (authState.data.token) {
      backend.post(`${endpointUrls.USER}/refresh-token`, {})
        .then((response: any) => {
          setAuthState((prev) => {
            const newAuthState = { ...prev.data, token: response.token };
            localStorage.setItem('auth', JSON.stringify(newAuthState));

            return prev.load(newAuthState);
          });
          setTimeoutForNextRefresh(response.token);
        })
        .catch((e) => {
          if (window.location.hostname === 'localhost') {
            console.warn('Failed to refresh token... skipping auto-logout', e);
          } else {
            onLogout();
          }
        });
    }
  };

  const setTimeoutForNextRefresh = (token: string): void => {
    const jwtObject: any = jwtDecode(token);
    const expireDate = jwtObject.exp * 1000;
    const now = new Date().getTime();
    const timeout = (expireDate - now) - 5 * MINUTE;

    if (timeout > 0) {
      setTimeout(() => {
        onAutoRefreshToken();
      }, timeout < MAX_REFRESH_TOKEN_TIMEOUT ? timeout : MAX_REFRESH_TOKEN_TIMEOUT);
    } else {
      onAutoRefreshToken();
    }
  };

  const getToken = () => authState.data.token;


  const getConfigurationParameters = (
    module: Module,
    tokenOverride: string,
  ): ConfigurationParameters => ({
    apiKey: `Bearer ${tokenOverride || authState.data.token}`,
    basePath: config.backendUrl,
    middleware: [
      {
        pre: async ({ url, ...rest }) => ({
          url: url.replace('/api/', `/services/pmp-${module}/api/`).replace('/v2/', `/services/pmp-${module}/v2/`),
          ...rest,
        }),
        post: async ({ response }) => {
          if (response.status === 403) { // response is Forbidden
            showNotification({
              key: 'forbiddenOperation',
              type: 'info',
              message: tl(translations.notifications.authContext.forbiddenOperation.message),
              description: tl(translations.notifications.authContext.forbiddenOperation.description),
            });
          }
          return response;
        },
      },
    ],
    queryParamsStringify: customQuerystring,
  });

  const apiConfiguration = useCallback((module: Module, tokenOverride: string = '') => new Configuration(getConfigurationParameters(module, tokenOverride)), [authState, language]);


  // TODO: Refactor to only use single interface for Api Configuration
  const documentApiConfiguration = useCallback((module: Module, tokenOverride: string = '') => new DocumentConfiguration(getConfigurationParameters(module, tokenOverride)), [authState]);

  const publicApiConfiguration = useCallback((module: Module, tokenOverride: string = '') => new PublicConfiguration(getConfigurationParameters(module, tokenOverride)), [authState]);
  
  const appApiConfiguration = useCallback((module: Module, tokenOverride: string = '') => new AppConfiguration(getConfigurationParameters(module, tokenOverride)), [authState]);

  const isLoggedIn = () => !!getToken();

  const domainId = useMemo(() => {
    try {
      const jwtObject: any = jwtDecode(authState.data.token);
      return jwtObject.domainId;
    } catch (e) {
      return null;
    }
  }, [authState]);

  return (
    <AuthContext.Provider value={{
      ...authState,
      onLogin,
      onLogout,
      token: authState.data.token,
      configuration: authState.data?.configuration,
      isLoggedIn,
      apiConfiguration,
      documentApiConfiguration,
      publicApiConfiguration,
      appApiConfiguration,
      domainId,
      customQuerystring,
    }}
    >
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
