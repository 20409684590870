import React from 'react';
import { Tooltip, TooltipProps } from 'antd';
import './TooltipForTableCell.scss';


const TooltipForTableCell = ({
  title,
  overlayClassName: overlayClassNameProp,
  children,
  ...restProps
}: TooltipProps) => {
  const overlayClassName = `TooltipForTableCell ${overlayClassNameProp || ''}`;

  return (
    <Tooltip
      title={(
        <div
          className="ant-tooltip-inner-content-wrapper"
          role="presentation"
          onClick={e => e.stopPropagation()}
        >
          {title}
        </div>
      )}
      overlayClassName={overlayClassName}
      {...restProps}
    >
      {/* without this div the Tooltip doesn't work inside a table */}
      <div>{children}</div>
    </Tooltip>
  );
};

export default TooltipForTableCell;
