import moment from 'moment';
import { useCallback, useContext } from 'react';
import { EconomicPlanSimpleProjection, EconomicPlanSimpleProjectionStatusEnum } from '../../../api/accounting';
import { EconomicPlanContext } from './EconomicPlanContext';

const epWasActivated = ep => [EconomicPlanSimpleProjectionStatusEnum.DECIDED, EconomicPlanSimpleProjectionStatusEnum.ACTIVE, EconomicPlanSimpleProjectionStatusEnum.EXPIRED].includes(ep.status);

const isOverlapping = (startDate: moment.Moment, endDate: moment.Moment) => (ep: EconomicPlanSimpleProjection) => {
  const epStartDate = moment(ep.startDate);
  const epEndDate = ep.endDate === undefined ? undefined : moment(ep.endDate);

  return (!endDate || epStartDate.isBefore(endDate))
    && (!epEndDate || startDate.isBefore(epEndDate));
};

export const useFindOverlappingEconomicPlans = () => {
  const economicPlanContext = useContext(EconomicPlanContext);
  if (economicPlanContext === undefined) {
    throw Error('useShowCorrectionsSection cannot be used outside of EconomicPlanContext');
  }

  const { economicPlan, economicPlanList } = economicPlanContext;

  const findOverlappingEconomicPlans = useCallback(() => {
    if (economicPlan === undefined) {
      throw Error('You must first select an economic plan before checking for overlaps');
    }

    if (economicPlan?.data?.startDate === undefined) {
      throw Error('Selected Economic Plan must have a start date before checking for overlaps');
    }

    const startDate = moment(economicPlan.data?.startDate);
    const endDate = economicPlan?.data?.endDate === undefined ? undefined : moment(economicPlan.data.endDate);
    const overlappingEconomicPlans = economicPlanList?.data
      ?.filter(epWasActivated)
      ?.filter(isOverlapping(startDate, endDate)) || [];

    return overlappingEconomicPlans;
  }, [economicPlan?.data?.startDate, economicPlanList?.data]);


  return {
    findOverlappingEconomicPlans,
  };
};
