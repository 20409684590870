import { useContext } from 'react';
import Amount from 'storybook-components/typography/Amount/Amount';
import CellWithTwoLines from 'storybook-components/table/CellWithTwoLines/CellWithTwoLines';
import { FORMAT } from 'elements/SmartTable/SmartTable';
import { getPropertyCellSubtitleInList } from 'lib/utilGetPropertyCellSubtitleInList';
import TooltipForTableCell from 'storybook-components/table/TooltipForTableCell/TooltipForTableCell';
import { translations } from '../../../elements/Translation/translations';
import { LanguageContext } from '../../../contexts/LanguageContext';
import StatusCell from './components/StatusCell';

export const usePropertyListColumns = () => {
  const { tl } = useContext(LanguageContext);

  return [
    {
      title: tl(translations.pages.property.table.headers.status),
      dataKey: 'propertyState',
      render: (_, record) => <StatusCell record={record} />,
    },
    {
      title: tl(translations.pages.property.table.headers.property),
      dataKey: 'name',
      render: (_, record) => (
        <TooltipForTableCell title={`${record.name || '—'}\n${getPropertyCellSubtitleInList(record, 'administrationType')}`}>
          <CellWithTwoLines
            firstElement={record.name || '—'}
            secondElement={getPropertyCellSubtitleInList(record, 'administrationType')}
          />
        </TooltipForTableCell>
      )
      ,
    },
    {
      title: tl(translations.pages.property.table.headers.address),
      dataKey: 'address',
      sortable: false,
    },
    {
      title: tl(translations.pages.property.table.headers.units),
      dataKey: 'numberOfUnits',
      sortable: false,
      render: val => <Amount>{val}</Amount>,
    },
    {
      title: tl(translations.pages.property.table.headers.clientCategory),
      dataKey: 'clientCategory',
      defaultVisible: false,
    },
    {
      title: tl(translations.pages.property.table.headers.accountant),
      dataKey: 'accountantName',
      defaultVisible: false,
    },
    {
      title: tl(translations.pages.property.table.headers.administrator),
      dataKey: 'supervisorName',
      defaultVisible: false,
    },
    {
      title: tl(translations.pages.property.table.headers.created),
      dataKey: 'created',
      format: FORMAT.DATE,
      defaultVisible: false,
    },
    {
      title: tl(translations.pages.property.table.headers.createdBy),
      dataKey: 'createdBy',
      sortable: false,
      defaultVisible: false,
    },
  ];
};
