import { WkaProjectionDto } from 'api/accounting';
import DEFAULT_DATA, { DefaultDataInterface } from 'lib/data';
import {
  createContext, useContext, useMemo, useState,
} from 'react';
import { Order } from 'services/useSort';

interface Props {
  children: React.ReactNode;
}

export interface WkaFilter {
  name?: string,
  property?: string,
  startDate?: string,
  endDate?: string,
}

export const WkaListContext = createContext<{
  wkaList: DefaultDataInterface<WkaProjectionDto[]>,
  setWkaList: React.Dispatch<React.SetStateAction<DefaultDataInterface<WkaProjectionDto[]>>>,
  filterState: WkaFilter,
  setFilterState: React.Dispatch<React.SetStateAction<WkaFilter>>,
  sortState: { field: string, order: Order },
  setSortState: React.Dispatch<React.SetStateAction<{ field: string, order: Order }>>,
  setParamOfFetchInProgress: React.Dispatch<React.SetStateAction<WkaFilter | undefined>>,
} | undefined>(undefined);

export const useWkaListContext = (usageName: string) => {
  const wkaListContext = useContext(WkaListContext);

  if (wkaListContext === undefined) {
    throw new Error(`${usageName} must be used within a WkaListContextProvider`);
  }

  return wkaListContext;
};

const WkaListContextProvider = ({ children }: Props) => {
  const [wkaList, setWkaList] = useState(DEFAULT_DATA<WkaProjectionDto[]>([]));
  const [filterState, setFilterState] = useState<WkaFilter>();
  const [sortState, setSortState] = useState({ field: 'updated', order: -1 });
  const [paramOfFetchInProgress, setParamOfFetchInProgress] = useState<any>(undefined);

  const wkaListContextProviderValue = useMemo(() => ({
    wkaList,
    setWkaList,
    filterState,
    setFilterState,
    sortState,
    setSortState,
    setParamOfFetchInProgress,
  }), [wkaList, setWkaList, filterState, setFilterState, sortState, setSortState, setParamOfFetchInProgress]);

  return (
    <WkaListContext.Provider value={wkaListContextProviderValue}>
      {children}
    </WkaListContext.Provider>
  );
};

export default WkaListContextProvider;
