import {
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  GetWkasPagedUsingGETOrderEnum,
  WkaControllerApi,
  WkaProjectionDto,
} from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import _, { isEqual } from 'lodash';

import { Action } from 'elements/SmartTable/data';
import { useDeleteWka } from 'pages/Wka/services/useDeleteWka';
import { WKA_PATH } from 'pages/Wka/routes';
import { useLocation } from 'react-router';
import { translations } from '../translations';
import {
  useWkaListContext,
  WkaFilter,
} from '../../services/WkaListContext';

const PAGE_SIZE = 30;

export interface SortInterface {
  field: string,
  order: 1 | -1,
}


export default function useWkaList() {
  const { tl } = useContext(LanguageContext);
  const location = useLocation();

  const {
    wkaList, setWkaList, sortState, setSortState, filterState, setFilterState, setParamOfFetchInProgress,
  } = useWkaListContext('useWkaList');

  const { onClickDelete } = useDeleteWka();
  const { apiConfiguration } = useContext(AuthContext);
  const lastRequestTimestamp = useRef<number | null>(null);


  const wkaControllerApi = new WkaControllerApi(apiConfiguration('accounting'));
  const abortController = useRef<AbortController | undefined>(undefined);


  const toTableData = (data: WkaProjectionDto) => ({
    ...data,
    translatedStatus: data.status ? tl(translations.wkaList.wkaStatuses[data.status]) : '',
  });


  useEffect(() => {
    if (location.pathname === WKA_PATH || !wkaList.loaded) {
      onLoadWkaList(true);
    }
  }, [filterState, sortState, location.pathname === WKA_PATH]);

  useEffect(() => {
    onLoadWkaList(true);
  }, []);

  const onLoadWkaList = async (resetPage: boolean = false) => {
    setWkaList(state => state.startLoading());
    const currentTimestamp = new Date().getTime();
    lastRequestTimestamp.current = currentTimestamp;
    setParamOfFetchInProgress((prevFetchParams) => {
      // add filters
      // _.pickBy(obj,_.identity) removes the null values
      const currentFetchParams = _.pickBy({ ...filterState, ...sortState }, _.identity);
      if (isEqual(currentFetchParams, prevFetchParams)) {
        return prevFetchParams;
      }

      // if params changed since last initiated fetch then abort the in-progress fetch
      abortController.current?.abort();
      // create new abort controller
      abortController.current = new AbortController();
      const { signal } = abortController.current;


      wkaControllerApi.getWkasPagedUsingGET({
        ...currentFetchParams,
        startDate: currentFetchParams.startDate ? new Date(currentFetchParams.startDate) : undefined,
        endDate: currentFetchParams.endDate ? new Date(currentFetchParams.endDate) : undefined,
        size: PAGE_SIZE,
        page: resetPage ? 0 : wkaList.page,
        sort: sortState?.field,
        order: sortState?.order > 0 ? GetWkasPagedUsingGETOrderEnum.ASC : GetWkasPagedUsingGETOrderEnum.DESC,
      }, { signal }).then((response: any) => {
        setWkaList(state => state.loadPaged(response.content, resetPage, response.last));
      })
        .catch((e) => {
          if (signal.aborted) return;
          console.error(e);

          setWkaList(state => state.failed());
          showNotification({
            key: 'loadWkaListError',
            message: tl(translations.wkaList.loadFailed.message),
            type: 'error',
          });
        }).finally(() => { setParamOfFetchInProgress(undefined); });

      return currentFetchParams;
    });
  };


  const tableData = useMemo(() => wkaList?.data?.map(toTableData) || [], [wkaList?.data]);
  const updateFilter = (key: keyof WkaFilter, value: WkaFilter[keyof WkaFilter]) => {
    setFilterState((f: any) => ({
      ...f,
      [key]: value,
    }));
  };

  const updateFilterState = (data: object) => {
    setFilterState((currentState: any) => ({
      ...currentState,
      ...data,
    }));
  };

  const setSortField = (field: string) => {
    setSortState(prev => ({
      field,
      order: prev.field === field ? prev.order * (-1) : 1,
    }));
  };


  const updateWkaById = (wkaId: number, totalCosts: number) => {
    if (wkaId && totalCosts !== 0) {
      setWkaList(prevState => prevState.load(prevState.data?.map((wka) => {
        if (wka.id === wkaId) {
          return {
            ...wka,
            totalCost: totalCosts,
          };
        }
        return wka;
      }) ?? []));
    }
  };


  const actionsMenu: Action[] = [{
    label: tl(translations.wkaList.actions.delete),
    onAction: (record: WkaProjectionDto) => onClickDelete(record.id, () => onLoadWkaList(true)),
  }];


  return {
    wkaList,
    setWkaList,
    onLoadWkaList,
    filterState,
    updateFilter,
    updateFilterState,
    setSortField,
    sortState,
    updateWkaById,
    tableData,
    actionsMenu,
  };
}
