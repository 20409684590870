import {
  Col, Row,
} from 'antd';
import { useContext, useMemo } from 'react';
import DateInput, { MAX_DATE_NORMAL, MIN_DATE_NORMAL } from 'elements/Inputs/DateInput/DateInput';
import moment from 'moment';
import ComponentVisibilityToggler from 'components/ComponentVisibilityToggler/ComponentVisibilityToggler';
import { SPACE_SIZES } from 'lib/constants';
import { EconomicPlanDtoStatusEnum } from '../../../../api/accounting';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import SmartSearch from '../../../../elements/Inputs/SmartSearch/SmartSearch';
import TextInput from '../../../../elements/Inputs/TextInput/TextInput';
import usePropertyOptions from '../../../../services/usePropertyOptions';
import { economicPlanTranslations } from '../../economicPlanTranslations';
import './EconomicPlanData.scss';
import { useEconomicPlanData } from './services/useEconomicPlanData';
import EconomicPlanWkaSelector from './EconomicPlanWkaSelector/EconomicPlanWkaSelector';

export const EconomicPlanData = () => {
  const { tl } = useContext(LanguageContext);

  const {
    title,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    setTitle,
    economicPlanState,
    selectedPropertyId,
    isWKASelectorDisplayed,
    loading,
  } = useEconomicPlanData();

  const {
    propertyOptions,
    searchForProperty,
    onLoadProperty,
  } = usePropertyOptions(false);

  const options = propertyOptions.map(propertyOption => ({
    key: propertyOption?.value,
    value: propertyOption?.value,
    label: propertyOption?.simpleLabel,
  }));

  const showEndDateNearStartDate = economicPlanState === EconomicPlanDtoStatusEnum.EXPIRED;
  const editEnabled = economicPlanState === EconomicPlanDtoStatusEnum.DRAFT;

  const endDateValidationError = useMemo(() => {
    if (!endDate) {
      return undefined;
    }
    if (endDate.isAfter(moment())) {
      return tl(economicPlanTranslations.economicPlan.endDate.validationErrors.cannotBeInFuture);
    }
    if (startDate !== undefined && endDate.isBefore(startDate)) {
      return tl(economicPlanTranslations.economicPlan.endDate.validationErrors.cannotBeBeforeStartDate);
    }
    return undefined;
  }, [startDate, endDate]);

  const onEndDateVisibilityChange = (visible) => {
    if (!visible && endDate !== undefined) {
      setEndDate(undefined);
    }
  };

  return (
    <>
      <div className="EconomicPlanData">
        <Row gutter={[SPACE_SIZES.xLarge, SPACE_SIZES.xLarge]}>
          <Col span={12}>
            <SmartSearch
              label={tl(economicPlanTranslations.property)}
              disabled
              value={selectedPropertyId}
              searchFunction={searchForProperty}
              getOneFunction={(id: string) => onLoadProperty(parseInt(id, 10))}
              onChange={() => {
              }}
              options={options}
            />
          </Col>
          <Col span={showEndDateNearStartDate ? 6 : 12}>
            <DateInput
              onChange={setStartDate}
              label={tl(economicPlanTranslations.economicPlan.startDate.label)}
              infoText={tl(economicPlanTranslations.economicPlan.startDate.tooltip)}
              value={startDate}
              format="DD.MM.YYYY"
              disabled={!editEnabled}
              views={['month']}
              softMinDate={MIN_DATE_NORMAL}
              softMaxDate={MAX_DATE_NORMAL}
              required
            />
          </Col>
          {showEndDateNearStartDate
              // end date can never be edited when shown near the start date
              && (
                <Col span={6}>
                  <DateInput
                    onChange={() => { }}
                    label={tl(economicPlanTranslations.economicPlan.endDate.label)}
                    infoText={tl(economicPlanTranslations.economicPlan.endDate.tooltip)}
                    value={endDate}
                    format="DD.MM.YYYY"
                    disabled
                  />
                </Col>
              )}
          <Col span={isWKASelectorDisplayed ? 12 : 24}>
            <TextInput
              onChange={setTitle}
              value={title}
              required
              label={tl(economicPlanTranslations.economicPlan.title)}
              disabled={!editEnabled}
            />
          </Col>
          {isWKASelectorDisplayed && <Col span={12}><EconomicPlanWkaSelector /></Col>}
          {!loading && !showEndDateNearStartDate
            // only render if not loading so the 'defaultIsVisible' field will be set corretly from first render
            && (
              <Col span={12}>
                <ComponentVisibilityToggler
                  labelWhenClosed={tl(economicPlanTranslations.economicPlan.endDateComponentVisibilityLabel.labelWhenClosed)}
                  labelWhenOpen={tl(economicPlanTranslations.economicPlan.endDateComponentVisibilityLabel.labelWhenOpen)}
                  defaultIsVisible={endDate !== undefined}
                  onVisibilityChange={onEndDateVisibilityChange}
                  disabled={!editEnabled}
                >
                  <DateInput
                    onChange={setEndDate}
                    label={tl(economicPlanTranslations.economicPlan.endDate.label)}
                    infoText={tl(economicPlanTranslations.economicPlan.endDate.tooltip)}
                    value={endDate}
                    format="DD.MM.YYYY"
                    disabled={!editEnabled}
                    views={['month']}
                    softMinDate={MIN_DATE_NORMAL}
                    validationState={endDateValidationError !== undefined ? 'error' : undefined}
                    validationMessage={endDateValidationError}
                  />
                </ComponentVisibilityToggler>
              </Col>
            )}
        </Row>
      </div>
    </>
  );
};
