export const translations: any = {
  propertyHrId: {
    en: 'Property ID',
    de: 'Objekt ID',
  },
  internalNr: {
    en: 'Property-Nr.',
    de: 'Objekt-Nr.',
  },
  name: {
    en: 'Property name',
    de: 'Objektbezeichnung',
  },
};
