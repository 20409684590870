import { UnitContractProjectionDto } from 'api/accounting';
import DEFAULT_DATA, { DefaultDataInterface } from 'lib/data';
import React, { createContext, useState } from 'react';
import moment from 'moment';


interface Props {
  children: React.ReactNode;
}

interface ActiveContractsListCachedParamsType {
  propertyId: number;
  atDate: moment.Moment;
}


export const ActiveContractsListContext = createContext<{
  activeContractsList: DefaultDataInterface<UnitContractProjectionDto[]>,
  setActiveContractsList: React.Dispatch<React.SetStateAction<DefaultDataInterface<UnitContractProjectionDto[]>>>,
  paramOfCachedValue: ActiveContractsListCachedParamsType | undefined,
  setParamOfCachedValue: React.Dispatch<React.SetStateAction<ActiveContractsListCachedParamsType | undefined>>,
  setParamOfFetchInProgress: React.Dispatch<React.SetStateAction<ActiveContractsListCachedParamsType | undefined>>,
  resetActiveContractsListContext:() => void,
    } | undefined>(undefined);


const ActiveContractsListContextProvider = ({ children }: Props) => {
  const [activeContractsList, setActiveContractsList] = useState(DEFAULT_DATA<UnitContractProjectionDto[]>([]));
  const [paramOfCachedValue, setParamOfCachedValue] = useState<ActiveContractsListCachedParamsType | undefined>(undefined);
  const [paramOfFetchInProgress, setParamOfFetchInProgress] = useState<{propertyId: number, atDate: moment.Moment} | undefined>(undefined);


  const resetActiveContractsListContext = () => {
    setActiveContractsList(prev => prev.resetToInitial());
    setParamOfCachedValue(undefined);
    setParamOfFetchInProgress(undefined);
  };

  // don't need to export paramOfFetchInProgress because it's value is stale,
  // we're using the setState(previuesValue => {...}) version of setState to get the up-to-date value
  const providerValue = {
    activeContractsList,
    setActiveContractsList,
    paramOfCachedValue,
    setParamOfCachedValue,
    setParamOfFetchInProgress,
    resetActiveContractsListContext,
  };

  return (
    <ActiveContractsListContext.Provider value={providerValue}>
      {children}
    </ActiveContractsListContext.Provider>
  );
};

export default ActiveContractsListContextProvider;
