import { formatCurrency, formatDate } from 'lib/Utils';
import { getViewContactRoute } from 'pages/Contacts';
import { getContractEditingRoute, getPropertyEditingRoute } from 'pages/Property/routes';
import { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import CellWithTwoLines from 'storybook-components/table/CellWithTwoLines/CellWithTwoLines';
import Amount from 'storybook-components/typography/Amount/Amount';
import TagsGroup from 'storybook-components/TagsGroup/TagsGroup';
import { isNil } from 'lodash';
import { ExtendedColumn } from 'storybook-components/table/TableColumnSelector/services/useTableColumnSelectorExtendedColumns';
import { getPropertyCellSubtitleInList } from 'lib/utilGetPropertyCellSubtitleInList';
import { FORMAT } from 'elements/SmartTable/SmartTable';
import TooltipForTableCell from 'storybook-components/table/TooltipForTableCell/TooltipForTableCell';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { translations } from '../translations';
import { DocumentListEntry } from './DocumentListContext';

export const useDocumentListColumns = () => {
  const { tl, language } = useContext(LanguageContext);

  // table row needs to open document editing view on clicking on the row,
  // and also at the same time, context related links should work correctly
  const stopLinkPropagation = (e: any) => {
    e.stopPropagation();
  };

  const columns: ExtendedColumn<DocumentListEntry>[] = useMemo(() => [

    {
      title: tl(translations.table.header.property),
      dataKey: 'propertyName',
      sortable: false,
      className: 'column-padding-medium',
      render: (_, record) => (
        record.propertyImpowerHrId
          ? (
            <TooltipForTableCell title={`${record.propertyName || '—'}\n${getPropertyCellSubtitleInList(record, 'propertyAdministrationType')}`}>
              <CellWithTwoLines
                firstElement={(
                  <Link
                    to={getPropertyEditingRoute(record.propertyImpowerHrId)}
                    onClick={stopLinkPropagation}
                    target="_blank"
                  >
                    {record.propertyName || '—'}
                  </Link>
                )}
                secondElement={getPropertyCellSubtitleInList(record, 'propertyAdministrationType')}
              />
            </TooltipForTableCell>
          ) : record.propertyName || '—'
      ),
    },
    {
      title: tl(translations.table.header.title),
      dataKey: 'name',
      sortable: false,
    },
    {
      title: tl(translations.table.header.tag),
      dataKey: 'tags',
      sortable: false,
      render: (_, record) => (
        <TagsGroup tags={record.tags?.filter(tag => !isNil(tag.name)).map(tag => tag.name).sort() || []} shorten />
      ),
    },
    {
      title: tl(translations.table.header.contract),
      dataKey: 'contractName',
      sortable: false,
      className: 'column-padding-medium',
      render: (_, record) => {
        const contactNavigationUrl = getViewContactRoute({ contactId: record.contractContactId });
        return (
          <CellWithTwoLines
            firstElement={(record.contractId
              ? (
                <Link
                  to={getContractEditingRoute({ propertyHrId: record.propertyImpowerHrId, unitDbId: record.unitIdOfContract, contractDbId: record.contractId })}
                  onClick={stopLinkPropagation}
                  target="_blank"
                >
                  {record.contractName}
                </Link>
              ) : ('—')
            )}
            secondElement={(record.contractContactType
              ? (
                <Link
                  to={contactNavigationUrl}
                  onClick={stopLinkPropagation}
                  target="_blank"
                >
                  {record?.contractName?.substring(record?.contractName?.includes(' - ') ? record?.contractName?.indexOf(' - ') + 3 : 0)}
                </Link>
              ) : ('—')
            )}
          />
        );
      },
    },
    {
      title: tl(translations.table.header.contact),
      dataKey: 'contact',
      sortable: false,
      render: (_, record) => {
        const contactNavigationUrl = !isNil(record.contactType) && getViewContactRoute({ contactId: record.contactId });
        return (
          record.contactType
            ? (
              <Link
                to={contactNavigationUrl}
                onClick={stopLinkPropagation}
                target="_blank"
              >
                {record.contactName}
              </Link>
            ) : ('—')
        );
      },
    },
    {
      title: tl(translations.table.header.date),
      dataKey: 'issuedDate',
      render: val => (val ? formatDate(val) : '—'),
      defaultWidth: 120,
    },
    {
      title: tl(translations.table.header.value),
      dataKey: 'amount',
      sortable: false,
      className: 'column-align-right',
      defaultWidth: 150,
      render: val => (val
        ? (
          <Amount color={val < 0 ? 'red' : undefined}>
            {formatCurrency(val)}
          </Amount>
        ) : '—'
      ),
    },
    {
      title: tl(translations.table.header.lastUpdated),
      dataKey: 'updated',
      sortable: false,
      className: 'column-align-right',
      render: val => (val ? formatDate(val) : '—'),
      defaultVisible: false,
    },
    {
      title: tl(translations.table.header.accountant),
      dataKey: 'accountant',
      defaultVisible: false,
      sortable: false,
    },
    {
      title: tl(translations.table.header.administrator),
      dataKey: 'administrator',
      defaultVisible: false,
      sortable: false,
    },
    {
      title: tl(translations.table.header.createdOn),
      dataKey: 'created',
      format: FORMAT.DATE,
      defaultVisible: false,
    },
    {
      title: tl(translations.table.header.createdBy),
      dataKey: 'createdBy',
      defaultVisible: false,
    },
  ], [language]);

  return {
    columns,
  };
};
