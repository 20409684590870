import { UnitContractProjectionDto } from 'api/accounting';
import DEFAULT_DATA, { DefaultDataInterface } from 'lib/data';
import React, { createContext, useState } from 'react';


interface Props {
  children: React.ReactNode;
}


export const AllContractsListContext = createContext<{
  allContractsList: DefaultDataInterface<UnitContractProjectionDto[]>,
  setAllContractsList: React.Dispatch<React.SetStateAction<DefaultDataInterface<UnitContractProjectionDto[]>>>,
  paramOfCachedValue: number | undefined,
  deleteContractInProgress: boolean,
  setParamOfCachedValue: React.Dispatch<React.SetStateAction<number | undefined>>,
  setParamOfFetchInProgress: React.Dispatch<React.SetStateAction<number | undefined>>,
  setDeleteContractInProgress: React.Dispatch<React.SetStateAction<boolean | undefined>>,
  resetAllContractsListContext:() => void,
    } | undefined>(undefined);


const AllContractsListContextProvider = ({ children }: Props) => {
  const [allContractsList, setAllContractsList] = useState(DEFAULT_DATA<UnitContractProjectionDto[]>([]));
  const [paramOfCachedValue, setParamOfCachedValue] = useState<number | undefined>(undefined);
  const [paramOfFetchInProgress, setParamOfFetchInProgress] = useState<number | undefined>(undefined);
  const [deleteContractInProgress, setDeleteContractInProgress] = useState<boolean>(false);


  const resetAllContractsListContext = () => {
    setAllContractsList(prev => prev.resetToInitial());
    setParamOfCachedValue(undefined);
    setParamOfFetchInProgress(undefined);
    setDeleteContractInProgress(false);
  };

  // putting them in objects so they are easier to destructure when using the contexts
  // no need to memoize since they're all in the same object
  const providerValue = {
    allContractsList,
    setAllContractsList,
    paramOfCachedValue,
    setParamOfCachedValue,
    setParamOfFetchInProgress,
    deleteContractInProgress,
    setDeleteContractInProgress,
    resetAllContractsListContext,
  };

  return (
    <AllContractsListContext.Provider value={providerValue}>
      {children}
    </AllContractsListContext.Provider>
  );
};

export default AllContractsListContextProvider;
