import { useContext } from 'react';
import { Tooltip } from 'antd';
import CellWithTwoLines from 'storybook-components/table/CellWithTwoLines/CellWithTwoLines';
import { getPropertyCellSubtitleInList } from 'lib/utilGetPropertyCellSubtitleInList';
import { LanguageContext } from 'contexts/LanguageContext';
import { translations } from 'elements/Translation/translations';

export const useHouseMoneySettlementPropertyListColumns = () => {
  const { tl } = useContext(LanguageContext);

  return [
    {
      title: tl(translations.pages.houseMoneySettlement.table.headers.hgaStatus),
      dataKey: ['hga', 'status'],
    },
    {
      title: tl(translations.pages.property.table.headers.name),
      dataKey: 'propertyName',
      render: (_, record) => (
        <Tooltip title={`${record.name || '—'}\n${getPropertyCellSubtitleInList(record, 'administrationType')}`}>
          {/* without this div the Tooltip doesn't work */}
          <div>
            <CellWithTwoLines
              firstElement={record.name || '—'}
              secondElement={getPropertyCellSubtitleInList(record, 'administrationType')}
            />
          </div>
        </Tooltip>
      ),
    },
    {
      title: tl(translations.pages.houseMoneySettlement.table.headers.administrator),
      dataKey: 'supervisorName',
    },
  ];
};
