import { useContext } from 'react';
import _ from 'lodash';
import {
  HouseMoneyListContext,
  HouseMoneySectionUpdatersContext,
  OpsCostContext,
  ReserveFundAmountContext,
} from '../../../services/HouseMoneyContext';
import { LanguageContext } from '../../../../../contexts/LanguageContext';
import { AuthContext } from '../../../../../contexts/AuthContext';
import { EconomicPlanDto, EconomicPlanHouseMoneyControllerApi, HouseMoneyDto } from '../../../../../api/accounting';
import { ActionTypes } from './ActionTypes';
import { showNotification } from '../../../../../lib/Notification';
import { economicPlanHouseMoneyTableTranslations } from '../economicPlanHouseMoneyTableTranslations';
import { useSimpleDirtModal } from '../../../../../elements/Modals/DirtModal/SimpleDirtModal/useSimpleDirtModal';


export const useSaveHouseMoney = () => {
  const opsCostContext = useContext(OpsCostContext);
  const reserveAccountDraftsContext = useContext(ReserveFundAmountContext);
  const houseMoneyListContext = useContext(HouseMoneyListContext);
  const updatersContext = useContext(HouseMoneySectionUpdatersContext);
  const { tl } = useContext(LanguageContext);

  const { apiConfiguration } = useContext(AuthContext);
  const houseMoneyControllerApi = new EconomicPlanHouseMoneyControllerApi(apiConfiguration('accounting'));

  if (reserveAccountDraftsContext === undefined
    || opsCostContext === undefined
    || houseMoneyListContext === undefined
    || updatersContext === undefined
  ) {
    throw new Error('useSaveHouseMoneySection must be used within a HouseMoneyListContextProvider');
  }
  const { clearDirty } = useSimpleDirtModal();

  const { opsCosts } = opsCostContext;
  const { reserveFundAmounts } = reserveAccountDraftsContext;
  const {
    houseMoneyList,
  } = houseMoneyListContext;

  const {
    setHouseMoneyList,
  } = updatersContext;

  const collectHouseMoney = (actionType: string) => {
    const houseMoneyWithActionType: HouseMoneyDto[] = opsCosts.map(unitOpsCostAmount => ({
      unitId: unitOpsCostAmount.unitId,
      houseMoneyAmounts: unitOpsCostAmount.action === actionType ? [{
        id: unitOpsCostAmount?.id,
        amount: unitOpsCostAmount.amount,
        reserveFundId: undefined,
      }] : [],
    }));

    houseMoneyWithActionType.forEach((unitHouseMoney) => {
      const unitReserveFundAmounts = reserveFundAmounts.find(reserveFundAmount => reserveFundAmount[0].unitId === unitHouseMoney.unitId);
      const unitReserveFundAmountsWithActionType = unitReserveFundAmounts?.filter(amount => amount.action === actionType);
      if (!_.isEmpty(unitReserveFundAmountsWithActionType)) {
        unitHouseMoney.houseMoneyAmounts.push(
          ...unitReserveFundAmountsWithActionType
            .map(reserveFundAmount => ({
              id: reserveFundAmount.id,
              amount: reserveFundAmount.amount,
              reserveFundId: reserveFundAmount.reserveFundId,
            })),
        );
      }
    });
    return houseMoneyWithActionType;
  };

  const unite = (prevState: HouseMoneyDto[], dataFromServer: HouseMoneyDto[]) => {
    const prevStateUpdated: HouseMoneyDto[] = prevState.map((unitHouseMoney) => {
      const updatedUnitHouseMoney = dataFromServer.find(it => it.unitId === unitHouseMoney.unitId);
      if (updatedUnitHouseMoney) {
        return {
          ...updatedUnitHouseMoney,
          houseMoneyAmounts: (
            unitHouseMoney.houseMoneyAmounts.map(previousAmount => updatedUnitHouseMoney.houseMoneyAmounts.find(it => it.id === previousAmount.id) ?? previousAmount)
          ),
        };
      }
      return unitHouseMoney;
    });
    const unitsNotPresentInPreviousState = dataFromServer.filter(newUnit => !prevState.find(prevUnit => prevUnit.unitId === newUnit.unitId)) || [];
    return [...prevStateUpdated, ...unitsNotPresentInPreviousState];
  };

  const mapResponseToState = (response: HouseMoneyDto[], economicPlan: EconomicPlanDto) => {
    setHouseMoneyList(prevState => prevState.load(unite(prevState.data, response)));
    clearDirty();
    return Promise.resolve(economicPlan);
  };

  const saveHouseMoneyAmounts = (economicPlan: EconomicPlanDto) => {
    setHouseMoneyList(prevState => prevState.startLoading());
    const dataForCreation = collectHouseMoney(ActionTypes.CREATE)
      ?.map(houseMoney => ({
        ...houseMoney,
        economicPlanId: economicPlan.id,
      }))
      ?.filter(houseMoney => !_.isEmpty(houseMoney.houseMoneyAmounts));

    if (!_.isEmpty(dataForCreation)) {
      return houseMoneyControllerApi.createHouseMoneyAmountsUsingPOST({
        economicPlanId: economicPlan.id,
        houseMoneyDtoList: dataForCreation ?? [],
      })
        .then(response => mapResponseToState(response, economicPlan))
        .catch(() => {
          showNotification({
            key: 'saveHouseMoneyError',
            message: tl(economicPlanHouseMoneyTableTranslations.notifications.saveHmError.message),
            type: 'error',
          });
          setHouseMoneyList(prevState => prevState.failed());
          return Promise.reject();
        });
    }

    const dataForUpdate = collectHouseMoney(ActionTypes.UPDATE)
      ?.map(houseMoney => ({
        ...houseMoney,
        economicPlanId: economicPlan.id,
      }))
      ?.filter(houseMoney => !_.isEmpty(houseMoney.houseMoneyAmounts));

    if (!_.isEmpty(dataForUpdate)) {
      return houseMoneyControllerApi.updateHouseMoneyAmountsUsingPATCH({
        economicPlanId: economicPlan.id,
        houseMoneyDtoList: dataForUpdate ?? [],
      })
        .then(response => mapResponseToState(response, economicPlan))
        .catch(() => {
          showNotification({
            key: 'saveHouseMoneyError',
            message: tl(economicPlanHouseMoneyTableTranslations.notifications.saveHmError.message),
            type: 'error',
          });
          setHouseMoneyList(prevState => prevState.failed());
          return Promise.reject();
        });
    }
    setHouseMoneyList(prevState => prevState.finishLoading());
    return Promise.resolve(economicPlan);
  };

  return {
    saveHouseMoneyAmounts,
    canSave: !houseMoneyList.loading,
  };
};
