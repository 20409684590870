import React from 'react';

import ContactsListContextProvider from 'services/ContactsList/ContactsListContext';
import ActiveContractsListContextProvider from 'services/UnitContractsList/ActiveContractsListContext';
import DirectDebitMandateListContextProvider from 'services/DirectDebitMandateList/DirectDebitMandateListContext';
import AllContractsListContextProvider from 'pages/Property/PropertyEditing/services/AllContractsListContext';
import UnitsListContextProvider from 'services/UnitsList/UnitsListContext';
import EconomicPlanContextProvider from 'pages/EconomicPlan/services/EconomicPlanContext';
import MuiPickersUtilsAndLocaleProvider from 'contexts/MuiPickersUtilsAndLocaleProvider';
import WkaListContextProvider from 'pages/Wka/services/WkaListContext';
import { ConfigProvider } from 'antd';
import VatRatesContextProvider from 'services/vatRates/VatRatesContext';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Etg24UnitContractsContextProvider from 'pages/UnitContractHistory/services/Etg24UnitContractsContext';
import AuthProvider from '../../contexts/AuthContext';
import LanguageProvider from '../../contexts/LanguageContext';
import PropertyListProvider from '../../contexts/PropertyListContext';
import PropertyEditingProvider from '../../pages/Property/PropertyEditing/services/PropertyEditingContext/PropertyEditingContext';
import InvoiceListProvider from '../../contexts/InvoiceListContext';
import InvoiceEditingProvider from '../../contexts/InvoiceEditingContext';
import ContactEditingProvider from '../../contexts/PersonEditingContext';
import PropertyManagementCompaniesProvider from '../../contexts/PropertyManagementCompaniesContext';
import ContactListProvider from '../../contexts/ContactListContext';
import AccountListProvider from '../../contexts/AccountListContext';
import StandingOrderContextProvider from '../../contexts/StandingOrderContext';
import DirectDebitMandateListProvider from '../../contexts/DirectDebitMandateListContext';
import BankAccountListProvider from '../../contexts/BankAccountListContext';
import PaymentCreationProvider from '../../contexts/PaymentCreationContext';
import AccountSalesListProvider from '../../contexts/AccountSalesListContext';
import AccountBalancesListProvider from '../../contexts/AccountBalancesListContext';
import PostingListProvider from '../../contexts/PostingListContext';
import DistributionKeyProvider from '../../contexts/DistributionKeyContext';
import PropertyDistributionKeyListProvider from '../../contexts/PropertyDistributionKeyListContext';
import { AccountsBalanceProvider } from '../../contexts/AccountsBalanceContext';
import ResolutionRecordProvider from '../../contexts/ResolutionRecordContext';
import HouseMoneySettlementProvider from '../../contexts/HouseMoneySettlementContext';
import CompanyEditingProvider from '../../contexts/CompanyEditingContext';
import AccountBookingProvider from '../../contexts/AccountBookingContext';
import OwnersMeetingInvitationProvider from '../../contexts/OwnersMeetingInvitationContext';
import { OverlayContextProvider } from '../../services/OverlayContext/OverlayContext';
import PropertyProvider from '../../contexts/PropertyContext';
import FormEditingProvider from '../../contexts/FormEditingContext';
import AgendaItemsContextProvider
  from '../../contexts/AgendaItemsContext';

export default function ContextProvider({ children }: any): JSX.Element {
  return (
    <div style={{ overflow: 'hidden' }}>
      <LanguageProvider>
        <MuiPickersUtilsAndLocaleProvider>
          <DndProvider backend={HTML5Backend}>
            {/* hide the default empty table placeholder; can be overwritten by wrapping the table in another ConfigProvider */}
            <ConfigProvider renderEmpty={() => null}>
              <AuthProvider>
                <OverlayContextProvider>
                  <ContactsListContextProvider>
                    <PropertyListProvider>
                      <PropertyEditingProvider>
                        <UnitsListContextProvider>
                          <AllContractsListContextProvider>
                            <ActiveContractsListContextProvider>
                              <PropertyManagementCompaniesProvider>
                                <VatRatesContextProvider>
                                  <InvoiceListProvider>
                                    <InvoiceEditingProvider>
                                      <AccountListProvider>
                                        <DirectDebitMandateListProvider>
                                          <ContactListProvider>
                                            <ContactEditingProvider>
                                              <BankAccountListProvider>
                                                <AccountSalesListProvider>
                                                  <PaymentCreationProvider>
                                                    <StandingOrderContextProvider>
                                                      <AccountBalancesListProvider>
                                                        <PropertyDistributionKeyListProvider>
                                                          <DistributionKeyProvider>
                                                            <AccountsBalanceProvider>
                                                              <PostingListProvider>
                                                                <ResolutionRecordProvider>
                                                                  <HouseMoneySettlementProvider>
                                                                    <OwnersMeetingInvitationProvider>
                                                                      <WkaListContextProvider>
                                                                        <CompanyEditingProvider>
                                                                          <AccountBookingProvider>
                                                                            <PropertyProvider>
                                                                              <FormEditingProvider>
                                                                                {/* TODO colocate after PMP-10896 is implemented */}
                                                                                <DirectDebitMandateListContextProvider>
                                                                                  <EconomicPlanContextProvider>
                                                                                    <AgendaItemsContextProvider>
                                                                                      <Etg24UnitContractsContextProvider>
                                                                                        {children}
                                                                                      </Etg24UnitContractsContextProvider>
                                                                                    </AgendaItemsContextProvider>
                                                                                  </EconomicPlanContextProvider>
                                                                                </DirectDebitMandateListContextProvider>
                                                                              </FormEditingProvider>
                                                                            </PropertyProvider>
                                                                          </AccountBookingProvider>
                                                                        </CompanyEditingProvider>
                                                                      </WkaListContextProvider>
                                                                    </OwnersMeetingInvitationProvider>
                                                                  </HouseMoneySettlementProvider>
                                                                </ResolutionRecordProvider>
                                                              </PostingListProvider>
                                                            </AccountsBalanceProvider>
                                                          </DistributionKeyProvider>
                                                        </PropertyDistributionKeyListProvider>
                                                      </AccountBalancesListProvider>
                                                    </StandingOrderContextProvider>
                                                  </PaymentCreationProvider>
                                                </AccountSalesListProvider>
                                              </BankAccountListProvider>
                                            </ContactEditingProvider>
                                          </ContactListProvider>
                                        </DirectDebitMandateListProvider>
                                      </AccountListProvider>
                                    </InvoiceEditingProvider>
                                  </InvoiceListProvider>
                                </VatRatesContextProvider>
                              </PropertyManagementCompaniesProvider>
                            </ActiveContractsListContextProvider>
                          </AllContractsListContextProvider>
                        </UnitsListContextProvider>
                      </PropertyEditingProvider>
                    </PropertyListProvider>
                  </ContactsListContextProvider>
                </OverlayContextProvider>
              </AuthProvider>
            </ConfigProvider>
          </DndProvider>
        </MuiPickersUtilsAndLocaleProvider>
      </LanguageProvider>
    </div>
  );
}
