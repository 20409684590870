import {
  PropertyBankAccountProjectionDtoPropertyAccountTypesEnum,
  PropertyDisplayDtoPropertyStateEnum,
} from 'api/accounting';

export const translations = {
  accounts: {
    mainCategory: {
      houseMoneySettlement: {
        en: 'Incomes and Expenses',
        de: 'Einnahmen und Ausgaben',
      },
      wealthStatus: {
        en: 'Reserve funds and Results',
        de: 'Rücklagen und Ergebnisse',
      },
      debitorsCreditors: {
        en: 'Debitors and creditors',
        de: 'Debitoren und Kreditoren',
      },
    },
    subCategory: {
      revenue: {
        en: 'Revenues',
        de: 'Einnahmen',
      },
      expense: {
        en: 'Expenses',
        de: 'Ausgaben',
      },
      liquidity: {
        en: 'Liquidity',
        de: 'Flüssige Mittel, Aktive RAP',
      },
      liability: {
        en: 'Liabilities',
        de: 'Verbindlichkeiten',
      },
      capital: {
        en: 'Capital',
        de: 'Rücklagen & Abrechnungsspitze',
      },
      openingStatus: {
        en: 'Opening status',
        de: 'Eröffnungsbestände',
      },
      debitors: {
        en: 'Debitors',
        de: 'Debitoren',
      },
      creditors: {
        en: 'Creditors',
        de: 'Kreditoren',
      },
    },
  },
  elements: {
    richTextEditor: {
      imageUploadError: {
        en: 'Failed to upload image',
        de: 'Bild konnte nicht hochgeladen werden',
      },
    },
    autoGeneratedPlaceholder: {
      en: 'Is generated by the system',
      de: 'Wird durch System generiert',
    },
    header: {
      searchPlaceholder: {
        en: 'Search',
        de: 'Suche',
      },
    },
    fileUpload: {
      required: {
        en: ' (Please upload)',
        de: ' (Bitte hochladen)',
      },
      uploadError: {
        message: {
          en: 'Upload failed',
          de: 'Upload fehlgeschlagen',
        },
        description: {
          en: 'Something went wrong during file upload.',
          de: 'Beim Hochladen der Datei ist ein Fehler aufgetreten.',
        },
        unsupportedFormat: {
          en: ' Uploaded file must be a PDF.',
          de: ' Die hochgeladene Datei muss ein PDF sein.',
        },
        wrongDimensions: {
          en: ' PDF dimensions are not matching the A4 format.',
          de: ' Das PDF-Format stimmen nicht mit dem A4-Format überein.',
        },
      },
      downloadError: {
        message: {
          en: 'Download failed',
          de: 'Download fehlgeschlagen',
        },
      },
      noContentDownload: {
        message: {
          en: 'No bookings happened on the selected accounts',
          de: 'Auf den ausgewählten Konten sind keine Buchungen erfolgt',
        },
      },
      buttonLabel: {
        en: 'Choose file',
        de: 'Dateien auswählen',
      },
      buttonLabelLine: {
        en: 'Select file...',
        de: 'Datei auswählen...',
      },
      modal: {
        title: {
          en: 'Delete file',
          de: 'Dokument löschen',
        },
        cancelButton: {
          en: 'Cancel',
          de: 'Abbrechen',
        },
        deleteButton: {
          en: 'Delete',
          de: 'Löschen',
        },
        description: {
          en: 'Do you want to delete this document?',
          de: 'Möchten Sie das Dokument löschen?',
        },
      },
    },
    radioGroup: {
      yes: {
        en: 'Yes',
        de: 'Ja',
      },
      no: {
        en: 'No',
        de: 'Nein',
      },
      selected: {
        en: 'selected',
        de: 'ausgewählt',
      },
    },
    propertyOptionElement: {
      propertyIdInternal: {
        en: 'Internal Id',
        de: 'Objekt-Nr intern',
      },
      stateLabels: {
        [PropertyDisplayDtoPropertyStateEnum.OFFBOARDED]: {
          de: 'Abgegeben',
          en: 'Offboarded',
        },
        [PropertyDisplayDtoPropertyStateEnum.DRAFT]: {
          de: 'Entwurf',
          en: 'Draft',
        },
        [PropertyDisplayDtoPropertyStateEnum.READY]: {
          // these values are not in sync with the ones in the property list/property editing ui
          de: 'Aktiv',
          en: 'Active',
        },
      },
    },
    selectInput: {
      placeholder: {
        en: 'Please select...',
        de: 'Bitte auswählen...',
      },
      noMatch: {
        en: 'No match',
        de: 'Keine Übereinstimmung',
      },
    },
    economicYearPicker: {
      addNew: {
        en: 'Copy Distribution Keys',
        de: 'Verteilerschlüssel kopieren',
      },
      placeholder: {
        en: 'Please select...',
        de: 'Bitte auswählen...',
      },
    },
    textInput: {
      placeholder: {
        en: 'Start typing...',
        de: 'Bitte ausfüllen...',
      },
    },
    textAreaInput: {
      placeholder: {
        en: 'Start typing...',
        de: 'Bitte ausfüllen...',
      },
      edit: {
        en: 'Edit',
        de: 'Bearbeiten',
      },
    },
    smartSearch: {
      placeholder: {
        en: 'Please select or start typing...',
        de: 'Bitte auswählen oder eintippen...',
      },
      addMore: {
        en: 'Add more',
        de: 'Hinzufügen',
      },
      edit: {
        en: 'Edit',
        de: 'Bearbeiten',
      },
      addMorePerson: {
        en: 'Add new person',
        de: 'Neue Person hinzufügen',
      },
      addMoreCompany: {
        en: 'Add new company',
        de: 'Neue Firma hinzufügen',
      },
    },
    contactModal: {
      title: {
        en: 'Contact editing',
        de: 'Kontaktbearbeitung',
      },
      cancelButton: {
        en: 'Cancel',
        de: 'Abbrechen',
      },
      saveButton: {
        en: 'Save',
        de: 'Speichern',
      },
      contactDataFields: {
        companyName: {
          en: 'Company name',
          de: 'Name der Firma',
        },
        email: {
          en: 'Email',
          de: 'Email',
        },
        firstName: {
          en: 'First name',
          de: 'Vorname',
        },
        lastName: {
          en: 'Last name',
          de: 'Nachname',
        },
        phoneMobile: {
          en: 'Phone mobile',
          de: 'Mobiltelefon',
        },
        phoneOffice: {
          en: 'Phone office',
          de: 'Telefon Büro',
        },
        phonePrivate: {
          en: 'Phone private',
          de: 'Telefon privat',
        },
        salutation: {
          en: 'Salutation',
          de: 'Anrede',
        },
        title: {
          en: 'Title',
          de: 'Titel',
        },
        address: {
          en: 'Address',
          de: 'Adresse',
        },
        alternativeAddress: {
          en: 'Alternative address',
          de: 'Alternative Adresse',
        },
        addressFields: {
          additionalAddress1: {
            en: 'Address extra',
            de: 'Adresse extra',
          },
          city: {
            en: 'City',
            de: 'Stadt',
          },
          country: {
            en: 'Country',
            de: 'Land',
          },
          street: {
            en: 'Street',
            de: 'Straße',
          },
          number: {
            en: 'Number',
            de: 'Nummer',
          },
          postalCode: {
            en: 'Postal code',
            de: 'Postleitzahl',
          },
          recipient: {
            en: 'Recipient',
            de: 'Empfänger',
          },
        },
      },
    },
    serviceCompanyModal: {
      title: {
        en: 'Service provider',
        de: 'Dienstleister Kontaktinformationen',
      },
      cancelButton: {
        en: 'Cancel',
        de: 'Abbrechen',
      },
      saveButton: {
        en: 'Save',
        de: 'Speichern',
      },
      serviceCompanyDataFields: {
        name: {
          en: 'Company name',
          de: 'Name der Firma',
        },
        serviceType: {
          label: {
            en: 'Service type',
            de: 'Dienstleister Art',
          },
          options: {
            caretaker: { en: 'CARETAKER', de: 'HAUSMEISTER' },
            cleaning: { en: 'CLEANING', de: 'REINIGUNG' },
            winterService: { en: 'WINTER SERVICE', de: 'WINTERDIENST' },
            ac: { en: 'AC', de: 'KLIMA' },
            heating: { en: 'HEATING', de: 'HEIZUNG' },
            outdoor: { en: 'OUTDOOR_SERVICE', de: 'AUSSENANLAGENBETREUUNG' },
            water: { en: 'WATER TREATMENT', de: 'WASSERVERSORGUNG' },
            tv: { en: 'TELEVISION PROVIDER', de: 'TV ANBIETER' },
            internet: { en: 'INTERNET PROVIDER', de: 'INTERNET ANBIETER' },
            smokeDetector: { en: 'SMOKE DETECTOR', de: 'RAUCHMELDER' },
            ventilation: { en: 'VENTILATION', de: 'AUFZUG' },
            smokeDuct: { en: 'SMOKE DUCT', de: 'RAUCHKANAL' },
            elevator: { en: 'ELEVATOR', de: 'AUFZUG' },
            garage: { en: 'GARAGE DOOR', de: 'GARAGENTOR' },
            energy: { en: 'ENERGY CERTIFICATE', de: 'ENERGIEAUSWEIS' },
            legionnaires: { en: 'LEGIONNAIRES_PROBING', de: 'LEGIONELLENBEPROBUNG' },
            extinguisher: { en: 'EXTINGUISHER', de: ' FEUERLÖSCHER' },
            biller: { en: 'BILLER', de: 'ABRECHNER' },
            disposal: { en: 'DISPOSAL', de: 'ENTSORGUNG' },
            sanitary: { en: 'SANITARY', de: 'SANITÄR' },
            locking: { en: 'LOCKING SYSTEM', de: 'SCHLIEßANLAGE' },
            other: { en: 'OTHER', de: 'ANDERE' },
          },
        },
        note: {
          en: 'Notes',
          de: 'Notizen',
        },
        address: {
          en: 'Address',
          de: 'Adresse',
        },
        bankAccount: {
          en: 'Bank account',
          de: 'Bankkonto',
        },
        addressFields: {
          additionalAddress1: {
            en: 'Additional address',
            de: 'Adresszusatz',
          },
          city: {
            en: 'City',
            de: 'Stadt',
          },
          country: {
            en: 'Country',
            de: 'Land',
          },
          street: {
            en: 'Street',
            de: 'Straße',
          },
          number: {
            en: 'Number',
            de: 'Nummer',
          },
          postalCode: {
            en: 'Postal code',
            de: 'Postleitzahl',
          },
          recipient: {
            en: 'Recipient',
            de: 'Empfänger',
          },
        },
        bankAccountFields: {
          iban: {
            en: 'IBAN',
            de: 'IBAN',
          },
          bic: {
            en: 'BIC',
            de: 'BIC',
          },
          accountHolderName: {
            en: 'Account holder name',
            de: 'Inhaber',
          },
        },
      },
    },
    navigationBar: {
      navigationItems: {
        dashboard: {
          en: 'Dashboard',
          de: 'Dashboard',
        },
        objectData: {
          en: 'Object data',
          de: 'Objektdaten',
        },
        propertyAccounting: {
          en: 'Property accounting',
          de: 'Objektbuchhaltung',
        },
        contacts: {
          en: 'Contacts',
          de: 'Kontakt',
        },
        evaluations: {
          en: 'Evaluations',
          de: 'Auswertungen',
        },
        settings: {
          en: 'Settings',
          de: 'Einstellungen',
        },
        help: {
          en: 'Help',
          de: 'Hilfe',
        },
      },
    },
    section: {
      buttonClosed: {
        en: 'Edit',
        de: 'Bearbeiten',
      },
      save: {
        en: 'Save and Continue',
        de: 'Speichern und Weiter',
      },
      subsectionTitle: {
        optional: {
          en: 'optional',
          de: 'optional',
        },
      },
      copy: {
        en: 'Copy from',
        de: 'Kopie von',
      },
      add: {
        en: 'Add',
        de: 'Hinzufügen',
      },
      collapse: {
        en: 'Collapse',
        de: 'Zuklappen',
      },
    },
    tableHeader: {
      sort: {
        en: 'Sort',
        de: 'Sortieren',
      },
      ascending: {
        en: 'Ascending',
        de: 'Aufsteigend',
      },
      descending: {
        en: 'Descending',
        de: 'Absteigend',
      },
    },
    dirtModal: {
      title: {
        en: 'You have unsaved changes',
        de: 'Sie haben nicht gespeicherte Änderungen',
      },
      description: {
        en: 'Save the changes before closing section?',
        de: 'Änderungen vor dem Schließen des Abschnitts speichern?',
      },
      cancel: {
        en: 'Cancel',
        de: 'Abbrechen',
      },
      discard: {
        en: 'Discard changes',
        de: 'Änderungen verwerfen',
      },
      save: {
        en: 'Save',
        de: 'Speichern',
      },
    },
    deactivateModal: {
      title: {
        en: 'Offboard property',
        de: 'Objekt deaktivieren',
      },
      description: {
        en: 'Are you sure you want to offboard this property?',
        de: 'Sind Sie sicher, dass Sie dieses Objekt deaktivieren wollen?',
      },
      cancel: {
        en: 'Cancel',
        de: 'Abbrechen',
      },
      deactivate: {
        en: 'Offboard',
        de: 'Deaktivieren',
      },
    },
    prompt: {
      en: 'You have unsaved changes that will be lost. Do you still want to navigate?',
      de: 'Sie haben nicht gespeicherte Änderungen. Möchten Sie weiter?',
    },
    deletionModal: {
      title: {
        en: 'Are you sure you want to delete this?',
        de: 'Möchten Sie dies wirklich löschen?',
      },
      description: {
        en: 'You will not be able to restore your data.',
        de: 'Sie können Ihre Daten nicht wiederherstellen.',
      },
      cancel: {
        en: 'Cancel',
        de: 'Abbrechen',
      },
      delete: {
        en: 'Delete',
        de: 'Löschen',
      },
    },
    dataExportModal: {
      title: {
        en: 'Data export',
        de: 'Daten exportieren',
      },
      description: {
        en: 'You will not be able to restore your data.',
        de: 'Sie können Ihre Daten nicht wiederherstellen.',
      },
      cancel: {
        en: 'Cancel',
        de: 'Abbrechen',
      },
      download: {
        en: 'Download',
        de: 'Herunterladen',
      },
    },
    smartTable: {
      noData: {
        en: 'This table is empty',
        de: 'Diese Tabelle ist leer',
      },
      all: {
        en: 'All',
        de: 'Alle',
      },
    },
    summaryRow: {
      title: {
        en: 'Summary',
        de: 'Gesamt',
      },
    },
    iconFileUpload: {
      download: {
        title: {
          en: 'Download',
          de: 'Herunterladen',
        },
      },
      delete: {
        title: {
          en: 'Delete',
          de: 'Löschen',
        },
      },
      upload: {
        title: {
          en: 'Upload',
          de: 'Hochladen',
        },
      },
    },
    filterButton: {
      label: {
        en: 'Filter',
        de: 'Filtern',
      },
    },
  },
  pages: {
    errorPage: {
      errorOccured: {
        en: 'An error occured.',
        de: 'Ein Fehler ist aufgetreten.',
      },
      navigate: {
        en: 'Please navigate',
        de: 'Bitte navigieren Sie',
      },
      back: {
        en: 'Back',
        de: 'zurück',
      },
      or: {
        en: 'Or',
        de: 'Oder',
      },
      reload: {
        en: 'Reload',
        de: 'neu laden',
      },
    },
    loginPage: {
      title: {
        en: 'Welcome to Impower',
        de: 'Willkommen bei Impower',
      },
      subTitle: {
        en: 'Please log in with your access data.',
        de: 'Bitte melden Sie sich mit Ihren Zugangsdaten an.',
      },
      loginForm: {
        domainPlaceholder: {
          en: 'Domain',
          de: 'Domain',
        },
        emailPlaceholder: {
          en: 'Email',
          de: 'E-mail',
        },
        passwordPlaceholder: {
          en: 'Password',
          de: 'Passwort',
        },
        loginButton: {
          en: 'Log in',
          de: 'Anmelden',
        },
        forgotPassword: {
          en: 'Forgot your password?',
          de: 'Passwort vergessen?',
        },
        notRegistered: {
          en: 'Not registered yet?',
          de: 'Noch nich registriert?',
        },
        register: {
          en: 'Register',
          de: 'Registrieren',
        },
      },
    },
    home: {
      title: {
        en: 'Welcome to the hooks home page',
        de: 'Willkommen auf der Hooks-Homepage',
      },
      logout: {
        en: 'Log out',
        de: 'Ausloggen',
      },
    },
    property: {
      title: {
        en: 'Property',
        de: 'Objekte',
      },
      addButton: {
        buttonLabel: {
          en: 'Create',
          de: 'Erstellen',
        },
        dropdownOptions: {
          mvAndWegGroupLabel: {
            en: 'Property management',
            de: 'Objektverwaltung',
          },
          mvLabel: {
            en: 'MV · Mietverwaltung',
            de: 'MV · Mietverwaltung',
          },
          wegLabel: {
            en: 'WEG · Wohnungseigentum',
            de: 'WEG · Wohnungseigentum',
          },

          sevGroupLabel: {
            en: 'Special property management',
            de: 'Sondereigenumsverwaltung',
          },
          sevWithWegLabel: {
            en: 'SEV with WEG connection',
            de: 'SEV mit WEG-Verbindung',
          },
          sevWithoutWegLabel: {
            en: 'SEV without WEG connection',
            de: 'SEV ohne WEG-Verbindung',
          },

          importGroupLabel: {
            en: 'Import',
            de: 'Import',
          },
        },
      },
      table: {
        headers: {
          type: {
            en: 'Adminstration type',
            de: 'Verwaltungsart',
          },
          propertyHrId: {
            en: 'Property ID',
            de: 'Objekt ID',
          },
          internalNr: {
            en: 'Property-Nr.',
            de: 'Objekt-Nr.',
          },
          name: {
            en: 'Property',
            de: 'Objekt',
          },
          status: {
            en: 'Status',
            de: 'Status',
          },
          property: {
            en: 'Property',
            de: 'Objekt',
          },
          address: {
            en: 'Address',
            de: 'Adresse',
          },
          units: {
            en: 'Units',
            de: 'Einheiten',
          },
          clientCategory: {
            en: 'Client category',
            de: 'Kundenkategorie',
          },
          administrator: {
            en: 'Administrator',
            de: 'Objektbetreuer',
          },
          accountant: {
            en: 'Accountant',
            de: 'Objektbuchhalter',
          },
          updated: {
            en: 'Updated on',
            de: 'Aktualisiert am',
          },
          updatedBy: {
            en: 'Updated by',
            de: 'Aktualisiert von',
          },
          created: {
            en: 'Created on',
            de: 'Erstellt am',
          },
          createdBy: {
            en: 'Created by',
            de: 'Erstellt von',
          },
        },
        actions: {
          delete: {
            en: 'Delete',
            de: 'Löschen',
          },
        },
        propertyStates: {
          DRAFT: {
            en: 'Draft',
            de: 'Entwurf',
          },
          READY: {
            // legacy term not changed due to the cost of updating all support articles & videos
            en: 'Bookable',
            de: 'Bereit zur Verbuchung',
          },
          COMPLETED: {
            en: 'Completed',
            de: 'Vollständig',
          },
          OFFBOARDED: {
            en: 'Offboarded',
            de: 'Abgegeben',
          },
        },
        lastItem: {
          en: 'No more property to load',
          de: 'Kein Objekte mehr zu laden',
        },
      },
      SubNavigationBar: {
        property: {
          name: {
            en: 'Property',
            de: 'Objekte',
          },
          items: {
            overview: {
              en: 'Overview',
              de: 'Übersicht',
            },
          },
        },
      },
      editPage: {
        createTitle: (administrationType: string) => ({
          en: `Create new ${administrationType} property`,
          de: `Neues ${administrationType} Objekt erstellen`,
        }),
        editTitle: (administrationType: string) => ({
          en: `Edit ${administrationType} property`,
          de: `${administrationType} Objekt bearbeiten`,
        }),
        cancel: {
          en: 'Cancel',
          de: 'Abbrechen',
        },
        saveAll: {
          en: 'Save all',
          de: 'Als Entwurf Speichern',
        },
        save: {
          en: 'Save',
          de: 'Speichern',
        },
        readyForInvoice: {
          // legacy term not changed due to the cost of updating all support articles & videos
          en: 'Ready for invoice',
          de: 'Bereit zur Verbuchung',
        },
        editButton: {
          en: 'Edit',
          de: 'Bearbeiten',
        },
        backButton: {
          en: 'Back',
          de: 'Zurück',
        },
        closeButton: {
          en: 'Close',
          de: 'Schließen',
        },
        deactivateButton: {
          en: 'Offboard',
          de: 'Deaktivieren',
        },
        activate: {
          en: 'Activate',
          de: 'Aktivieren',
        },
        sidebar: {
          title: {
            en: 'Overview',
            de: 'Übersicht',
          },
        },
        notReadyForInvoice: {
          message: {
            en: 'Property is not ready for invoice',
            de: 'Objekt ist nicht bereit zur Verbuchung',
          },
          description: {
            en: 'Highlighted sections have incomplete information.',
            de: 'In den hervorgehobenen Sektionen sind Informationen unvollständig hinterlegt.',
          },
        },
        sections: {
          save: {
            en: 'Save and Continue',
            de: 'Speichern und Weiter',
          },
          section1: {
            title: {
              en: 'Property Management Data',
              de: 'Verwaltung',
            },
            subsection1: {
              title: {
                en: 'Property address',
                de: 'Objektadresse',
              },
              fields: {
                internalNr: {
                  en: 'Property-Nr.',
                  de: 'Objekt-Nr.',
                },
                street: {
                  en: 'Street',
                  de: 'Straße',
                },
                number: {
                  en: 'House number',
                  de: 'Hausnummer',
                },
                postalCode: {
                  en: 'Postal code',
                  de: 'Postleitzahl (PLZ)',
                },
                location: {
                  en: 'Location',
                  de: 'Ort',
                },
                country: {
                  title: {
                    en: 'Country',
                    de: 'Land',
                  },
                },
                state: {
                  en: 'State',
                  de: 'Bundesland',
                },
                propertyName: {
                  en: 'Property name',
                  de: 'Objektbezeichnung',
                },
              },
            },
            subsection2: {
              title: {
                en: 'Billing data',
                de: 'Rechnungsadresse',
              },
              fields: {
                recipient: {
                  en: 'Recipient',
                  de: 'Rechnungsempfänger',
                },
                additionalAddress1: {
                  en: 'Additional address',
                  de: 'Adress-Zusatz',
                },
                additionalAddress2: {
                  en: 'Additional building',
                  de: 'Gebäude-Zusatz',
                },
                billingStreet: {
                  en: 'Street',
                  de: 'Straße',
                },
                billingNumber: {
                  en: 'Number',
                  de: 'Hausnummer',
                },
                billingPostalCode: {
                  en: 'Postal code',
                  de: 'PLZ',
                },
                billingLocation: {
                  en: 'Location',
                  de: 'Ort',
                },
                billingCountry: {
                  title: {
                    en: 'Country',
                    de: 'Land',
                  },
                },
              },
            },
            subsection3: {
              title: {
                en: 'Sharing declaration',
                de: 'Teilungserklärung',
              },
              fields: {
                ownerChangeApproval: {
                  en: 'Administration approval for owner change',
                  de: 'Verwalterzustimmung ET-Wechsel',
                },
                decisionThreshold: {
                  title: {
                    en: 'Decision threshold',
                    de: 'Schwelle zur Beschlussfähigkeit',
                  },
                  majority: {
                    en: '> 50 %',
                    de: '> 50 %',
                  },
                  always: {
                    en: 'Always capable',
                    de: 'Immer beschlussfähig',
                  },
                  other: {
                    en: 'Other',
                    de: 'Sonst.',
                  },
                },
                votingType: {
                  title: {
                    en: 'Voting type',
                    de: 'Abstimmungsprinzip',
                  },
                  share: {
                    en: 'Ownership share',
                    de: 'Wertprinzip (Miteigentumsanteile)',
                  },
                  units: {
                    en: 'Units',
                    de: 'Einheitenprinzip (Einheiten)',
                  },
                  heads: {
                    en: 'Owners',
                    de: 'Kopfprinzip (Eigentümer)',
                  },
                },
                sharingDeclarationUrl: {
                  en: 'Sharing declaration',
                  de: 'Teilungserklärung',
                },
              },
            },
            subsection4: {
              title: {
                en: 'Property Care',
                de: 'Objektbetreuung',
              },
              fields: {
                administrationType: {
                  title: {
                    en: 'Adminstration type',
                    de: 'Verwaltungsart',
                  },
                  weg: {
                    en: 'Home Owners Association (HOA)',
                    de: 'Wohnungseigentumsverwaltung (WEG)',
                  },
                  mv: {
                    en: 'Tenant Management (TM)',
                    de: 'Mietverwaltung (MV)',
                  },
                  sev: {
                    en: 'SEV with WEG connection',
                    de: 'SEV mit WEG-Verbindung',
                  },
                  sev_mv: {
                    en: 'SEV without WEG connection',
                    de: 'SEV ohne WEG-Verbindung',
                  },
                },
                clientCategory: {
                  en: 'Client category',
                  de: 'Kundenkategorie',
                },
                propertyManagementCompany: {
                  en: 'Property management company',
                  de: 'Verwaltungunternehmen',
                },
                administrator: {
                  en: 'Administrator',
                  de: 'Objektbetreuer',
                },
                accountant: {
                  en: 'Accountant',
                  de: 'Objektbuchhalter',
                },
              },
            },
            subsection5: {
              title: {
                en: 'Administrator Contract',
                de: 'Verwaltervertrag',
              },
              fields: {
                contractId: {
                  en: 'Contract ID',
                  de: 'Vertrags-ID',
                },
                managerDecisionDate: {
                  en: 'Manager order decision date',
                  de: 'Beschlussfassungsdatum der Verwalterbestellung',
                },
                contractStart: {
                  en: 'Contract start',
                  de: 'Vertragsbeginn',
                },
                contractEnd: {
                  en: 'Contract end',
                  de: 'Vertragsende',
                },
                yearlyTerminationDate: {
                  en: 'Yearly termination date',
                  de: 'Sonderkündigungsrecht jährlich zum',
                },
                specialTerminationDate: {
                  en: 'Special termination date',
                  de: 'Sonderkündigungsrecht einmalig zum',
                },
                managementContractUrl: {
                  descriptionLabel: {
                    en: 'Administration contract upload',
                    de: 'Verwaltvertrag Upload',
                  },
                  buttonLabel: {
                    en: 'Choose file',
                    de: 'Dateien auswählen',
                  },
                },
              },
            },
            subsection6: {
              title: {
                en: 'Property management fees',
                de: 'Verwalterhonorare',
              },
              fields: {
                feeType: {
                  title: {
                    en: 'Fee type',
                    de: 'Honorarart',
                  },
                  flat: {
                    en: 'Flat fee',
                    de: 'Pauschalhonorar',
                  },
                  perUnit: {
                    en: 'Fee per unit',
                    de: 'Honorar je Einheit',
                  },
                },
                yearlyFeeAdaptation: {
                  title: {
                    en: 'Yearly fee adaptation',
                    de: 'Art der jährlichen Anpassung',
                  },
                  none: {
                    en: 'None',
                    de: 'Keine',
                  },
                  fixedIndexing: {
                    en: 'Fixed indexing',
                    de: 'Fixe Indexierung - % Erhöhung p.a.',
                  },
                  realIndexing: {
                    en: 'Real indexing',
                    de: 'Echte Indexierung',
                  },
                  feeScales: {
                    en: 'Fee scales',
                    de: 'Honorarstaffeln',
                  },
                },
                flatFeeNet: {
                  en: 'Net fee',
                  de: 'Honorar p.M. (netto)',
                },
                flatFeeGross: {
                  en: 'Gross fee',
                  de: 'Honorar p.M. (inkl. MwSt. 19%)',
                },
                feeIncreasePercentage: {
                  en: 'Percentage increase',
                  de: '% Erhöhung p.a.',
                },
                feeIncreaseDate: {
                  en: 'First at',
                  de: 'Erstmals zum',
                },
                indexBasis: {
                  title: {
                    en: 'Index basis',
                    de: 'Echte Indexierung – Indexbasis',
                  },
                  consumerPriceIndex: {
                    en: 'Consumer price index',
                    de: 'Verbraucherpreisindex',
                  },
                  collectiveAgreement: {
                    en: 'Collective agreement',
                    de: 'Tarifvertrag der Wohnungswirtschaft',
                  },
                },
                applicationBasis: {
                  title: {
                    en: 'Application basis',
                    de: 'Echte Indexierung – Anwendungsbasis',
                  },
                  retroactive: {
                    en: 'Retroactive',
                    de: 'Rückwirkend',
                  },
                  foresighted: {
                    en: 'Foresighted',
                    de: 'Voraussschauend',
                  },
                },
                feeIncrease: {
                  en: 'Fee scales - price increase ',
                  de: 'Honorarstaffel — Preiserhöhung ',
                },
                feeIncreaseNumber: {
                  en: 'Price increase ',
                  de: 'Preiserhöhung ',
                },
                apartment: {
                  en: 'Apartments',
                  de: 'Wohneinheiten',
                },
                apartmentFeeScales: {
                  en: 'Apartments - Fee scales',
                  de: 'Wohneinheiten - Honorarstaffeln',
                },
                commercial: {
                  en: 'Commercial units',
                  de: 'Gewerbeeinheiten',
                },
                commercialFeeScales: {
                  en: 'Commercial units - Fee scales',
                  de: 'Gewerbeeinheiten - Honorarstaffeln',
                },
                garage: {
                  en: 'Garages',
                  de: 'Garagen',
                },
                garageFeeScales: {
                  en: 'Garages - Fee scales',
                  de: 'Garagen - Honorarstaffeln',
                },
                undergroundGarage: {
                  en: 'Underground garage',
                  de: 'Tiefgaragenplatz',
                },
                undergroundGarageFeeScales: {
                  en: 'Underground garage - Fee scales',
                  de: 'Tiefgaragenplatz - Honorarstaffeln',
                },
                basement: {
                  en: 'Basement',
                  de: 'Kellerraum',
                },
                basementFeeScales: {
                  en: 'Basement - Fee scales',
                  de: 'Kellerraum - Honorarstaffeln',
                },
                partialOwnership: {
                  en: 'Partial ownership',
                  de: 'Teileigentum Sonstige',
                },
                partialOwnershipFeeScales: {
                  en: 'Partial ownership - Fee scales',
                  de: 'Teileigentum Sonstige - Honorarstaffeln',
                },
              },
            },
            subsection7: {
              title: {
                en: 'Special fees',
                de: 'Sonderhonorare',
              },
              fields: {
                specialOwnerAssemblyFlatFee: {
                  en: 'Special owner assembly flat fee',
                  de: 'Außerordentliche ETV (Pauschale) netto',
                },
                specialOwnerAssemblyHourlyFee: {
                  en: 'Special owner assembly hourly fee',
                  de: 'Außerordentliche ETV (Stundenlohn) netto',
                },
                dunningFeeNet: {
                  en: 'Dunning fee net',
                  de: 'Mahnung netto',
                },
                noPartDirectDebitFee: {
                  en: 'No participation direct debit procedure fee',
                  de: 'Nichtteilnahme Lastschriftverfahren netto',
                },
                alienationApprovalFee: {
                  en: 'Alienation approval fee',
                  de: 'Veräußerungszustimmung netto',
                },
                ownerChangeFee: {
                  en: 'Owner change fee',
                  de: 'Eigentümerwechsel netto',
                },
                wageAccountingFee: {
                  en: 'Wage accounting fee',
                  de: 'Lohnbuchhaltung netto',
                },
                socialAuditFee: {
                  en: 'Social audit fee',
                  de: 'Sozialversicherungsprüfung netto',
                },
                legalDefaultActionFee: {
                  en: 'Legal default action fee',
                  de: 'Gerichtliches Mahnverfahren netto',
                },
                certificate35aFee: {
                  en: '35a certificate fee',
                  de: 'Bescheinigung nach § 35a netto',
                },
                additionalBenefitsHourlyFee: {
                  en: 'Additional benefits hourly fee',
                  de: 'Mehrleistungen nach Stunden (Stundenlohn) netto',
                },
                directorHourlyFee: {
                  en: 'Director hourly fee',
                  de: 'Geschäftsführer (Stundenlohn) netto',
                },
                authorizedOfficerHourlyFee: {
                  en: 'Authorized officer hourly fee',
                  de: 'Prokurist (Stundenlohn) netto',
                },
                propertySupervisorHourlyFee: {
                  en: 'Propery supervisor hourly fee',
                  de: 'Objektbetreuer / Sachbearbeiter (Stundenlohn) netto',
                },
                assistantHourlyFee: {
                  en: 'Assistant hourly fee',
                  de: 'Azubi/Hilfskraft (Stundenlohn) netto',
                },
                maintenanceType: {
                  title: {
                    en: 'Maintenance type',
                    de: 'Instandhaltung / Instandsetzung',
                  },
                  volumePercentage: {
                    en: 'Volume percentage based',
                    de: 'Abrechnung in % Volumen',
                  },
                  hourBased: {
                    en: 'Hour based',
                    de: 'Abrechnung nach Stundenaufwand',
                  },
                },
                maintenanceVolume: {
                  en: 'Maintenance volume percentage',
                  de: 'Instandhaltung / Instandsetzung (% des Volumens)',
                },
                maintenanceSpecialFee: {
                  en: 'Special maintenence fee',
                  de: 'IS/IH: Mindestvolumen für Sonderhonorar netto',
                },
              },
            },
          },
          section2: {
            title: {
              en: 'Buildings',
              de: 'Gebäude',
            },
            subsection1: {
              title: {
                en: 'Building number',
                de: 'Gebäudeanzahl',
              },
              fields: {
                numberBuildings: {
                  en: 'Number of buildings',
                  de: 'Anzahl der Gebäude',
                },
              },
            },
          },
          section3: {
            title: {
              en: 'Building',
              de: 'Gebäude',
            },
            subsection1: {
              title: {
                en: 'Building identification - Building №',
                de: 'Gebäudeidentifikation – Gebäude №',
              },
              fields: {
                buildingAdministrator: {
                  en: 'Administrator (building)',
                  de: 'Objektbetreuer (Gebäude)',
                },
                buildingName: {
                  en: 'Building name',
                  de: 'Gebäudebezeichnung',
                },
                buildingHrId: {
                  en: 'Building-ID',
                  de: 'Gebäude-ID',
                },
                buildingType: {
                  title: {
                    en: 'Building type',
                    de: 'Gebäudeart',
                  },
                  apartmentBuilding: {
                    en: 'Apartment building',
                    de: 'Mehrfamilienhaus',
                  },
                  detachedHouse: {
                    en: 'Detached house',
                    de: 'Einfamilienhaus',
                  },
                  townHouse: {
                    en: 'Townhouse',
                    de: 'Reihenhaus',
                  },
                  undergroundGarage: {
                    en: 'Underground garage',
                    de: 'Tiefgarage',
                  },
                },
                monument: {
                  en: 'Monument protection',
                  de: 'Denkmalschutz',
                },
              },
            },
            subsection2: {
              title: {
                en: 'Building address - Building №',
                de: 'Gebäude-Anschrift – Gebäude №',
              },
              fields: {
                additionalAddress2: {
                  en: 'Building extra',
                  de: 'Gebäude-Zusatz',
                },
                additionalAddress1: {
                  en: 'Additional address',
                  de: 'Adresszusatz',
                },
                street: {
                  en: 'Street',
                  de: 'Straße',
                },
                number: {
                  en: 'Number',
                  de: 'Hausnummer',
                },
                postalCode: {
                  en: 'Postal code',
                  de: 'PLZ',
                },
                location: {
                  en: 'Location',
                  de: 'Ort',
                },
                country: {
                  title: {
                    en: 'Country',
                    de: 'Land',
                  },
                },
              },
            },
            subsection4: {
              title: {
                en: 'Building strucutre: equipment - Building №',
                de: 'Gebäudeaufbau: Ausstattung – Gebäude №',
              },
              fields: {
                floorsNr: {
                  en: 'Floors',
                  de: 'Etagen (inkl. EG)',
                },
                elevatorsNr: {
                  en: 'Elevators',
                  de: 'Aufzüge',
                },
                playgroundsNr: {
                  en: 'Playgrounds',
                  de: 'Spielplätze',
                },
                additionalArea: {
                  en: 'Additional area',
                  de: 'Nebenflächen in m²',
                },
                constructionYear: {
                  en: 'Construction year',
                  de: 'Baujahr Gebäude',
                },
                groundAreaGross: {
                  en: 'Ground area gross',
                  de: 'Grundfläche brutto in m² (nach DIN 277)',
                },
                groundAreaNet: {
                  en: 'Ground area net',
                  de: 'Grundfläche netto in m² (nach DIN 277)',
                },
              },
            },
            subsection5: {
              title: {
                en: 'Warranty - Building №',
                de: 'Gewährleistung - Gebäude №',
              },
              fields: {
                buildersGuarantee: {
                  en: 'Still ongoing builders guarantee?',
                  de: 'Noch laufende Bauträgergewährleistung?',
                },
                guaranteeDueDate: {
                  en: 'Builders guarantee expiry date',
                  de: 'Ablaufdatum Gewährleistung Bauträger',
                },
              },

            },
          },
          section4: {
            title: {
              en: 'Building №',
              de: 'Gebäude №',
            },
            maintenanceInterval: {
              monthly: {
                en: 'monthly',
                de: 'monatlich',
              },
              quarterly: {
                en: 'quarterly',
                de: 'quartalweise',
              },
              biannual: {
                en: 'biannual',
                de: 'halbjährlich',
              },
              annual: {
                en: 'annual',
                de: 'jährlich',
              },
              biennial: {
                en: 'biennnial',
                de: 'zweijährlich',
              },
            },
            heating: {
              title: {
                en: 'Heating',
                de: 'Heizung',
              },
              fields: {
                heatingType: {
                  title: {
                    en: 'Heating type',
                    de: 'Heizungsart',
                  },
                  options: {
                    gas: {
                      en: 'Gas',
                      de: 'Gas',
                    },
                    gasExternalTank: {
                      en: 'Gas (external tank)',
                      de: 'Gas (Aussentank)',
                    },
                    oil: {
                      en: 'Oil (external tank)',
                      de: 'Öl (Tank)',
                    },
                    districtHeating: {
                      en: 'District heating',
                      de: 'Fernwärme',
                    },
                    pellets: {
                      en: 'Pellets',
                      de: 'Pellets',
                    },
                    blockHeating: {
                      en: 'Block heating',
                      de: 'BHKW',
                    },
                    gasFloorHeating: {
                      en: 'Gas floor heating',
                      de: 'Gasetagenheizung',
                    },
                    oilFloorHeating: {
                      en: 'Oil floor heating',
                      de: 'Öletagenheizung',
                    },
                    groundHeat: {
                      en: 'Ground heat',
                      de: 'Grundwärme',
                    },
                    airHeat: {
                      en: 'Air heat',
                      de: 'Luftwärme',
                    },
                    heatPump: {
                      en: 'Heat pump',
                      de: 'Wärmepumpe',
                    },
                  },
                },
                manufacturer: {
                  en: 'Manufacturer',
                  de: 'Hersteller',
                },
                maintenanceCompany: {
                  en: 'Maintenance Company',
                  de: 'Wartungsfirma',
                },
                heatingTankType: {
                  title: {
                    en: 'Tank type',
                    de: 'Tankart',
                  },
                  underground: {
                    en: 'Underground',
                    de: 'Unterirdisch',
                  },
                  surface: {
                    en: 'Surface',
                    de: 'Oberirdisch',
                  },
                },
                heatingTankHeight: {
                  en: 'Tank height in cm',
                  de: 'Tankhöhe in cm',
                },
                heatingTankVolume: {
                  en: 'Tank volume in litre',
                  de: 'Tankvolumen in Litern',
                },
                manufacturingYear: {
                  en: 'Manufacturing year',
                  de: 'Baujahr Heizungsanlage',
                },
                heatingInstallationDate: {
                  en: 'Installation date of heating',
                  de: 'Einbaudatum Heizungsanlage',
                },
                heatingWarrantyExpiryDate: {
                  en: 'Due date of guarantee',
                  de: 'Ablaufdatum Gewährleistung',
                },
                heatingMaintenanceInterval: {
                  title: {
                    en: 'Maintenance interval',
                    de: 'Wartungsintervall',
                  },
                  monthly: {
                    en: 'monthly',
                    de: 'monatlich',
                  },
                  quarterly: {
                    en: 'quarterly',
                    de: 'quartalweise',
                  },
                  biannual: {
                    en: 'biannual',
                    de: 'halbjährlich',
                  },
                  annual: {
                    en: 'annual',
                    de: 'jährlich',
                  },
                  biennial: {
                    en: 'biennnial',
                    de: 'zweijährlich',
                  },
                },
                heatingMaintenanceContract: {
                  en: 'Maintenance contract heating',
                  de: 'Wartungsvertrag Heizung',
                },
              },
            },
            subsection1: {
              title: {
                en: 'Energy certificate',
                de: 'Energieausweis',
              },
              fields: {
                certificateType: {
                  title: {
                    en: 'Energy certificate type',
                    de: 'Energieausweistyp',
                  },
                  noInformation: {
                    en: 'No information',
                    de: 'Keine Angaben',
                  },
                  energyDemand: {
                    en: 'Energy demand certificate',
                    de: 'Energiebedarfsausweis',
                  },
                  energyConsumption: {
                    en: 'Energy consumption certificate',
                    de: 'Energieverbrauchsausweis',
                  },
                },
                certificateCompany: {
                  en: 'Issuing company',
                  de: 'Ausstellende Firma',
                },
                issueDate: {
                  en: 'Issue date',
                  de: 'Ausstellungsdatum',
                },
                expiry: {
                  en: 'Expiry',
                  de: 'Gültig bis',
                },
                heatingArea: {
                  en: 'Heating area',
                  de: 'Heizfläche in m²',
                },
                energyConsumption: {
                  en: 'Energy consumption',
                  de: 'Endenergiebedarf in kWh',
                },
                totalEnergyConsumption: {
                  en: 'Total energy consumption',
                  de: 'Primärenergiebedarf in kWh',
                },
                energyConsumptionCategory: {
                  en: 'Energy consumption category',
                  de: 'Vergleichswert Endenergie',
                },
              },
            },
            airConditioning: {
              title: {
                en: 'Air conditioning',
                de: 'Klimaanlage',
              },
              fields: {
                manufacturer: {
                  en: 'Manufacturer',
                  de: 'Hersteller',
                },
                maintenanceCompany: {
                  en: 'Maintenance company',
                  de: 'Wartungsfirma',
                },
                maintenanceInterval: {
                  en: 'Maintenance interval',
                  de: 'Wartungsintervall',
                },
                maintenanceContract: {
                  en: 'Air conditioning maintenance contract',
                  de: 'Wartungsvertrag Klimaanlage',
                },
              },
            },
            subsection2: {
              title: {
                en: 'Central locking system',
                de: 'Zentrale Schließanlage',
              },
              fields: {
                centralLock: {
                  en: 'Locking system',
                  de: 'Schließanlage',
                },
                lockManufacturer: {
                  en: 'Locking system manufacturer',
                  de: 'Schließanlagenhersteller',
                },
                lockNumber: {
                  en: 'Locking system number',
                  de: 'Schließanlagennummer',
                },
                lockKeyDuty: {
                  en: 'Key service',
                  de: 'Schlüsseldienst',
                },
                lockCardStorage: {
                  title: {
                    en: 'Security card storage',
                    de: 'Aufbewahrungsort Sicherungskarte',
                  },
                  building: {
                    en: 'At key service of the building',
                    de: 'Beim Schlüsseldienst des Gebäudes',
                  },
                  house: {
                    en: 'Inside own house',
                    de: 'Intern im eigenen Haus',
                  },
                },
              },
            },
            subsection3: {
              title: {
                en: 'Legionnaires\' probing',
                de: 'Legionellenbeprobung',
              },
              fields: {
                legionnaireExamCompany: {
                  en: 'Examination company',
                  de: 'Prüffirma',
                },
                legionnaireExamInstitute: {
                  en: 'Examination institute',
                  de: 'Prüfinstitut',
                },
                legionnaireExamDate: {
                  en: 'Last probing',
                  de: 'Letzte Beprobung',
                },
                legionnaireFound: {
                  title: {
                    en: 'Legionnaires\' findings',
                    de: 'Legionellenbefund',
                  },
                  positive: {
                    en: 'positive',
                    de: 'positiv',
                  },
                  negative: {
                    en: 'negative',
                    de: 'negativ',
                  },
                },
                legionnaireFoundDate: {
                  en: 'Legionnaire\' findings date',
                  de: 'Datum Legionellenbericht',
                },
                legionnaireExamInterval: {
                  title: {
                    en: 'Examination interval',
                    de: 'Prüfintervall',
                  },
                  placeholder: {
                    en: 'will be completed automatically',
                    de: 'wird automatisch ausgefüllt',
                  },
                  yearly: {
                    en: 'Yearly',
                    de: 'Jährlich',
                  },
                  every3Years: {
                    en: 'Every 3 years',
                    de: 'Alle 3 Jahre',
                  },
                },
                legionnaireHazardAnalysis: {
                  en: 'Hazard analysis',
                  de: 'Gefährdungsanalyse',
                },
                legionnaireLastReport: {
                  en: 'Last report',
                  de: 'Letzter Prüfbericht',
                },
                legionnaireCompanyContract: {
                  en: 'Contract examination company',
                  de: 'Vertrag Prüfinstitut',
                },
              },
            },
            fireExtinguisher: {
              title: {
                en: 'Fire Extinguisher',
                de: 'Feuerlöscher',
              },
              fields: {
                maintenanceCompany: {
                  en: 'Maintenance company',
                  de: 'Wartungsfirma',
                },
                maintenanceInterval: {
                  en: 'Maintenance interval',
                  de: 'Wartungsintervall',
                },
                maintenanceContract: {
                  en: 'Maintenance contract Fire extinguisher',
                  de: 'Wartungsvertrag Feuerlöscher',
                },
              },
            },
            waterTreatment: {
              title: {
                en: 'Water treatment',
                de: 'Wasseraufbereitung',
              },
              fields: {
                manufacturer: {
                  en: 'Manufacturer',
                  de: 'Hersteller',
                },
                maintenanceCompany: {
                  en: 'Maintenance company',
                  de: 'Wartungsfirma',
                },
                maintenanceInterval: {
                  en: 'Maintenance interval',
                  de: 'Wartungsintervall',
                },
                maintenanceContract: {
                  en: 'Maintenance contract Water treatment',
                  de: 'Wartungsvertrag Wasseraufbereitung',
                },
              },
            },
            fumeExtraction: {
              title: {
                en: 'Fume extraction system',
                de: 'Rauchabzugsanlage',
              },
              fields: {
                manufacturer: {
                  en: 'Manufacturer',
                  de: 'Hersteller',
                },
                maintenanceCompany: {
                  en: 'Maintenance company',
                  de: 'Wartungsfirma',
                },
                maintenanceInterval: {
                  en: 'Maintenance interval',
                  de: 'Wartungsintervall',
                },
                maintenanceContract: {
                  en: 'Maintenance contract fume extraction system',
                  de: 'Wartungsvertrag Rauchabzugsanlage',
                },
              },
            },
            ventilation: {
              title: {
                en: 'Ventilation system',
                de: 'Lüftungsanlage',
              },
              fields: {
                manufacturer: {
                  en: 'Manufacturer',
                  de: 'Hersteller',
                },
                maintenanceCompany: {
                  en: 'Maintenance company',
                  de: 'Wartungsfirma',
                },
                maintenanceInterval: {
                  en: 'Maintenance interval',
                  de: 'Wartungsintervall',
                },
                maintenanceContract: {
                  en: 'Maintenance contract Ventilation system',
                  de: 'Wartungsvertrag Lüftungsanlage',
                },
              },
            },
            garageDoor: {
              title: {
                en: 'Garage Door',
                de: 'TG-Tor',
              },
              fields: {
                manufacturer: {
                  en: 'Manufacturer',
                  de: 'Hersteller',
                },
                maintenanceCompany: {
                  en: 'Maintenance company',
                  de: 'Wartungsfirma',
                },
                maintenanceInterval: {
                  en: 'Maintenance interval',
                  de: 'Wartungsintervall',
                },
                maintenanceContract: {
                  en: 'Maintenance contract Garage Door',
                  de: 'Wartungsvertrag TG-Tor',
                },
              },
            },
            meter: {
              title: {
                en: 'Counter',
                de: 'Zähler',
              },
              fields: {
                counterNumber: {
                  en: 'Counter-Nr. (Main counter ',
                  de: 'Zähler-Nr. (Hauptzähler ',
                },
                name: {
                  en: 'Counter-Name (Main counter ',
                  de: 'Zähler-Bezeichnung (Hauptzähler ',
                },
                addButton: {
                  en: 'Add counter',
                  de: 'Zähler hinzufügen',
                },
                meterType: {
                  label: {
                    en: 'Counter type (Main counter ',
                    de: 'Zählerart (Hauptzähler ',
                  },
                  energy: {
                    en: 'Energy',
                    de: 'Strom',
                  },
                  water: {
                    en: 'Water',
                    de: 'Wasser',
                  },
                  gas: {
                    en: 'Gas',
                    de: 'Gas',
                  },
                },
                gridOperator: {
                  en: 'Network operator/Owner (Main counter ',
                  de: 'Netzbetreiber/Eigentümer (Hauptzähler ',
                },
              },
            },
            subsection4: {
              title: {
                en: 'Smoke detector',
                de: 'Rauchwarnmelder',
              },
              fields: {
                detectorResponsibility: {
                  title: {
                    en: 'Responsibility',
                    de: 'Zuständigkeit',
                  },
                  community: {
                    en: 'Property',
                    de: 'Objekt',
                  },
                  individual: {
                    en: 'Individually',
                    de: 'Einzelverantwortung',
                  },
                },
                detectorMaintenanceCompany: {
                  en: 'Maintenance company',
                  de: 'Wartungsfirma',
                },
                detectorMaintenanceInterval: {
                  en: 'Maintenance interval',
                  de: 'Wartungsintervall',
                },
                detectorMaintenanceContract: {
                  en: 'Maintenance contract smoke detector',
                  de: 'Wartungsvertrag Rauchwarnmelder',
                },
              },
            },
            subsection5: {
              title: {
                en: 'Elevator №',
                de: 'Aufzug №',
              },
              fields: {
                hrId: {
                  en: 'Elevator-ID',
                  de: 'Aufzugs-ID',
                },
                name: {
                  en: 'Elevator name',
                  de: 'Aufzugsbezeichnung',
                },
                manufacturer: {
                  en: 'Manufacturer',
                  de: 'Hersteller',
                },
                maintenanceCompany: {
                  en: 'Maintenance company',
                  de: 'Wartungsfirma',
                },
                maintenanceInterval: {
                  en: 'Maintenance interval',
                  de: 'Wartungsintervall',
                },
                contractPeriod: {
                  en: 'Contract period (from – to)',
                  de: 'Vertragslaufzeit (von – bis)',
                },
                lastExamination: {
                  en: 'Last examination (TÜV)',
                  de: 'Letzte Hauptprüfung (TÜV)',
                },
                maintenanceContract: {
                  en: 'Maintenance company contract',
                  de: 'Wartungsvertrag Aufzug',
                },
              },
            },
            subsection6: {
              title: {
                en: 'Service providers',
                de: 'Dienstleister',
              },
              serviceProviderType: {
                none: {
                  en: 'None',
                  de: 'Keine',
                },
                sameAsCaretaker: {
                  en: 'Same as caretaker',
                  de: 'Wie Hausmeister',
                },
                marginalEmployment: {
                  en: 'Marginal employment',
                  de: 'Lohn geringfügig',
                },
                employment: {
                  en: 'Employment',
                  de: 'Lohn sozialversicherungspflichtig',
                },
                external: {
                  en: 'External',
                  de: 'Gewerblich',
                },
                resident: {
                  en: 'Self organised residents',
                  de: 'Bewohner in Eigenorganisation',
                },
              },
              externalProviderFields: {
                monthlyInvoiceNet: {
                  en: 'Monthly net sum',
                  de: 'Rechnungsbetrag pro Monat netto',
                },
                monthlyInvoiceGross: {
                  en: 'Monthly gross sum',
                  de: 'Rechnungsbetrag pro Monat brutto',
                },
                invoiceTax: {
                  title: {
                    en: 'VAT in the invoice',
                    de: 'MwSt-Ausweis in der Rechnung',
                  },
                  yes: {
                    en: 'With VAT',
                    de: 'Mit MwSt.',
                  },
                  no: {
                    en: 'Without VAT',
                    de: 'Ohne MwSt.',
                  },
                },
              },
              fields: {
                caretakerType: {
                  title: {
                    en: 'Type of caretaker',
                    de: 'Art des Hausmeisters',
                  },
                  none: {
                    en: 'None',
                    de: 'Keiner',
                  },
                  marginalEmployment: {
                    en: 'Marginal employment',
                    de: 'Lohn geringfügig',
                  },
                  employment: {
                    en: 'Employment',
                    de: 'Lohn sozialversicherungspflichtig',
                  },
                  external: {
                    en: 'External',
                    de: 'Gewerblich',
                  },
                },
                caretaker: {
                  en: 'Caretaker',
                  de: 'Hausmeister (Firma, Person)',
                },
                caretakerPerson: {
                  en: 'Add new person',
                  de: 'Neue Person Hinzufügen',
                },
                caretakerCompany: {
                  en: 'Add new company',
                  de: 'Neue Firma Hinzufügen',
                },
                cleaningType: {
                  en: 'Type of cleaning',
                  de: 'Art der Gebäudereinigung',
                },
                cleaning: {
                  en: 'Cleaning',
                  de: 'Gebäudereiniger (Firma, Person)',
                },
                outdoorType: {
                  en: 'Type of outdoor care',
                  de: 'Art der Aussenanlagenbetreuung',
                },
                outdoor: {
                  en: 'Outdoor service',
                  de: 'Aussenanlagenbetreuung  (Firma, Person)',
                },
                winterType: {
                  en: 'Type of winter service',
                  de: 'Art des Winterdienstes',
                },
                winter: {
                  en: 'Winter service',
                  de: 'Winterdienst  (Firma, Person)',
                },
                residualWaste: {
                  en: 'Residual waste service',
                  de: 'Restmüll - Dienstleister',
                },
                bioWaste: {
                  en: 'Bio waste service',
                  de: 'Biomüll - Dienstleister',
                },
                yellowBin: {
                  en: 'Yellow bin service',
                  de: 'Gelbe Tonne -  Dienstleister',
                },
                paperWaste: {
                  en: 'Paper waste service',
                  de: 'Papiermüll - Dienstleister',
                },
                caretakerContract: {
                  en: 'Caretaker contract',
                  de: 'Hausmeister Vertrag',
                },
                cleaningContract: {
                  en: 'Cleaning contract',
                  de: 'Gebäudereinigung',
                },
                outdoorContract: {
                  en: 'Outdoor service contract',
                  de: 'Aussenanlage',
                },
                winterContract: {
                  en: 'Winter service contract',
                  de: 'Winterdienst',
                },
              },
            },
            tv: {
              title: {
                en: 'TV',
                de: 'TV',
              },
              fields: {
                tvViaWeg: {
                  en: 'TV via WEG',
                  de: 'TV läuft über die Gemeinschaft',
                },
                supplierType: {
                  en: 'TV supplier type',
                  de: 'TV Versorgungsart',
                },
                types: {
                  cable: {
                    en: 'CABLE',
                    de: 'KABEL',
                  },
                  satellite: {
                    en: 'SATELLITE',
                    de: 'SATELITENSCHÜSSELN',
                  },
                  antenna: {
                    en: 'COMMUNITY_ANTENNA',
                    de: 'GEMEINSCHAFTSANTENNE',
                  },
                  dvbt: {
                    en: 'DVBT',
                    de: 'DVBT',
                  },
                },
                supplier: {
                  en: 'Supplier',
                  de: 'Anbieter',
                },
                contractDates: {
                  en: 'Contract(start-til)',
                  de: 'Vertraglaufszeit (von - bis)',
                },
                cancellation: {
                  en: 'Cancellation period in months',
                  de: 'Kündigungsfrist in Monaten',
                },
                cancellationDate: {
                  en: 'Due date cancellation',
                  de: 'Ablauf Kündigungstermin',
                },
                ports: {
                  en: 'Number of ports',
                  de: 'Anzahl Anschlüsse',
                },
                tvInternet: {
                  en: 'Cable: building network capable of internet network',
                  de: 'Kabel: Hausnetz Internetfähig',
                },
                tvContract: {
                  en: 'Cable TV Contract',
                  de: 'Kabel TV Vertrag',
                },
              },
            },
            internet: {
              title: {
                en: 'Internet',
                de: 'Internet',
              },
              fields: {
                supplierType: {
                  en: 'Internet supplier type',
                  de: 'Internet Versorgungsart',
                },
                types: {
                  cable: {
                    en: 'CABLE',
                    de: 'KABEL',
                  },
                  opticalFiber: {
                    en: 'OPTICAL_FIBER',
                    de: 'GLASFASER',
                  },
                  telekom: {
                    en: 'TELEKOM',
                    de: 'TELEKOM',
                  },
                },
                supplier: {
                  en: 'Supplier',
                  de: 'Anbieter',
                },
                contractDates: {
                  en: 'Contract(start-til)',
                  de: 'Vertraglaufszeit(von-bis)',
                },
                cancellation: {
                  en: 'Cancellation period in months',
                  de: 'Kündigungsfrist in Monaten',
                },
                cancellationDate: {
                  en: 'Due date cancellation',
                  de: 'Ablauf Kündigungstermin',
                },
                ports: {
                  en: 'Number of ports',
                  de: 'Anzahl Anschlüsse',
                },
                internetContract: {
                  en: 'Internet contract',
                  de: 'Internet Vertrag',
                },
              },
            },
          },
          section5: {
            title: {
              en: 'Owners',
              de: 'Eigentümer',
            },
            ownerNumber: {
              en: 'Owner №',
              de: 'Eigentümer №',
            },
            ownerValidationMessage: {
              en: 'Missing or invalid fields',
              de: 'Fehlenden oder nicht validen Werten',
            },
            addButton: {
              en: 'Add owner',
              de: 'Eigentümer hinzufügen',
            },
            subsection1: {
              title: {
                en: 'Owner data ',
                de: 'Eigentümerdaten ',
              },
              fields: {
                owner: {
                  en: 'Owner',
                  de: 'Eigentümer',
                },
                bankAccount: {
                  en: 'Bank account',
                  de: 'Bankkonto',
                },
                notifications: {
                  title: {
                    en: 'Notification preferences',
                    de: 'Versandpräferenzen',
                  },
                  email: {
                    en: 'E-Mail',
                    de: 'E-Mail',
                  },
                  portal: {
                    en: 'Portal',
                    de: 'Portal',
                  },
                  postal: {
                    en: 'Postal',
                    de: 'Postalisch',
                  },
                },
              },
            },
            subsection2: {
              title: {
                en: 'Bankconnection',
                de: 'Bankverbindung',
              },
              bankAccountOwner: {
                en: 'Bank account owner',
                de: 'Inhaber',
              },
              iban: {
                en: 'IBAN',
                de: 'IBAN',
              },
              bic: {
                en: 'BIC (for foreign accounts)',
                de: 'BIC (Bei Auslandskonten)',
              },
            },
            subsection3: {
              title: {
                en: 'Direct debit mandate №',
                de: 'Lastschriftmandat №',
              },
              fields: {
                directDebitActive: {
                  en: 'Direct debit mandate active',
                  de: 'Lastschrifteinzug aktiveren',
                },
                directDebitId: {
                  en: 'Direct debit mandate ID',
                  de: 'Mandatsreferenznummer',
                },
                directDebitIdPlaceholder: {
                  en: 'System generated if left empty',
                  de: 'Freilassen damit System eine Mandatsreferenz erstellt',
                },
                directDebitValidFrom: {
                  en: 'Valid from',
                  de: 'Gültig ab',
                },
                directDebitSignedOn: {
                  en: 'Signed on',
                  de: 'Unterzeichnet am',
                },
                addDirectDebit: {
                  en: 'Add direct debit',
                  de: 'Neues Lastschriftmandat hinzufügen',
                },
                noAccount: {
                  en: 'Make sure owner is selected and has bank accounts',
                  de: 'Zuerst Eigentümer mit Bankkonten wählen',
                },
                directDebitEnabled: {
                  en: 'Direct debit mandate will be enabled starting from next month.',
                  de: 'Das Lastschriftmandat wird ab nächsten Monat aktiv sein.',
                },
                directDebitDisabled: {
                  en: 'Direct debit mandate will be disabled starting from next month.',
                  de: 'Das Lastschriftmandat wird ab nächsten Monat inaktiv sein.',
                },
              },
            },
            subsection4: {
              title: {
                en: 'Data protection',
                de: 'Datenschutz',
              },
              fields: {
                dataProtectionDeclaration: {
                  en: 'Data protection declaration',
                  de: 'Datenschutz Einwillungserklärung',
                },
                council: {
                  en: 'Council',
                  de: 'Beirat',
                },
                notificationDeclarationUrl: {
                  en: 'Notification declaration',
                  de: 'Erklärung Versandpräferenzen',
                },
                directDebitDeclarationUrl: {
                  en: 'Direct debit declaration',
                  de: 'Lastschriftmandat',
                },
                dataProtectionDeclarationUrl: {
                  en: 'Data protection declaration',
                  de: 'Einwilligungserklärung Datenschutz',
                },
              },
            },
            subsection5: {
              title: {
                en: 'Council',
                de: 'Beirat',
              },
              addButton: {
                en: 'Add council member',
                de: 'Verwaltungsbeirat hinzufügen',
              },
              fields: {
                councilPresident: {
                  en: 'Council president',
                  de: 'Verwaltungsbeirat Vorsitz',
                },
                councilMember: {
                  en: 'Council member №',
                  de: 'Verwaltungsbeirat №',
                },
              },
            },
          },
          section6: {
            title: {
              en: 'Units',
              de: 'Einheiten',
            },
            building: {
              en: 'Building',
              de: 'Gebäude',
            },
            owner: {
              en: 'Owner',
              de: 'Eigentümer',
            },
            download: {
              en: 'Unit list',
              de: 'Einheitenliste',
            },
            unitValidationMessage: {
              en: 'Missing or invalid fields',
              de: 'Fehlenden oder nicht validen Werten',
            },
            validationMessage: {
              en: 'There are units with invalid fields.',
              de: 'Es gibt Einheiten mit nicht validen Werten.',
            },
            validationMessageOwners: {
              en: 'There are owners with invalid fields.',
              de: 'Es gibt Eigentümer mit nicht validen Werten.',
            },
            unitsListLoadingMessage: {
              en: 'Update units list in progress.',
              de: 'Die Aktualisierung der Einheitenliste ist in Bearbeitung',
            },
            subsection: {
              typeTitles: {
                apartment: {
                  en: 'Apartment',
                  de: 'Wohnung',
                },
                other: {
                  en: 'Other',
                  de: 'Sonstiges',
                },
                parking: {
                  en: 'Parking',
                  de: 'Parkmöglichkeit',
                },
                commercial: {
                  en: 'Commercial',
                  de: 'Gewerbeeinheit',
                },
              },
              ownerListNotEmpty: {
                en: 'Owners list should not be empty',
                de: 'Die Eigentümerliste sollte nicht leer sein',
              },
              fields: {
                unitNrSharingDeclaration: {
                  en: 'Unit Nr sharing declaration',
                  de: 'Einheiten-Nr. lt. Teilungserklärung',
                },
                buildingNumber: {
                  en: 'Building assignment',
                  de: 'Gebäudezuordung',
                },
                noBuilding: {
                  en: 'No building',
                  de: 'Kein Gebäude',
                },
                type: {
                  en: 'Type',
                  de: 'Art',
                },
                typeOfBusiness: {
                  en: 'Type of business',
                  de: 'Art des Gewerbes',
                },
                floor: {
                  en: 'Floor',
                  de: 'Stockwerk',
                },
                position: {
                  title: {
                    en: 'Position',
                    de: 'Lage',
                  },
                },
                unitMetrics: {
                  en: 'Unit metrics',
                  de: 'Einheitskennzahlen',
                },
                livingArea: {
                  en: 'Area (m²)',
                  de: 'Fläche (m²)',
                },
                commercialArea: {
                  en: 'Commercial area (m²)',
                  de: 'Gewerbefläche (m²)',
                },
                heatingArea: {
                  en: 'Heating area (m²)',
                  de: 'Heizfläche (m²)',
                },
                roomsNr: {
                  en: 'Rooms number',
                  de: 'Anzahl Zimmer',
                },
                customerId: {
                  en: 'Customer ID',
                  de: 'Kundennummer',
                },
                economicData: {
                  en: 'Economic data',
                  de: 'Wirtschaftsdaten',
                },
                reserveFundAmounts: {
                  en: 'Reserve fund amounts',
                  de: 'Rücklagenbeträge',
                },
                accountName: {
                  en: 'Account name',
                  de: 'Kontobezeichnung',
                },
                amount: {
                  en: 'Amount',
                  de: 'Betrag',
                },
                propertyShare: {
                  en: 'Property share',
                  de: 'Miteigentumsanteil (MEA)',
                },
                persons: {
                  en: 'People',
                  de: 'Personen',
                },
                houseMoneyAndReservesCopy: {
                  label: {
                    en: 'Create house money & reserves',
                    de: 'Hausgeld & Rücklagen anlegen',
                  },
                  options: {
                    preAdministratorValues: {
                      en: 'Enter pre-administrator values',
                      de: 'Vorverwalterwerte eintragen',
                    },
                  },
                },
                monthlyTotalMoney: {
                  en: 'Total monthly money',
                  de: 'Monatliches Gesamthausgeld',
                },
                monthlyHouseMoney: {
                  en: 'Monthly House money',
                  de: 'Monatliches Hausgeld',
                },
                monthlyReserveMoney: {
                  en: 'Monthly Reserve money',
                  de: 'Monatliche Rücklagenzuführung',
                },
                tenantData: {
                  en: 'Tenant data',
                  de: 'Mieterdaten',
                },
                tenantAdd: {
                  en: 'Add',
                  de: 'Hinzufügen',
                },
                tenantAbort: {
                  en: 'Abort',
                  de: 'Abbrechen',
                },
                tenant: {
                  en: 'Tenant',
                  de: 'Mieter',
                },
                tenantLeaseStartDate: {
                  en: 'Lease start date',
                  de: 'Einzugsdatum',
                },
                tenantLeaseEndDate: {
                  en: 'Lease end date',
                  de: 'Auszugsdatum',
                },
                owner: {
                  en: 'Owner',
                  de: 'Eigentümer',
                },
                addOwner: {
                  en: 'Add another owner',
                  de: 'Weiteren Eigentümer hinzufügen',
                },
                changeOwner: {
                  en: 'Change owner on this unit',
                  de: 'Eigentümerwechsel vornehmen',
                },
                ownerChangePlanned: {
                  en: 'Planned owner change on ',
                  de: 'Geplanter Eigentümerwechsel am ',
                },
                lastOwnerChangeDate: {
                  en: 'Last owner change date: ',
                  de: 'Datum des letzten Eigentümerwechsel: ',
                },
                selectBankAccount: {
                  en: 'Select bank account',
                  de: 'Bankkonto auswählen',
                },
              },
            },
          },
          serviceProviderSection: {
            title: {
              en: 'Service providers',
              de: 'Dienstleister',
            },
            services: {
              title: {
                en: 'Object services - Building №',
                de: 'Objektdienste Gebäude №',
              },
            },
            care: {
              title: {
                en: 'Care - Building №',
                de: 'Versorgung - Gebäude №',
              },
              fields: {
                coldWaterRented: {
                  en: 'Cold water counter rented',
                  de: 'Kaltwasserzähler gemietet',
                },
                company: {
                  en: 'Add company',
                  de: 'Firma eingeben',
                },
                warmWaterRented: {
                  en: 'Warm water counter rented',
                  de: 'Warmwasserzähler gemietet',
                },
                hkvRented: {
                  en: 'HKV rented',
                  de: 'HKV gemietet',
                },
                coldWaterReadingCompany: {
                  en: 'Cold water reading company',
                  de: 'Ablesedienst Kaltwasserzähler',
                },
                warmWaterReadingCompany: {
                  en: 'Warm water reading company',
                  de: 'Ablesedienst Warmwasserzähler',
                },
                hkvReadingCompany: {
                  en: 'HKV reading company',
                  de: 'Ablesedienst HKV',
                },
                heatingCostCalculator: {
                  en: 'Heating cost calculator',
                  de: 'Wärmekostenabrechner',
                },
                energyCompany: {
                  en: 'Energy supplier company',
                  de: 'Energie - Versorger',
                },
                waterCompany: {
                  en: 'Water supplier company',
                  de: 'Wasser - Versorger',
                },
                electricityCompany: {
                  en: 'Electricity supplier company',
                  de: 'Strom - Versorger',
                },
              },
            },
            insurance: {
              title: {
                en: 'Insurances',
                de: 'Versicherungen',
              },
              risk: {
                en: 'Risk coverage of building insurance',
                de: 'Risikendeckung über die Wohngebäude-Versicherung',
              },
              weg: {
                en: 'WEG insurances',
                de: 'WEG Versicherungen',
              },
              building: {
                en: 'Building insurance - building №',
                de: 'Wohngebäudeversicherung - Gebäude №',
              },
              buildingInsurance: {
                en: 'Building insurance',
                de: 'Wohngebäudeversicherung',
              },
              board: {
                en: 'Board insurance',
                de: 'Beiratsversicherung',
              },
              groundOwner: {
                en: 'House and Groundowner insurance',
                de: 'Haus- und Grundbesitzer Haftpflicht',
              },
              fire: {
                en: 'Fire insurance',
                de: 'Feuer',
              },
              storm: {
                en: 'Storm insurance',
                de: 'Sturm',
              },
              water: {
                en: 'Water insurance',
                de: 'Gewässerschadenhaftpflicht',
              },
              elementary: {
                en: 'Elementary',
                de: 'Elementar',
              },
              fields: {
                identical: {
                  en: 'Insurances identical for all buildings',
                  de: 'Versicherungen identisch für alle Gebäude',
                },
                another: {
                  en: 'Another insurance contract',
                  de: 'Separate Versicherungspolice',
                },
                contractNr: {
                  en: 'Contract number',
                  de: 'Policennummer',
                },
                company: {
                  en: 'Insurance company',
                  de: 'Versicherungsgesellschaft',
                },
                representative: {
                  en: 'Insurance dealer / contact person',
                  de: 'Versicherungsmakler / - Vertreter',
                },
                dueAmount: {
                  en: 'Due date',
                  de: 'Hauptfälligkeit',
                },
                paymentInterval: {
                  en: 'Payment interval',
                  de: 'Zahlungsrythmus',
                },
                intervalOptions: {
                  yearly: {
                    en: 'Yearly',
                    de: 'Jährlich',
                  },
                  quarterly: {
                    en: 'Quarterly',
                    de: 'Vierteljährlich',
                  },
                  monthly: {
                    en: 'Monthly',
                    de: 'Monatlich',
                  },
                },
                amount: {
                  en: 'Amount insured',
                  de: 'Deckungssumme',
                },
                contractDates: {
                  en: 'Contract length (from - til)',
                  de: 'Laufzeit (von - bis)',
                },
                closingDate: {
                  en: 'Signage date',
                  de: 'Abschlussdatum',
                },
                deductible: {
                  en: 'Deductible',
                  de: 'Selbstbehalt',
                },
              },
            },
          },
          costCenterSection: {
            title: {
              en: 'Cost centers',
              de: 'Sachkonten',
            },
            subsection1: {
              title: {
                en: 'Cost centers',
                de: 'Sachkonten',
              },
              fields: {
                economicDivision: {
                  en: 'Economic division of buildings',
                  de: 'Wirtschaftliche Trennung der Gebäude',
                },
                divisionTooltip: {
                  en: 'Every account gets a subaccount for each building.',
                  de: 'Jedes Sachkonto erhält für jedes Gebäude ein Unterkonto.',
                },
                divideSubtitle: {
                  en: 'Divide individual accounts',
                  de: 'Einzelne Sachkonten unterteilen',
                },
                accountSmartSearch: {
                  en: 'Account which should be divided',
                  de: 'Sachkonto das unterteilt werden soll',
                },
                addDivision: {
                  en: 'Add division',
                  de: 'Unterteilung hinzufügen',
                },
                newAccNumber: {
                  en: 'New account number',
                  de: 'Neue Sachkonten Nr',
                },
                newAccPlaceholder: {
                  en: 'Will be generated automatically',
                  de: 'Wird automatisch generiert',
                },
                newAccName: {
                  en: 'Name of account',
                  de: 'Bezeichnung Sachkonto',
                },
                addAccount: {
                  en: 'Add further account for division',
                  de: 'Sachkonto zur weiteren Unterteilung hinzufügen',
                },
              },
            },
            reserveAccounts: {
              title: {
                en: 'Reserve Accounts',
                de: 'Rücklagenkonten',
              },
              fields: {
                accountName: {
                  en: 'Account name',
                  de: 'Kontobezeichnung',
                },
                add: {
                  en: 'Add new reserve account',
                  de: 'Neues Rücklagenkonto hinzufügen',
                },
              },
            },
            rentAccounts: {
              en: 'Rent accounts',
              de: 'Mietkonten',
            },
          },
          section7: {
            title: {
              en: 'Property accounting',
              de: 'Objektbuchhaltung',
            },
            groupTitle1: {
              en: 'Economic data',
              de: 'Wirtschaftsdaten',
            },
            groupTitle2: {
              en: 'Bookings',
              de: 'Buchungen',
            },
            groupTitle3: {
              en: 'Payments',
              de: 'Zahlungsverkehr',
            },
            subsection1: {
              title: {
                en: 'Economic year',
                de: 'Wirtschaftsjahr',
              },
              fields: {
                economicYearStart: {
                  en: 'Economic year start',
                  de: 'Wirtschaftsjahr Anfang',
                },
                houseMoneyBuilding: {
                  en: 'Total house money for building ',
                  de: 'Hausgeld gesamt für Gebäude ',
                },
                reserveMoneyBuilding: {
                  en: 'Total reserve money for building ',
                  de: 'Rücklagenzuführung gesamt für Gebäude ',
                },
                houseMoneyProperty: {
                  en: 'Total house money for property',
                  de: 'Hausgeld WEG gesamt',
                },
                reserveMoneyProperty: {
                  en: 'Total reserve money for property',
                  de: 'Rücklagenzuführung WEG gesamt',
                },
              },
            },
            subsection2: {
              title: {
                en: 'Bank data',
                de: 'Bankdaten',
              },
              addAccount: {
                en: 'Add bank account',
                de: 'Bankverbindung hinzufügen',
              },
              fields: {
                name: {
                  en: 'Bank account №',
                  de: 'Bankkonto №',
                },
                creditor: {
                  en: 'Creditor ID',
                  de: 'Gläubiger-ID',
                },
                accountOwner: {
                  en: 'Account owner',
                  de: 'Kontoinhaber',
                },
                bankAccounts: {
                  en: 'Property bank accounts',
                  de: 'Bankkonten',
                },
                iban: {
                  en: 'IBAN',
                  de: 'IBAN',
                },
                bic: {
                  en: 'BIC',
                  de: 'BIC',
                },
                institute: {
                  en: 'Bank',
                  de: 'Bank',
                },
                accountName: {
                  en: 'Account name',
                  de: 'Kontenbezeichnung',
                },
                accountType: {
                  title: {
                    en: 'Account type',
                    de: 'Kontentyp',
                  },
                  HOUSE: {
                    en: 'Housemoney Bank account',
                    de: 'Hausgeldkonto für Wirtschaftspläne',
                  },
                  RESERVE: {
                    en: 'Bank account',
                    de: 'Bankkonto',
                  },
                  RENT: {
                    en: 'Rent money',
                    de: 'Mietenkonto',
                  },
                  DEPOSIT: {
                    en: 'Deposit money',
                    de: 'Kautionskonto',
                  },
                },
              },
            },
          },
          section8: {
            title: {
              en: 'Document Upload',
              de: 'Dokument Upload',
            },
            subsection1: {
              fields: {
                managementContract: {
                  en: 'Management Contract',
                  de: 'Verwalterdaten',
                },
                sharingDeclaration: {
                  en: 'Sharing declaration',
                  de: 'Teilungserklärung',
                },
                energyCertificate: {
                  en: 'Energy certificate',
                  de: 'Energieausweis',
                },
                ac: {
                  en: 'Air conditioning contract',
                  de: 'Wartungsvertrag Klimaanlage',
                },
                tv: {
                  en: 'Cable TV contract',
                  de: 'Kabel TV Vertrag',
                },
                internet: {
                  en: 'Internet contract',
                  de: 'Internet Vertrag',
                },
                legionnaireContract: {
                  en: 'Legionnaires\' company contract',
                  de: 'Vertrag Prüfinstitut',
                },
                legionnaireReport: {
                  en: 'Last legionnaires\' report',
                  de: 'Letzter Prüfbericht',
                },
                legionnaireHazardAnalysis: {
                  en: 'Legionnaires\' hazard analysis',
                  de: 'Gefährdungsanalyse',
                },
                smokeDetector: {
                  en: 'Smoke detector contract',
                  de: 'Wartungsvertrag',
                },
                fireExtinguisher: {
                  en: 'Fire Extinguisher contract',
                  de: 'Feuerlöscher',
                },
                waterTreatment: {
                  en: 'Watertreatment contract',
                  de: 'Wasseraufbereitung',
                },
                fumeExtraction: {
                  en: 'Fume Extraction contract',
                  de: 'Rauchabzugsanlage',
                },
                ventilation: {
                  en: 'Ventilation System contract',
                  de: 'Lüftungsanlage',
                },
                garageDoor: {
                  en: 'Garage Door contract',
                  de: 'TG-Tor',
                },
                caretaker: {
                  en: 'Caretaker contract',
                  de: 'Service Vertrag - Hausmeister',
                },
                cleaning: {
                  en: 'Cleaning contract',
                  de: 'Service Vertrag - Gebäudereinigung',
                },
                outdoor: {
                  en: 'Outdoor contract',
                  de: 'Service Vertrag - Aussenanlage',
                },
                winter: {
                  en: 'Winter contract',
                  de: 'Service Vertrag - Winterdienst',
                },
              },
            },
          },
        },
      },
    },
    propertyAccounting: {
      SubNavigationBar: {
        bookings: {
          name: {
            en: 'Bookings',
            de: 'Buchungen',
          },
          items: {
            bills: {
              en: 'Bills',
              de: 'Rechnungen',
            },
            propertyAccounts: {
              en: 'Property accounts',
              de: 'Objektkonten',
            },
            housingAccounts: {
              en: 'Housing accounts',
              de: 'Wohnungs-konten',
            },
            dunning: {
              en: 'Dunning',
              de: 'mahnwesen',
            },
            creditsAndDebits: {
              en: 'Credits & Debits',
              de: 'Kreditoren und Debitoren',
            },
            insurance: {
              en: 'Insurance',
              de: 'Versicherung',
            },
          },
        },
      },
    },
    invoice: {
      title: {
        en: 'Invoice',
        de: 'Rechnungen',
      },
      add: {
        en: 'Add',
        de: 'Hinzufügen',
      },
      upload: {
        en: 'Upload',
        de: 'Rechnungen hochladen',
      },
      manual: {
        en: 'Add manually',
        de: 'Rechnung manuell anlegen',
      },
      email: {
        buttonLabel: {
          en: 'Invoices via Email',
          de: 'Rechnungen via Email',
        },
        subject: {
          en: 'Invoices to Impower',
          de: 'Rechnungen an Impower',
        },
        body: {
          en: 'To create invoices: Send PDF documents to: ',
          de: 'Um Rechnunge anzulegen: Schicken Sie PDF-Dateien per Email an: ',
        },
      },
      delete: {
        en: 'Delete',
        de: 'Löschen',
      },
      revertToDraft: {
        en: 'Revert to draft',
        de: 'Auf Entwurf zurücksetzen',
      },
      stopRecurring: {
        en: 'Stop recurring',
        de: 'Dauerauftrag stoppen',
      },
      reject: {
        en: 'Reject',
        de: 'Ablehnen',
      },
      copy: {
        en: 'Create a copy',
        de: 'Kopie erstellen',
      },
      searchBar: {
        direction: {
          en: 'Direction',
          de: 'Richtung',
        },
        directions: {
          incoming: {
            en: 'Incoming',
            de: 'Eingehend',
          },
          outgoing: {
            en: 'Outgoing',
            de: 'Ausgehend',
          },
        },
      },
      table: {
        headers: {
          invoiceHrId: {
            en: 'Invoice ID',
            de: 'Rechnungs-ID',
          },
          propertyHrId: {
            en: 'Property ID',
            de: 'Objekt ID',
          },
          internalNr: {
            en: 'Property-Nr.',
            de: 'Objekt-Nr.',
          },
          invoiceNumber: {
            en: 'Invoice Number',
            de: 'Rechnungs-Nr',
          },
          propertyPrivateId: {
            en: 'Property private ID',
            de: 'Objekt private ID',
          },
          propertyName: {
            en: 'Property name',
            de: 'Objektbezeichnung',
          },
          creditor: {
            en: 'Creditor',
            de: 'Rechnungssteller',
          },
          amount: {
            en: 'Amount',
            de: 'Betrag',
          },
          dueDate: {
            en: 'Due date',
            de: 'Zahlungsziel',
          },
          createdDate: {
            en: 'Created on',
            de: 'Erstellt am',
          },
          createdBy: {
            en: 'Created by',
            de: 'Bearbeiter',
          },
          invoiceDate: {
            en: 'Invoice date',
            de: 'Beleg-/Rechnungs-Datum',
          },
          state: {
            en: 'State',
            de: 'Status',
          },
          receiverIban: {
            en: 'Receiver IBAN',
            de: 'Empfänger IBAN',
          },
          administrationType: {
            en: 'Administration type',
            de: 'Verwaltungsart',
          },
          administrationTypes: {
            WEG: {
              en: 'Wohnungseigentum (WEG)',
              de: 'Wohnungseigentum (WEG)',
            },
            MV: {
              en: 'Mietverwaltung (MV)',
              de: 'Mietverwaltung (MV)',
            },
            SEV: {
              en: 'Sondereigentumsverwaltung (SEV)',
              de: 'Sondereigentumsverwaltung (SEV)',
            },
          },
        },
        states: {
          NEW: {
            en: 'Draft',
            de: 'Entwurf',
          },
          READY_FOR_APPROVAL: {
            en: 'Ready',
            de: 'Bereit',
          },
          APPROVED: {
            en: 'Approved',
            de: 'Freigegeben',
          },
          SENT_TO_BANK: {
            en: 'Sent to Bank',
            de: 'An Bank übermittelt',
          },
          PAID: {
            en: 'Paid',
            de: 'Bezahlt',
          },
          RECURRING: {
            en: 'Recurring',
            de: 'Dauerauftrag',
          },
          RECURRING_STOPPED: {
            en: 'Recurring stopped',
            de: 'Gestoppt',
          },
          SUPPLIER: {
            en: 'Supplier',
            de: 'Versorger',
          },
          DELETED: {
            en: 'Deleted',
            de: 'Gelöscht',
          },
          OVERDUE: {
            en: 'Overdue',
            de: 'Überfällig',
          },
          CANCELLED: {
            en: 'Cancelled',
            de: 'Abgebrochen',
          },
        },
        lastItem: {
          en: 'No more invoice to load',
          de: 'Kein Rechnungen mehr zu laden',
        },
      },
    },
    bankAccount: {
      title: {
        en: 'Bank Accounts',
        de: 'Bankkonten',
      },
      table: {
        headers: {
          administrator: {
            en: 'Property Supervisor',
            de: 'Obj Betreuer',
          },
          houseMoneyBalance: {
            en: 'Actual House Money Account',
            de: 'Tatsächlich Hausgeldkonto',
          },
          reserveMoneyBalance: {
            en: 'Actual Reserve Money Account',
            de: 'Tatsächlich Rücklagenkonto',
          },
          totalBalance: {
            en: 'Actual Total Balance',
            de: 'Tatsächlicher Saldo',
          },
          calculatedHouseMoneyBalance: {
            en: 'Calculated House Money Account',
            de: 'Kalkuliert Hausgeldkonto',
          },
          calculatedReserveMoneyBalance: {
            en: 'Calculated Reserve Money Account',
            de: 'Kalkuliert Rücklagenkonto',
          },
          calculatedTotalBalance: {
            en: 'Calculated Total Balance',
            de: 'Kalkulierter Saldo',
          },
          accountType: {
            en: 'Account Type',
            de: 'Kontoart',
          },
          accountName: {
            en: 'Account Name',
            de: 'Konto Name',
          },
          accountIban: {
            en: 'IBAN',
            de: 'IBAN',
          },
          calculatedAccountBalance: {
            en: 'Calculated Balance',
            de: 'Kalkulierter Saldo',
          },
          actualAccountBalance: {
            en: 'Actual Balance',
            de: 'Tatsächlicher Saldo',
          },
          HOUSE: {
            en: 'House',
            de: 'Hausgeldkonto',
          },
          RESERVE: {
            en: 'Reserve',
            de: 'Rücklagenkonto',
          },
          RENT: {
            en: 'Rent',
            de: 'Mietenkonto',
          },
          DEPOSIT: {
            en: 'Deposit',
            de: 'Kautionskonto',
          },
        },
      },
      transactions: {
        header: {
          title: {
            en: 'Bank account information',
            de: 'Kontoinformation',
          },
          add: {
            en: 'Add',
            de: 'Hinzufügen...',
          },
          book: {
            en: 'Book',
            de: 'Buchen',
          },
          currentEconomicYear: {
            en: 'Current economic year',
            de: 'Aktuelles Wirtschaftsjahr',
          },
          previousYear: {
            en: 'Previous year',
            de: 'Vorheriges Wirtschaftsjahr',
          },
          payment: {
            en: 'Payment',
            de: 'Überweisung',
          },
          administrator: {
            en: 'Administrator:',
            de: 'Objektbetreuer:',
          },
          accounts: {
            en: 'Total',
            de: 'Insgesamt',
          },
          account: {
            en: 'Account selection',
            de: 'Kontoauswahl',
          },
          balance: {
            en: 'Balance',
            de: 'Kontostand',
          },
          accountType: {
            en: 'Account type',
            de: 'Kontoart Bank',
          },
          house: {
            en: 'House account',
            de: 'Hausgeldkonto',
          },
          reserve: {
            en: 'Reserve account',
            de: 'Rücklagenkonto',
          },
          bankAccountType: {
            [PropertyBankAccountProjectionDtoPropertyAccountTypesEnum.HOUSE]: {
              en: 'House account',
              de: 'Hausgeldkonto',
            },
            [PropertyBankAccountProjectionDtoPropertyAccountTypesEnum.RESERVE]: {
              en: 'Reserve account',
              de: 'Rücklagenkonto',
            },
            [PropertyBankAccountProjectionDtoPropertyAccountTypesEnum.DEPOSIT]: {
              en: 'Deposit account',
              de: 'Kautionskonto',
            },
            [PropertyBankAccountProjectionDtoPropertyAccountTypesEnum.RENT]: {
              en: 'Rent account',
              de: 'Mietenkonto',
            },
          },
        },
        filter: {
          title: {
            en: 'Period',
            de: 'Zeitraum',
          },
          periodOptions: {
            last30: {
              en: 'Last 30 days',
              de: 'Letzte 30 Tage',
            },
            last60: {
              en: 'Last 60 days',
              de: 'Letzte 60 Tage',
            },
            last90: {
              en: 'Last 90 days',
              de: 'Letzte 90 Tage',
            },
            currentYear: {
              en: 'Current year',
              de: 'Aktuelles Jahr',
            },
            lastYear: {
              en: 'Last year',
              de: 'Letztes Jahr',
            },
            beforeLastYear: {
              en: 'The year before last year',
              de: 'Vorletztes Jahr',
            },
          },
          unassignedTransactions: {
            en: 'Show only unassigned transactions',
            de: 'Nur "Zuweisen" anzeigen',
          },
        },
        table: {
          columns: {
            date: {
              en: 'Date / Value',
              de: 'Datum / Wert',
            },
            type: {
              en: 'Type',
              de: 'Art',
            },
            counterpartName: {
              en: 'Name / Company',
              de: 'Name / Firma',
            },
            bankAccount: {
              en: 'Bank account',
              de: 'Bankverbindung',
            },
            statement: {
              en: 'Statement',
              de: 'Verwendungszweck',
            },
            booking: {
              en: 'Booking',
              de: 'Buchung',
            },
            account: {
              en: 'Account',
              de: 'Sachkonto',
            },
            bookingText: {
              en: 'Booking text',
              de: 'Buchungstext',
            },
            invoice: {
              en: 'Invoice',
              de: 'Beleg',
            },
            balance: {
              en: 'Balance EUR',
              de: 'Saldo EUR',
            },
            amount: {
              en: 'Amount',
              de: 'Betrag',
            },
            allocate: {
              en: 'Not allocated',
              de: 'Nicht zugewiesen',
            },
            complete: {
              en: 'Complete',
              de: 'Vollständig',
            },
            purpose: {
              en: 'Explanation',
              de: 'Erläuterung',
            },
          },
          counterpartMandateReference: {
            en: 'Counterpart mandate reference',
            de: 'Mandatsreferenz',
          },
          counterpartCreditorId: {
            en: 'Counterpart creditor ID',
            de: 'Gläubiger-ID',
          },
          counterpartCustomerReference: {
            en: 'Counterpart customer reference',
            de: 'Kundenreferenz',
          },
          noInfo: {
            en: 'No information',
            de: 'Keine Information',
          },
        },
      },
      payment: {
        header: {
          title: {
            en: 'New Payment',
            de: 'Neue Zahlung',
          },
          create: {
            en: 'Create',
            de: 'Erstellen',
          },
        },
        form: {
          property: {
            en: 'Property',
            de: 'Objekt',
          },
          bankAccount: {
            en: 'Select bank account',
            de: 'Konto auswählen',
          },
          counterpart: {
            en: 'Select recipient',
            de: 'Empfänger auswählen',
          },
          counterpartBankAccount: {
            en: 'Select recipient bank account',
            de: 'Wählen Sie das Empfängerkonto aus',
          },
          iban: {
            en: 'IBAN',
            de: 'IBAN',
          },
          bic: {
            en: 'BIC',
            de: 'BIC',
          },
          amount: {
            en: 'Amount in EUR',
            de: 'Betrag in EUR',
          },
          text: {
            en: 'Statement',
            de: 'Verwendungstext',
          },
          execution: {
            en: 'Execute',
            de: 'Ausführen',
          },
          asap: {
            en: 'Earliest possible',
            de: 'Nächstmöglich',
          },
          date: {
            en: 'Scheduled',
            de: 'Terminiert',
          },
        },
      },
      assignTransaction: {
        header: {
          back: {
            en: 'Allocate',
            de: 'Zuweisen',
          },
          book: {
            en: 'Book',
            de: 'Buchen',
          },
          details: {
            date: {
              en: 'Date',
              de: 'Datum',
            },
            value: {
              en: 'Value',
              de: 'Wert',
            },
            purpose: {
              en: 'Explanation',
              de: 'Erläuterung',
            },
            credit: {
              en: 'Credit',
              de: 'Betrag Soll Eur',
            },
            debit: {
              en: 'Debit',
              de: 'Betrag Haben Eur',
            },
          },
        },
        credit: {
          section1: {
            title: {
              en: 'Creditor',
              de: 'Kreditor',
            },
            creditor: {
              en: 'Company of person',
              de: 'Firma oder Einzelperson',
            },
            owner: {
              en: 'Owner',
              de: 'Eigentümer',
            },
            other: {
              en: 'Other',
              de: 'Anderes',
            },
          },
          section2: {
            title: {
              en: 'Cost assignment',
              de: 'Kostenzuordnung',
            },
            subtitle: {
              en: 'Assignment to accounts',
              de: 'Zuordnung Sachkonten',
            },
            ownerSelect: {
              en: 'Select owner',
              de: 'Eigentümer auswählen',
            },
            amount: {
              en: 'Amount',
              de: 'Rechnungsbetrag',
            },
            account: {
              en: 'Account',
              de: 'Sachkonto',
            },
            text: {
              en: 'Booking text',
              de: 'Buchungstext',
            },
            add: {
              en: 'Divide the amount',
              de: 'Rechnungsbetrag auf ein weiteres Sachkonto aufteilen',
            },
          },
        },
        searchBar: {
          label: {
            en: 'Search invoice',
            de: 'Rechnung suchen',
          },
          placeholder: {
            en: 'Search for invoice',
            de: 'Suchen nach Rechnungs-, nummer, steller',
          },
          table: {
            headers: {
              invoiceHrId: {
                en: 'Invoice ID',
                de: 'Rechnungsnummer',
              },
              creditor: {
                en: 'Creditor',
                de: 'Rechnungssteller',
              },
              date: {
                en: 'Date',
                de: 'Datum',
              },
              amount: {
                en: 'Amount',
                de: 'Betrag',
              },
            },
            allocate: {
              en: 'Allocate',
              de: 'Zuweisen',
            },
          },
          invoiceDetails: {
            costAllocation: {
              en: 'Cost allocation',
              de: 'Kostenzuordnung',
            },
            inspectInvoice: {
              en: 'Inspect invoice',
              de: 'Rechnung ansehen',
            },
            totalUnitAmount: {
              en: 'Total amount for the housing unit',
              de: 'Gesamtbetrag für die Wohneinheit',
            },
            totalWegAmount: {
              en: 'Total amount for the WEG',
              de: 'Gesamtbetrag für die WEG',
            },
            unitAmountP1: {
              en: 'Amount №',
              de: 'Betrag №',
            },
            unitAmountP2: {
              en: 'for the housing unit',
              de: 'für die Wohneinheit',
            },
            unit: {
              en: 'Unit',
              de: 'Einheit',
            },
            account: {
              en: 'Account',
              de: 'Sachkonto',
            },
            laborCost: {
              en: 'Labor cost',
              de: 'Lohnanteil',
            },
            laborCostType: {
              en: 'Labor cost type',
              de: 'Lohnkostenart',
            },
            bookingText: {
              en: 'Booking text',
              de: 'Buchungstext',
            },
            splitP1: {
              en: 'Split №',
              de: 'Teilt №',
            },
            splitP2: {
              en: 'Amount',
              de: 'Betrag',
            },
          },
        },
        debit: {
          section1: {
            title: {
              en: 'Invoice',
              de: 'Rechnung',
            },
            search: {
              en: 'Search',
              de: 'Suchen',
            },
            book: {
              en: 'Paperless booking',
              de: 'Beleglos buchen',
            },
          },
          section2: {
            title: {
              en: 'Invoice Party',
              de: 'Rechnungsteller',
            },
            recipient: {
              en: 'Company or individual',
              de: 'Firma oder Einzelperson',
            },
            noVat: {
              en: 'No VAT incl.',
              de: 'Kein MwSt.',
            },
            vat: {
              en: 'VAT',
              de: 'MwSt.',
            },
          },
          section3: {
            title: {
              en: 'Cost Allocation',
              de: 'Kostenzuordnung',
            },
            toggleBtn: {
              en: 'Add',
              de: 'Hinzufügen',
            },
            abortBtn: {
              en: 'Abort',
              de: 'Abbrechen',
            },
            subsection1: {
              title: {
                en: 'Allocation WEG/Housing Unit',
                de: 'Zuordnung WEG/Wohneinheit',
              },
              fields: {
                title1: {
                  en: 'Assignment to WEG/flat',
                  de: 'Zuordnung WEG/Wohneinheit',
                },
                title2: {
                  en: 'Assignment of labor costs',
                  de: 'Zuordnung Lohnanteil',
                },
                title3: {
                  en: 'Assignment to accounts',
                  de: 'Zuordnung Sachkonten',
                },
                assignAmount: {
                  en: '(Thereof) assign amount to the unit',
                  de: '(Davon) Betrag auf die Wohneinheit zuzuordnen',
                },
                unit: {
                  en: 'Unit',
                  de: 'Einheit',
                },
                add: {
                  en: 'Split total to another unit',
                  de: 'Gesamtbetrag auf eine weitere Wohneinheit aufteilen',
                },
                wegAmount: {
                  en: 'WEG Amount',
                  de: 'WEG Betrag',
                },
                totalForUnit: {
                  en: 'Total for unit',
                  de: 'Gesamtbetrag für die Wohneinheit',
                },
                laborCost: {
                  en: '(Thereof) labor costs for unit',
                  de: '(Davon) Wohneinheit Lohnanteil',
                },
                totalForWeg: {
                  en: 'Total for WEG',
                  de: 'Gesamtbetrag für die WEG',
                },
                laborCostWeg: {
                  en: '(Thereof) labor costs for WEG',
                  de: '(Davon) WEG Lohnanteil',
                },
              },
            },
            subsection2: {
              title: {
                en: 'Allocation Portion of Wages',
                de: 'Zuordnung Lohnanteil',
              },
              fields: {
                amount: {
                  en: 'Total amount',
                  de: 'Gesamtbetrag',
                },
                portion: {
                  en: '(Thereof) portion of wages',
                  de: '(Davon) Lohnanteil',
                },
              },
            },
            subsection3: {
              title: {
                en: 'Allocation Material Costs',
                de: 'Zuordnung Sachkonten',
              },
              fields: {
                amount: {
                  en: 'Invoice Amount',
                  de: 'Rechnungsbetrag',
                },
                account: {
                  en: 'Account',
                  de: 'Sachkonto',
                },
                bookingText: {
                  en: 'Booking Text',
                  de: 'Buchungstext',
                },
                addButton: {
                  en: 'Allocate invoice amount to another account',
                  de: 'Rechnungsbetrag auf ein weiteres Sachkonto aufteilen',
                },
              },
            },
          },
          applyRuleInFuture: {
            en: 'From now on use this schema for all similar transactions.',
            de: 'Buchungslogik ab sofort für alle gleichartigen Transaktionen anwenden.',
          },
          applyRuleForPast: {
            en: 'Execute this schema for all similar und unmatched transactions in the past (same economic year)',
            de: 'Buchungslogik für gleichartige, nicht zugewiesene und zurückliegende Transaktionen im aktuellen Wirtschaftsjahr anwenden.',
          },
        },
      },
    },
    distributionKeyTable: {
      title: {
        en: 'Distribution keys',
        de: 'Verteilerschlüssel',
      },
      table: {
        headers: {
          property: {
            en: 'Property',
            de: 'Objekt',
          },
          administrator: {
            en: 'Administrator',
            de: 'Betreuer',
          },
          accountant: {
            en: 'Accountant',
            de: 'Buchhalter',
          },
        },
      },
      filters: {
        administrationType: {
          en: 'Administration type',
          de: 'Verwaltungsart',
        },
        administrationTypes: {
          WEG: {
            en: 'Wohnungseigentum (WEG)',
            de: 'Wohnungseigentum (WEG)',
          },
          MV: {
            en: 'Mietverwaltung (MV)',
            de: 'Mietverwaltung (MV)',
          },
          SEV: {
            en: 'SEV with WEG connection',
            de: 'SEV mit WEG-Verbindung',
          },
          SEV_MV: {
            en: 'SEV without WEG connection',
            de: 'SEV ohne WEG-Verbindung',
          },
        },
      },
    },
    payment: {
      title: {
        en: 'Payments',
        de: 'Zahlungslauf',
      },
      sepa: {
        en: 'SEPA file',
        de: 'SEPA Datei',
      },
      downloadSepa: {
        en: 'Download SEPA file',
        de: 'SEPA Datei herunterladen',
      },
      repairSepa: {
        en: 'Revert SEPA file orders to state NEW',
        de: 'Aufträge aus der SEPA Datei auf Status "Neu" zurücksetzen',
      },
      downloadWrongSEPA: {
        message: {
          en: 'Download failed',
          de: 'Download Fehlgeschlagen',
        },
        description: {
          en: 'Selected payments contain no NEW payment',
          de: 'Ausgewählte Zahlungen enthalten keine NEUE Zahlung',
        },
      },
      sepaAll: {
        en: 'Download all Orders as SEPA file',
        de: 'SEPA Datei für alle Aufträge herunterladen',
      },
      delete: {
        en: 'Delete',
        de: 'Löschen',
      },
      mark: {
        en: 'Mark as Sent to Bank',
        de: 'Als Versendet markieren',
      },
      reset: {
        en: 'Reset to New',
        de: 'Zurücksetzen',
      },
      sepaSelect: {
        en: 'Download selected orders as SEPA file',
        de: 'SEPA Datei für ausgewählte Aufträge herunterladen',
      },
      table: {
        headers: {
          invoiceHrId: {
            en: 'Invoice',
            de: 'Rechnung',
          },
          propertyHrId: {
            en: 'Property HR ID',
            de: 'Objekt',
          },
          propertyIdInternal: {
            en: 'Property-Nr.',
            de: 'Objekt-Nr.',
          },
          property: {
            en: 'Property',
            de: 'Objekt',
          },
          propertyName: {
            en: 'Property name',
            de: 'Objektbezeichnung',
          },
          state: {
            en: 'State',
            de: 'Status',
          },
          createdBy: {
            en: 'Created by',
            de: 'Ersteller',
          },
          createdDate: {
            en: 'Created on',
            de: 'Erstellt am',
          },
          administrator: {
            en: 'Administrator',
            de: 'Objektbetreuer',
          },
          accountant: {
            en: 'Accountant',
            de: 'Objektbuchhalter',
          },
          updatedBy: {
            en: 'Updated by',
            de: 'Aktualisiert von',
          },
          updatedDate: {
            en: 'Updated on',
            de: 'aktualisiert am',
          },
          executionDate: {
            en: 'Execution date',
            de: 'Ausführungsdatum',
          },
          amount: {
            en: 'Amount',
            de: 'Betrag',
          },
          statement: {
            en: 'Statement',
            de: 'Verwendungszweck',
          },
          executeButton: {
            en: 'Execute',
            de: 'Jetzt ausführen',
          },
          ebics: {
            en: 'EBICS',
            de: 'EBICS',
          },
          execute: {
            en: 'Execute',
            de: 'Zahlungslauf',
          },
          senderName: {
            en: 'Sender',
            de: 'Absender',
          },
          businessPartnerName: {
            en: 'Business partner name',
            de: 'Geschäftspartner Name',
          },
          businessPartner: {
            en: 'Business partner',
            de: 'Geschäftspartner',
          },
          businessPartnerIban: {
            en: 'Business partner IBAN',
            de: 'Geschäftspartner IBAN',
          },
          propertyIban: {
            en: 'Sender IBAN',
            de: 'Absender IBAN',
          },
          propertyBic: {
            en: 'Sender BIC',
            de: 'Absender BIC',
          },
          propertyBankCode: {
            en: 'Bank code',
            de: 'Bankleitzahl',
          },
          propertyAccountInstitute: {
            en: 'Sender Bank',
            de: 'Absender Bank',
          },
          accountType: {
            en: 'Account type',
            de: 'Abgehendes Konto',
          },
          balance: {
            en: 'Balance',
            de: 'Kontostand',
          },
        },
        paymentStates: {
          NEW: {
            en: 'New',
            de: 'Neu',
          },
          SENT: {
            en: 'Sent',
            de: 'Versendet',
          },
          DELETED: {
            en: 'Deleted',
            de: 'Gelöscht',
          },
        },
        lastItem: {
          en: 'No more payments to load',
          de: 'Keine Zahlungen mehr zu laden',
        },
      },
      filter: {
        administrationTypes: {
          en: 'Administration type',
          de: 'Verwaltungsart',
        },
        supervisorName: {
          en: 'Administrator',
          de: 'Objektbetreuer',
        },
        accountantName: {
          en: 'Accountant',
          de: 'Objektbuchhalter',
        },
        createdBy: {
          en: 'Created by',
          de: 'Erstellt von',
        },
        contactIds: {
          en: 'Contact Id',
          de: 'Kontakt Id',
        },
        propertyHrId: {
          en: 'Property-ID',
          de: 'Objekt-ID',
        },
        propertyName: {
          en: 'Property name',
          de: 'Objektbezeichnung',
        },
        status: {
          title: {
            en: 'State',
            de: 'Status',
          },
        },
        state: {
          en: 'State',
          de: 'Status',
        },
        updatedBy: {
          en: 'Updater',
          de: 'Aktualisiert von',
        },
        createdOnFrom: {
          en: 'Created from',
          de: 'Erstellt von',
        },
        createdOnTo: {
          en: 'Created until',
          de: 'Erstellt bis',
        },
        minExecutionDate: {
          en: 'Execution date from',
          de: 'Ausführungsdatum von',
        },
        maxExecutionDate: {
          en: 'Execution date until',
          de: 'Ausführungsdatum bis',
        },
        minAmount: {
          en: 'Amount from',
          de: 'Betrag von',
        },
        maxAmount: {
          en: 'Amount to',
          de: 'Betrag bis',
        },
        amount: {
          en: 'Amount',
          de: 'Betrag',
        },
        statement: {
          en: 'Statement',
          de: 'Verwendungszweck',
        },
        counterpartName: {
          en: 'Recipient name',
          de: 'Empfänger',
        },
        counterpartIban: {
          en: 'Recipient iban',
          de: 'Empfänger iban',
        },
        propertyIban: {
          en: 'Sender IBAN',
          de: 'Absender IBAN',
        },
        propertyBic: {
          en: 'Sender BIC',
          de: 'Absender BIC',
        },
        propertyAccountInstitute: {
          en: 'Sender Bank',
          de: 'Absender Bank',
        },
        invoiceHrId: {
          en: 'Invoice HR ID',
          de: 'Rechnung',
        },
        propertyIdInternalList: {
          en: 'Property-Nr.',
          de: 'Objekt-Nr.',
        },
        managementCompany: {
          en: 'Management Company',
          de: 'Verwaltungsfirma',
        },
        propertyAdministrationTypes: {
          en: 'Administration type',
          de: 'Verwaltungsart',
        },
      },
      fileUploadModal: {
        title: {
          en: 'Repair an EBICS file',
          de: 'Korrigieren Sie eine EBICS Datei',
        },
        upload: {
          en: 'Upload EBICS xml',
          de: 'Laden Sie EBICS xml hoch',
        },
        cancel: {
          en: 'Cancel',
          de: 'Abbrechen',
        },
        body: {
          en: 'By uploading the file, all the payments inside are irretrievably marked as "New" except the ones linked to offboarded properties! Make sure you offboard inactive properties first!',
          de: 'Durch das Hochladen der Datei werden alle darin enthaltenen Zahlungen unwiederbringlich auf "Neu" zurückgesetzt, mit Ausnahme der Zahlungen von Objekten im Status "Offboarded"! Alle Zahlungen im Status "Neu" stehen somit zum erneuten Ausführungversuch bereit. Stellen Sie sicher, dass Sie abgegebene Objekte entsprechend updaten!',
        },
      },
      executionModal: {
        title: {
          en: 'Execute via EBICS file',
          de: 'Ausführen über EBICS Datei',
        },
        download: {
          en: 'Download xml and mark transactions',
          de: 'Datei herunterladen & Transaktionen markieren',
        },
        cancel: {
          en: 'Cancel',
          de: 'Abbrechen',
        },
        criteriaTitle: {
          en: 'An XML file will be created for all transactions with the following criteria:',
          de: 'Es wird eine XML Datei für alle Transaktionen mit folgenden Kriterien erstellt:',
        },
        selectionTitle: {
          en: 'An XML file will be created for the selected transactions.',
          de: 'Es wird eine XML Datei für die ausgewählte Transaktionen.',
        },
        numberOfTransaction: {
          en: 'Number of transactions: ',
          de: 'Anzahl der Transaktionen:',
        },
        totalAmount: {
          en: 'Total amount: ',
          de: 'Gesamtsumme: ',
        },
        body: {
          en: 'Downloading the file will mark all selected transactions as “Sent”',
          de: 'Durch Herunterladen der Datei werden alle ausgewählten Transaktionen als “Versendet” markiert.',
        },
        insufficientFundsTitle: {
          en: 'Properties with insufficient funds:',
          de: 'Objekte mit nicht ausreichender Kontodeckung:',
        },
        insufficientFundsTableDescription: {
          en: 'Select which properties you would like to include the payments for regardless having insufficient funds.',
          de: 'Wählen Sie aus, für welche der Objekte mit nicht ausreichender Kontodeckung Sie die Zahlungen einziehen möchten. Unabhängig davon, ob genügend Mittel vorhanden sind.',
        },
        include: {
          en: 'Include',
          de: 'Einschließen',
        },
        propertyName: {
          en: 'Property name',
          de: 'Objektbezeichnung',
        },
        iban: {
          en: 'IBAN',
          de: 'IBAN',
        },
        bankAccountBalance: {
          en: 'Bank account balance',
          de: 'Kontostand',
        },
        amount: {
          en: 'Amount of transactions',
          de: 'Anzahl der Transaktionen',
        },
        updated: {
          en: 'Updated on',
          de: 'Aktualisiert am',
        },
        executionDateWarning: {
          en: (newDate: string) => `One or more of the selected files has the execution date in the past. The execution date for these files will be changed to: ${newDate}.`,
          de: (newDate: string) => `Das Ausführungsdatum einer oder mehrerer ausgewählter Dateien liegt in der Vergangenheit. Das Ausführungsdatum dieser Dateien wird abgeändert auf: ${newDate}.`,
        },
        sendAmendMandateInfoToBank: {
          en: 'Send Direct Debit Mandate amendment information to bank.',
          de: 'Information über Änderungen des Lastschriftmandats an Bank übermitteln.',
        },
      },
    },
    account: {
      subNavigationBar: {
        title: {
          en: 'Bank Accounts',
          de: 'Bankkonten',
        },
      },
    },
    bankConnections: {
      title: {
        en: 'Bank Connections',
        de: 'Bankverbindungen',
      },
      update: {
        en: 'Update connection',
        de: 'Verbindung aktualisieren',
      },
    },
    accountSales: {
      title: {
        en: 'Account sales',
        de: 'Kontoumsätze',
      },
      table: {
        headers: {
          accountType: {
            en: 'Account Type',
            de: 'Kontoart',
          },
          accountName: {
            en: 'Account Name',
            de: 'Konto Name',
          },
          iban: {
            en: 'IBAN',
            de: 'IBAN',
          },
          toAssign: {
            en: 'To Assign',
            de: 'Zuweisen',
          },
          editor: {
            en: 'Editor',
            de: 'Bearbeiter',
          },
          status: {
            en: 'Status',
            de: 'Status',
          },
        },
      },
      accountTypes: {
        house: {
          en: 'HOUSE',
          de: 'HAUSGELD',
        },
        reserve: {
          en: 'RESERVE',
          de: 'RÜCKLAGEN',
        },
      },
    },
    accountBalances: {
      title: {
        en: 'Account balances',
        de: 'Kontensalden',
      },
      table: {
        headers: {
          supervisor: {
            en: 'Administrator',
            de: 'Objektbetreuer',
          },
          accountant: {
            en: 'Accountant',
            de: 'Buchhalter',
          },
          currentYear: {
            en: 'Current year',
            de: 'Laufendes jahr',
          },
          income: {
            en: 'Income',
            de: 'Einnahmen',
          },
          expense: {
            en: 'Expense',
            de: 'Ausgaben',
          },
          reserve: {
            en: 'Reserve funds',
            de: 'Rücklagen',
          },
          contractBalance: {
            en: 'Owner/Tenant Balances',
            de: 'Eigentümer-/Mietersalden',
          },
        },
      },
    },
    accountSheet: {
      header: {
        title: {
          en: 'Account sheet',
          de: 'Kontenblatt',
        },
        number: {
          en: 'Account number',
          de: 'kontonummer',
        },
        name: {
          en: 'Account name',
          de: 'Kontobezeichnung',
        },
        category: {
          en: 'Account category',
          de: 'Kontokategorie',
        },
        type: {
          en: 'Account type',
          de: 'Kontenart',
        },
        distributionKeyHMS: {
          en: 'Distribution key House money settlement',
          de: 'Verteilerschlüssel Hausgeldabrechnung',
        },
        distributionKeyEP: {
          en: 'Distribution key economic plan',
          de: 'Verteilerschlüssel Wirtschaftsplan',
        },
        allUnits: {
          en: 'Distributed across all units',
          de: 'Auf alle Einheiten verteilt',
        },
        notAllUnits: {
          en: 'Individual units excluded',
          de: 'Einzelne Einheiten ausgeschlossen',
        },
        allocable: {
          en: 'Allocable',
          de: 'Umlagefähig',
        },
        detailsEP: {
          en: 'Details EP',
          de: 'Details WP',
        },
        detailsHMS: {
          en: 'Details HMS',
          de: 'Details HGA',
        },
      },
      filter: {
        title: {
          en: 'Bookings',
          de: 'Buchungen',
        },
        date: {
          en: 'Booking date',
          de: 'Buchungsdatum',
        },
      },
      table: {
        headers: {
          booking: {
            en: 'Booking',
            de: 'Buchung',
          },
          account: {
            en: 'Account',
            de: 'Sachkonto',
          },
          date: {
            en: 'Date',
            de: 'Datum',
          },
          creditor: {
            en: 'Creditor',
            de: 'Kreditor',
          },
          bookingText: {
            en: 'Booking text',
            de: 'Buchungstext',
          },
          counterAccount: {
            en: 'Counter account',
            de: 'Gegenkonto',
          },
          credit: {
            en: 'Credit',
            de: 'Haben',
          },
          debit: {
            en: 'Debit',
            de: 'Soll',
          },
          laborCost: {
            en: 'Labor cost',
            de: 'Lohnanteil',
          },
          laborCostType: {
            en: 'Labor cost type',
            de: 'Lohnkostenart',
          },
          invoiceDate: {
            en: 'Date',
            de: 'Datum',
          },
          invoiceAmount: {
            en: 'Amount',
            de: 'Betrag',
          },
          invoiceState: {
            en: 'Invoice state',
            de: 'Buchung',
          },
          invoice: {
            en: 'Invoice',
            de: 'Beleg',
          },
          balance: {
            en: 'Balance',
            de: 'Saldo',
          },
          startingBalance: {
            en: 'Starting balance',
            de: 'Anfangs-Saldo',
          },
          endBalance: {
            en: 'End balance',
            de: 'End-Saldo',
          },
          vatAmount: {
            en: 'VAT amount',
            de: 'MwSt Betrag',
          },
          vatPercentage: {
            en: 'VAT percentage',
            de: 'MwSt Prozentsatz',
          },
          vatEligibilityPercentage: {
            en: 'Eligible VAT share',
            de: 'Anteil anrechenbare USt.',
          },
          vatEligibilityAmount: {
            en: 'Eligible VAT amount',
            de: 'Anrechenbare USt.',
          },
        },
        laborCostTypes: {
          HOUSEHOLD_RELATED_SERVICES: {
            en: 'Household related services',
            de: 'Haushaltsnahe DL',
          },
          TECHNICIAN_SERVICE: {
            en: 'Technician service',
            de: 'Dl.anteil Handwerkerrechnungen',
          },
          MARGINAL_EMPLOYMENT: {
            en: 'Marginal employment',
            de: 'Geringf. Beschäftigung',
          },
          INSURABLE_EMPLOYMENT: {
            en: 'Insurable employment',
            de: 'SV.pfl. Beschäftigung',
          },
        },
      },
    },
    distributionKeyDetails: {
      title: {
        en: 'Distribution Key Details',
        de: 'Verteilerschlüssel Details',
      },
      header: {
        save: {
          en: 'Save',
          de: 'Speichern',
        },
        accountNumber: {
          en: 'Account number',
          de: 'Kontonummer',
        },
        accountName: {
          en: 'Account name',
          de: 'Kontobezeichnung',
        },
        economicYear: {
          en: 'Economic year',
          de: 'Wirtschaftsjahr',
        },
        applicable: {
          en: 'Expenses applicable',
          de: 'Umlagefähig',
        },
        wkaRelevant: {
          en: 'WKA relevant',
          de: 'WKA relevant',
        },
        economicPlan: {
          en: 'Economic plan',
          de: 'Wirtschaftsplan',
        },
        houseMoneySettlement: {
          en: 'House money settlement',
          de: 'Hausgeldabrechnung',
        },
        options: {
          mea: {
            en: 'MEA',
            de: 'Miteigentumsanteil',
          },
          units: {
            en: 'Units',
            de: 'Einheiten',
          },
          persons: {
            en: 'Persons',
            de: 'Personen',
          },
          heatingArea: {
            en: 'Heating area',
            de: 'Heizfläche',
          },
          area: {
            en: 'Area',
            de: 'Fläche',
          },
          individual: {
            en: 'Individual',
            de: 'Individuell',
          },
          distributionValue: {
            en: 'Distribution value',
            de: 'Verteilungswert',
          },
          heatingCost: {
            en: 'Heating cost',
            de: 'lt. Heizkostenabr.',
          },
          directCost: {
            en: 'Direct cost',
            de: 'Direktkosten',
          },
        },
      },
      table: {
        groupTitle1: {
          en: 'Units',
          de: 'Einheiten',
        },
        groupTitle2: {
          en: 'Distribution keys',
          de: 'Verteilerschlüssel',
        },
        groupTitle3: {
          en: 'Unit distribution',
          de: 'Verteilung Einheiten',
        },
        id: {
          en: 'ID',
          de: 'ID',
        },
        type: {
          en: 'Category',
          de: 'Kategorie',
        },
        title: {
          en: 'Title',
          de: 'Bezeichnung',
        },
        buildingName: {
          en: 'Building name',
          de: 'Gebäudename',
        },
        owner: {
          en: 'Owner',
          de: 'Eigentümer',
        },
        hga: {
          en: 'House money settlement',
          de: 'HGA',
        },
        wp: {
          en: 'Eco plan',
          de: 'WP',
        },
        MEA: {
          en: 'MEA',
          de: 'MEA',
        },
      },
    },
    accountsOverview: {
      title: {
        en: 'Accounts Overview',
        de: 'Sachkonten',
      },
      expandAll: {
        en: 'Expand all',
        de: 'Alle Aufklappen',
      },
      collapseAll: {
        en: 'Collapse all',
        de: 'Alle zuklappen',
      },
      expand: {
        en: 'Expand',
        de: 'Aufklappen',
      },
      close: {
        en: 'Close',
        de: 'Zuklappen',
      },
      noBookings: {
        en: 'No bookings',
        de: 'Keine Buchungen',
      },
      navigationBar: {
        groups: {
          overview: {
            en: 'Overview',
            de: 'Übersicht',
          },
        },
        houseMoneySettlement: {
          en: 'Incomes and Expenses',
          de: 'Einnahmen und Ausgaben',
        },
        wealthStatus: {
          en: 'Reserve funds and Results',
          de: 'Rücklagen und Ergebnisse',
        },
        debitorsCreditors: {
          en: 'Debitors/Creditors',
          de: 'Debitoren/Kreditoren',
        },
      },
      cards: {
        categories: {
          houseMoneySettlement: {
            en: 'Incomes and Expenses',
            de: 'Einnahmen und Ausgaben',
          },
          wealthStatus: {
            en: 'Reserve funds and Results',
            de: 'Rücklagen und Ergebnisse',
          },
          debitorsCreditors: {
            en: 'Debitors / Creditors',
            de: 'Debitoren / Kreditoren',
          },
        },
        total: {
          en: 'Total',
          de: 'Gesamt',
        },
        subCategories: {
          revenue: {
            en: 'Income',
            de: 'Einnahmen',
          },
          expense: {
            en: 'Expenses',
            de: 'Ausgaben',
          },
          liquidity: {
            en: 'Liquidity',
            de: 'Flüssige Mittel, Aktive RAP',
          },
          liability: {
            en: 'Liabilities',
            de: 'Verbindlichkeiten',
          },
          capital: {
            en: 'Capital',
            de: 'Rücklagen & Abrechnungsspitze',
          },
          openingStatus: {
            en: 'Opening Status',
            de: 'Eröffnungsbestände',
          },
          debitors: {
            en: 'Debitors',
            de: 'Debitoren',
          },
          creditors: {
            en: 'Creditors',
            de: 'Kreditoren',
          },
        },
      },
      dataExportModal: {
        labels: {
          of: {
            en: 'Of',
            de: 'Von',
          },
          timePeriod: {
            en: 'Time Period',
            de: 'Zeitspanne',
          },
          format: {
            en: 'Format',
            de: 'Format',
          },
          account: {
            en: 'Account(Multiple selection)',
            de: 'Sachkonto(Mehrfachauswahl)',
          },
          dateStart: {
            en: 'Date Start',
            de: 'Datum Start',
          },
          dateEnd: {
            en: 'Date End',
            de: 'Datum Ende',
          },
        },
        selectInputOfOptions: {
          allAccounts: {
            en: 'All Accounts',
            de: 'Alle Konten',
          },
          individualAccounts: {
            en: 'Individual account(s)',
            de: 'Einzelne Konten',
          },
        },
        selectInputTimePeriodOptions: {
          currentEconomicYear: {
            en: 'Current Economic Year',
            de: 'Aktuelles Wirtschaftsjahr',
          },
          previousYear: {
            en: 'Previous Year',
            de: 'Vorheriges Wirtschaftsjahr',
          },
          custom: {
            en: 'Custom',
            de: 'Benutzerdefiniert',
          },
        },
      },
      table: {
        groupHeaders: {
          current: {
            en: 'Current Year',
            de: 'Laufendes Jahr',
          },
          comparison: {
            en: 'Comparison',
            de: 'Vergleich',
          },
          difference: {
            en: 'Difference in %',
            de: 'Abweichung in %',
          },
        },
        headers: {
          code: {
            en: 'Account Nr',
            de: 'Kontonummer',
          },
          name: {
            en: 'Account Title',
            de: 'Kontobezeichnung',
          },
          currentYear: {
            en: 'Current year',
            de: 'Laufendes Jahr',
          },
          debitSum: {
            en: 'Debit',
            de: 'Soll',
          },
          creditSum: {
            en: 'Credit',
            de: 'Haben',
          },
          balance: {
            en: 'Balance',
            de: 'Saldo',
          },
          prevBalance: {
            en: 'Prev. Year',
            de: 'Vorjahr',
          },
        },
      },
    },
    resolutionRecordPropertyList: {
      title: {
        en: 'Resolution record',
        de: 'Beschlusssammlung',
      },
      table: {
        headers: {
          supervisorName: {
            en: 'Supervisor name',
            de: 'Objektbetreuer',
          },
        },
      },
    },
    resolutionRecordOverview: {
      title: {
        en: 'Record Overview',
        de: 'Beschlusssammlung',
      },
      create: {
        en: 'Create resolution',
        de: 'Beschluss anlegen',
      },
      view: {
        en: 'Document view',
        de: 'Dokumentenansicht',
      },
      search: {
        en: 'Search Resolution Record Title…',
        de: 'Beschlusstitel suchen…',
      },
      table: {
        headers: {
          serialNumber: {
            en: 'Serial #',
            de: 'LFD.NR',
          },
          status: {
            en: 'Resolution status',
            de: 'Status',
          },
          title: {
            en: 'Resolution title',
            de: 'Beschlusstitel',
          },
          meetingType: {
            en: 'Meeting type',
            de: 'Beschlussbasis',
          },
          location: {
            en: 'Location',
            de: 'Ort',
          },
          date: {
            en: 'Date',
            de: 'Datum',
          },
          legalEvents: {
            en: 'Legal events',
            de: 'Rechtsvorgänge',
          },
          created: {
            en: 'Created',
            de: 'Erstellt',
          },
          manager: {
            en: 'Manager',
            de: 'Verwalter',
          },
          meetingLeader: {
            en: 'Meeting leader',
            de: 'Versammlungsleiter',
          },
          viewPdf: {
            en: 'Document',
            de: 'Dokument',
          },
        },
        majorityAccepted: {
          en: 'Majority accepted',
          de: 'Mehrheitlich angenommen',
        },
        allAccepted: {
          en: 'All accepted',
          de: 'Einstimmig',
        },
      },
    },
    resolutionRecordEditing: {
      back: {
        en: 'Back to overview',
        de: 'Zurück zur Übersicht',
      },
      titleCreate: {
        en: 'Create resolution',
        de: 'Beschluss anlegen',
      },
      save: {
        en: 'Save',
        de: 'Anlegen',
      },
      edit: {
        en: 'Edit',
        de: 'Bearbeiten',
      },
      section1: {
        sectionTitle: {
          en: 'Protocol statement',
          de: 'Protokollarische Feststellung',
        },
        manager: {
          en: 'Manager/ Management company',
          de: 'Verwalter/Verwaltungsunternehmen',
        },
        leader: {
          en: 'Meeting leader',
          de: 'Versammlungsleiter',
        },
        minuteTaker: {
          en: 'Minute taker',
          de: 'Protokollführer',
        },
      },
      section2: {
        sectionTitle: {
          en: 'Resolution information',
          de: 'Beschlussinformationen',
        },
        resolutionNumber: {
          en: 'Serial number',
          de: 'Beschlussnummer',
        },
        meetingType: {
          en: 'Meeting type',
          de: 'Art der Versammlung',
        },
        meetingTypes: {
          ordinary: {
            en: 'Ordinary meeting',
            de: 'Ordentliche ETV',
          },
          extraordinary: {
            en: 'Extraordinary meeting',
            de: 'Außerordentliche ETV',
          },
          circular: {
            en: 'Circular resolution',
            de: 'Umlaufbeschluss',
          },
        },
        date: {
          en: 'Date',
          de: 'Datum',
        },
        location: {
          en: 'Location',
          de: 'Versammlungsort',
        },
        resolutionTitle: {
          en: 'Resolution title',
          de: 'Beschlusstitel',
        },
        agendaItem: {
          en: 'Agenda item',
          de: 'Tagesordnungspunkt',
        },
        resolutionText: {
          en: 'Resolution text',
          de: 'Beschlussfassungstext',
        },
      },
      section3: {
        sectionTitle: {
          en: 'Voting result',
          de: 'Abstimmungsergebnis',
        },
        voteKey: {
          en: 'Counting method',
          de: 'Stimmrecht',
        },
        countingMethods: {
          mea: {
            en: 'MEA',
            de: 'MEA',
          },
          peopleHeadCount: {
            en: 'People head count',
            de: 'Kopfprinzip',
          },
          numberOfUnits: {
            en: 'Number of units',
            de: 'Einheitsprinzip',
          },
        },
        voteMajorityKey: {
          label: {
            en: 'Majority requirement',
            de: 'Mehrheitserfordernis',
          },
          ABSOLUTE_MAJORITY: {
            en: 'Simple majority',
            de: 'Einfache Mehrheit',
          },
          UNANIMOUS_MAJORITY: {
            en: 'Unanimity of all owners',
            de: 'Allstimmigkeit',
          },
          ATTENDING_UNANIMOUS_MAJORITY: {
            en: 'Unanimity of attending owners',
            de: 'Einstimmigkeit',
          },
          QUALIFIED_MAJORITY: {
            en: 'Qualified majority',
            de: 'Qualifizierte Mehrheit',
          },
        },
        present: {
          en: 'Present',
          de: 'Anwesend',
        },
        yes: {
          en: 'Yes',
          de: 'Ja',
        },
        no: {
          en: 'No',
          de: 'Nein',
        },
        abstention: {
          en: 'Abstention',
          de: 'Enthaltungen',
        },
        resolutionStatus: {
          en: 'Resolution status',
          de: 'Beschlussstatus',
        },
        resolutionStatuses: {
          accepted: {
            en: 'Accepted',
            de: 'Angenommen',
          },
          declined: {
            en: 'Declined',
            de: 'Abgelehnt',
          },
          challenged: {
            en: 'Challenged',
            de: 'Angefochten',
          },
          legalValid: {
            en: 'Legal valid',
            de: 'Bestandskräftig',
          },
          legalDecided: {
            en: 'Legal decided',
            de: 'Rechtskräftig',
          },
        },
      },
      section4: {
        sectionTitle: {
          en: 'Legal events',
          de: 'Rechtsvorgänge',
        },
        manager: {
          en: 'Manager',
          de: 'Verwalter',
        },
        createdBy: {
          en: 'Created by',
          de: 'Ersteller',
        },
        dateOfCreation: {
          en: 'Date of creation',
          de: 'Datum der Vorgangserstellung',
        },
        eventType: {
          en: 'Legal event type',
          de: 'Art des Rechtsvorgangs',
        },
        eventTypes: {
          courtDecision: {
            en: 'Court decision',
            de: 'Gerichtsurteil',
          },
          challenge: {
            en: 'Challenge',
            de: 'Anfechtung',
          },
          else: {
            en: 'Else',
            de: 'Sonstiges',
          },
        },
        verdict: {
          en: 'Verdict (resolution)',
          de: 'Urteilsformel (Beschluss)',
        },
        court: {
          en: 'Court',
          de: 'Gericht',
        },
        date: {
          en: 'Date',
          de: 'Datum',
        },
        docketNumber: {
          en: 'Docket number',
          de: 'Gerichtliches Aktenzeichen',
        },
        involvedParties: {
          en: 'Involved parties',
          de: 'Beteiligte Parteien',
        },
        verdictCosts: {
          en: 'Verdict (costs)',
          de: 'Urteilsformeln (Kosten)',
        },
        verdictEnforceability: {
          en: 'Verdict (enforceability)',
          de: 'Urteilsformeln (Vollstreckbarkeit)',
        },
        note: {
          en: 'Note',
          de: 'Vermerk',
        },
        challengingParties: {
          en: 'Challenging parties',
          de: 'Anfechtende Parteien',
        },
        challengeReason: {
          en: 'Challenge reason',
          de: 'Anfrechtungsgrund',
        },
        add: {
          en: 'Add another legal event',
          de: 'Weiteren Vorgang hinzufügen',
        },
        expand: {
          en: 'Expand',
          de: 'Aufklappen',
        },
        collapse: {
          en: 'Collapse',
          de: 'Zuklappen',
        },
      },
    },
    standingOrderOverview: {
      title: {
        en: 'Standing Orders',
        de: 'Zahlungsaufträge',
      },
      table: {
        headers: {
          id: {
            en: 'Payment-ID',
            de: 'Zahlungs-ID',
          },
          createdBy: {
            en: 'Created by',
            de: 'Erstellt von',
          },
          createDate: {
            en: 'Created on',
            de: 'Erstellt am',
          },
          startDate: {
            en: 'First execution',
            de: 'Erste Ausführung',
          },
          endDate: {
            en: 'Last execution',
            de: 'Letzte Ausführung',
          },
          interval: {
            en: 'Interval',
            de: 'Intervall',
          },
          amount: {
            en: 'Amount',
            de: 'Betrag',
          },
          statement: {
            en: 'Statement',
            de: 'Verwendungszweck',
          },
          administrator: {
            en: 'Administrator',
            de: 'Betreuer',
          },
          businessPartner: {
            en: 'Business partner',
            de: 'Geschäftspartner',
          },
          businessPartnerIban: {
            en: 'Business partner IBAN',
            de: 'Geschäftspartner IBAN',
          },
          senderAccountType: {
            en: 'Sender account type',
            de: 'Abgehendes Konto',
          },
          propertyBankAccountHolderName: {
            en: 'Sender Account Name',
            de: 'Kontobezeichnung',
          },
          propertyIban: {
            en: 'Sender-IBAN',
            de: 'Absender-IBAN',
          },
          property: {
            en: 'Property',
            de: 'Objekt',
          },
          invoice: {
            en: 'Invoice',
            de: 'Rechnungen',
          },
          deleted: {
            en: 'Deleted',
            de: 'Gelöscht',
          },
          state: {
            en: 'State',
            de: 'Status',
          },
          active: {
            en: 'Active',
            de: 'Aktiv',
          },
        },
        fields: {
          accountTypes: {
            house: {
              en: 'HOUSE',
              de: 'HAUSEGELD',
            },
            reserve: {
              en: 'RESERVE',
              de: 'RÜCKLAGEN',
            },
          },
          intervalOptions: {
            weekly: {
              en: 'Weekly',
              de: 'Wöchentlich',
            },
            biweekly: {
              en: 'Biweekly',
              de: 'Zweiwöchentlich',
            },
            monthly: {
              en: 'Monthly',
              de: 'Monatlich',
            },
            bimonthly: {
              en: 'Bimonthly',
              de: 'Zweimonatlich',
            },
            quarterly: {
              en: 'Quarterly',
              de: 'Quartalsweise',
            },
            fourmonthly: {
              en: 'Four months',
              de: 'Viermonatlich',
            },
            half_yearly: {
              en: 'Half-yearly',
              de: 'Halbjährlich',
            },
            yearly: {
              en: 'Yearly',
              de: 'Jährlich',
            },
            halfyearly: {
              en: 'Half-yearly',
              de: 'Halbjährlich',
            },
          },
          menuItems: {
            stop: {
              en: 'Delete',
              de: 'Löschen',
            },
          },
        }
        ,
      },
      showDeleted: {
        en: 'Show deleted',
        de: 'Gelöschte anzeigen',
      },
    },
    houseMoneySettlement: {
      navigationGroups: {
        overview: {
          en: 'Overview',
          de: 'Übersicht',
        },
        laborCosts: {
          en: 'Labor costs',
          de: 'Arbeitskosten',
        },
      },
      title: {
        en: 'House money settlement',
        de: 'Hausgeldabrechnung',
      },
      loadingMessage: {
        en: 'The report may take a few minutes to generate, depending on the number of contracts. In the meantime, you can leave and come back later.',
        de: 'Da die Erstellung der Abrechnung einige Minuten dauern kann, können Sie diese Seite verlassen und später zurückkehren, um die Ergebnisse zu überprüfen.',
      },
      documentCreationInProgress: {
        en: 'Document creation is still ongoing',
        de: 'Die Dokumenterstellung ist noch nicht abgeschlossen',
      },
      houseMoneySettlementCreationInProgress: {
        en: 'House money settlement creation is in progress',
        de: 'Die Erstellung der Hausgeldabrechnung ist noch nicht abgeschlossen',
      },
      generatedOn: {
        en: 'Generated on',
        de: 'Erstellt am',
      },
      creationInitiatedOn1: {
        en: 'Creation was initiated on',
        de: 'Die Erstellung wurde am',
      },
      creationInitiatedOn2: {
        en: '',
        de: 'gestartet',
      },
      generationErrorMessage: {
        en: 'Report generation failed. Please try to recreate or',
        de: 'Die Abrechnung konnte nicht erstellt werden. Bitte erstellen Sie die Abrechnung neu oder',
      },
      contactSupport: {
        en: 'contact support.',
        de: 'kontaktieren Sie unser Support Team.',
      },
      messagesGeneratingTooltip: {
        en: 'Messages are currently generated for this process.',
        de: 'Es werden aktuell Nachrichten für diesen Prozess generiert.',
      },
      recreateModal: {
        title: {
          en: 'Regenerate house money settlement',
          de: 'Hausgeldabrechnung neu erstellen',
        },
        content: {
          en: 'Messages are currently generated for this process. Upon regeneration those messages will fail and you have to resend them. Are you sure you want to regenerate the document?',
          de: 'Es werden aktuell Nachrichten für diesen Prozess generiert. Wenn Sie das Dokument neu erstellen, schlagen die Nachrichten fehl und Sie müssen diese nochmal versenden. Sind Sie sicher, dass Sie die das Dokument neu erstellen möchten?',
        },
        cancel: {
          en: 'Cancel',
          de: 'Abbrechen',
        },
        regenerate: {
          en: 'Regenerate',
          de: 'Neu erstellen',
        },
      },
      generationText: {
        title: {
          en: 'Generating document…',
          de: 'Dokument wird erstellt…',
        },
        subtitle: {
          en: 'The document may take a few minutes to generate. In the meantime, you can leave and come back later.',
          de: 'Da die Erstellung des Dokuments einige Minuten dauern kann, können Sie diese Seite verlassen und später zurückkehren.',
        },
      },
      table: {
        headers: {
          type: {
            en: 'Adminstration type',
            de: 'Art',
          },
          administrator: {
            en: 'Administrator',
            de: 'Objektbetreuer',
          },
          hgaStatus: {
            en: 'HGA Status',
            de: 'HGA Status',
          },
        },
        houseMoneySettlementStatuses: {
          to_do: {
            en: 'To do',
            de: 'Ausstehend',
          },
          in_creation: {
            en: 'Creation in progress',
            de: 'Dokument wird erstellt',
          },
          draft: {
            en: 'Draft',
            de: 'Entwurf',
          },
          prepared: {
            en: 'Prepared',
            de: 'Vorbereitet',
          },
          finalized: {
            en: 'Finalized',
            de: 'Finalisiert',
          },
          failed: {
            en: 'Failed',
            de: 'Fehlgeschlagen',
          },
        },
      },
      prepayment: {
        table: {
          headers: {
            accountCode: {
              en: 'Account nr.',
              de: 'Kontonummer',
            },
            accountName: {
              en: 'Name',
              de: 'Kontoname',
            },
            month: {
              en: 'Month',
              de: 'Monat',
            },
            date: {
              en: 'Date',
              de: 'Datum',
            },
            months: {
              january: {
                en: 'January',
                de: 'Januar',
              },
              february: {
                en: 'February',
                de: 'Februar',
              },
              march: {
                en: 'March',
                de: 'März',
              },
              april: {
                en: 'April',
                de: 'April',
              },
              may: {
                en: 'May',
                de: 'Mai',
              },
              june: {
                en: 'June',
                de: 'Juni',
              },
              july: {
                en: 'July',
                de: 'Juli',
              },
              august: {
                en: 'August',
                de: 'August',
              },
              september: {
                en: 'September',
                de: 'September',
              },
              october: {
                en: 'October',
                de: 'Oktober',
              },
              november: {
                en: 'November',
                de: 'November',
              },
              december: {
                en: 'December',
                de: 'Dezember',
              },
              januar: {
                en: 'January',
                de: 'Januar',
              },
              februar: {
                en: 'February',
                de: 'Februar',
              },
              märz: {
                en: 'March',
                de: 'März',
              },
              mai: {
                en: 'May',
                de: 'Mai',
              },
              juni: {
                en: 'June',
                de: 'Juni',
              },
              juli: {
                en: 'July',
                de: 'Juli',
              },
              oktober: {
                en: 'October',
                de: 'Oktober',
              },
              dezember: {
                en: 'December',
                de: 'Dezember',
              },
            },
            debit: {
              en: 'Debit',
              de: 'Soll',
            },
            credit: {
              en: 'Credit',
              de: 'Haben',
            },
            balance: {
              en: 'Balance',
              de: 'Saldo',
            },
            subtotal: {
              en: 'Subtotal',
              de: 'Zwischensumme',
            },
          },
          cashReceived: {
            en: 'Cash recieved from current owner:',
            de: 'Zahlungseingänge des aktuellen Eigentümers:',
          },
        },
      },
      houseMoney: {
        title: {
          en: 'Your prepayments',
          de: 'Ihre Vorauszahlungen',
        },
        table: {
          claimedAmount: {
            en: 'House money claimed amount',
            de: 'Hausgeld Geforderte Beträge',
          },
        },
      },
      reserveFunds: {
        title: {
          en: 'Added value to reserve funds',
          de: 'Zuführung zu den Instandhaltungsrücklagen',
        },
        table: {
          claimedAmount: {
            en: 'Reserve claimed amount',
            de: 'Rücklage Geforderte Beträge',
          },
        },
      },
      propertyAccountBalances: {
        expenses: {
          title: {
            en: 'Expenses',
            de: 'Ausgaben',
          },
          subTitles: {
            applicableExpenses: {
              en: 'Applicable costs',
              de: 'Umlagefähige Kosten',
            },
            notApplicableExpenses: {
              en: 'Not applicable costs',
              de: 'Nicht umlagefähige Kosten',
            },
          },
          applicableExpenses: {
            en: 'Applicable expenses',
            de: 'Umlagefähige Kosten',
          },
          notApplicableExpenses: {
            en: 'Not applicable expenses',
            de: 'Nicht umlagefähige Kosten',
          },
          subtotal: {
            en: 'Subtotal',
            de: 'ZwischenSumme',
          },
          summary: {
            costs: {
              en: 'Costs',
              de: 'Kosten',
            },
          },
        },
        income: {
          title: {
            en: 'Income',
            de: 'Einnahmen',
          },
          summary: {
            costs: {
              en: 'Costs',
              de: 'Kosten',
            },
          },
        },
        table: {
          headers: {
            account: {
              en: 'Account',
              de: 'Sachkonto',
            },
            distributionKey: {
              en: 'Distribution key',
              de: 'Verteilerschlüssel',
            },
            shares: {
              en: 'Shares',
              de: 'Gesamtmenge',
            },
            costs: {
              en: 'Costs',
              de: 'Kosten',
            },
            income: {
              en: 'Income',
              de: 'Einnahmen',
            },
          },
        },
      },
      unitAccountBalances: {
        expenses: {
          title: {
            en: 'Expenses',
            de: 'Ausgaben',
          },
          subTitles: {
            applicableExpenses: {
              en: 'Applicable costs',
              de: 'Umlagefähige Kosten',
            },
            notApplicableExpenses: {
              en: 'Not applicable costs',
              de: 'Nicht umlagefähige Kosten',
            },
          },
          applicableExpenses: {
            en: 'Applicable expenses',
            de: 'Umlagefähige Kosten',
          },
          notApplicableExpenses: {
            en: 'Not applicable expenses',
            de: 'Nicht umlagefähige Kosten',
          },
          subtotal: {
            en: 'Subtotal',
            de: 'ZwischenSumme',
          },
          summary: {
            totalCosts: {
              en: 'Total Costs',
              de: 'Gesamtkosten',
            },
            yourShare: {
              en: 'Your share',
              de: 'Ihr anteil',
            },
          },
        },
        income: {
          title: {
            en: 'Income',
            de: 'Einnahmen',
          },
          summary: {
            totalCosts: {
              en: 'Total Costs',
              de: 'Gesamtkosten',
            },
            yourShare: {
              en: 'Your share',
              de: 'Ihr anteil',
            },
          },
        },
        table: {
          headers: {
            account: {
              en: 'Account',
              de: 'Sachkonto',
            },
            distributionKey: {
              en: 'Distribution key',
              de: 'Verteilerschlüssel',
            },
            settlementAmount: {
              en: 'Settlement amount',
              de: 'Abrechnungsmengen',
            },
            expenses: {
              en: 'Expenses',
              de: 'Ausgaben',
            },
            income: {
              en: 'Income',
              de: 'Einnahmen',
            },
            total: {
              en: 'Total',
              de: 'Gesamt',
            },
            yourShare: {
              en: 'Your share',
              de: 'Ihr anteil',
            },
          },
        },
      },
      overview: {
        close: {
          en: 'Back',
          de: 'Zurück',
        },
        buttonForward: {
          en: 'Forward to voucher check',
          de: 'Zur Belegprüfung freigeben',
        },
        recreate: {
          en: 'Recreate',
          de: 'Neu erstellen',
        },
        draft: {
          en: 'Revert to Draft',
          de: 'In Entwurf zurücksetzen',
        },
        download: {
          en: 'Download',
          de: 'Herunterladen',
        },
        prepare: {
          en: 'Mark Prepared',
          de: 'Als Vorbereitet markieren',
        },
        finalize: {
          en: 'Mark Finalized',
          de: 'Als finalisiert markieren',
        },
        send: {
          en: 'Preview and send',
          de: 'Vorschau & Versenden',
        },
        checkMessages: {
          en: 'View messages',
          de: 'Nachrichten ansehen',
        },
      },
      bankBalanceDevelopment: {
        title: {
          en: 'Bank balance development',
          de: 'Entwicklung der Geldkonten / Barvermögen',
        },
        summaryTitle: {
          en: 'Total',
          de: 'Summe',
        },
        table: {
          headers: {
            account: {
              en: 'Account',
              de: 'Konto',
            },
            startBalance: {
              en: 'Start balance',
              de: 'Anfangsstand',
            },
            in: {
              en: 'In',
              de: 'Abgang',
            },
            out: {
              en: 'Out',
              de: 'Zugang',
            },
            endBalance: {
              en: 'End balance',
              de: 'Endstand',
            },
          },
        },
      },
      unitsSettlementOverview: {
        title: {
          en: 'Result of settlement for all units',
          de: 'Ergebnis der Abrechnung für alle Einheiten',
        },
        fields: {
          unit: {
            en: 'Unit',
            de: 'Einheit',
          },
          summary: {
            en: 'Summary',
            de: 'Summe',
          },
          expensesTotal: {
            en: 'costs',
            de: 'Kosten',
          },
          minusIncomeTotal: {
            en: 'Income',
            de: 'Einnahmen',
          },
          unitsReserveFund: {
            en: 'Reserve funds',
            de: 'Soll Erhaltungsrücklage',
          },
          settlementSum: {
            en: 'Settlement sum',
            de: 'Abrechnungssumme',
          },
          houseMoneyAndReserveFund: {
            en: 'RF and HM',
            de: 'Soll Hausgeld',
          },
          settlementResults: {
            en: 'Settlement result',
            de: 'Abrechnungsspitze',
          },
          houseMoneyAndReserveFundsClaimedTotal: {
            en: 'Paid house money and reserve funds',
            de: 'Hausgeldzahlung (Ist)',
          },
          debtOrCredit: {
            en: 'Debt or Credit',
            de: 'Rückstand oder Überzahlung',
          },
          houseMoneyDebit: {
            en: 'Thereof Housemoney',
            de: 'Davon Hausgeld',
          },
          reserveFundDebit: {
            en: 'Thereof Reserve funds',
            de: 'Davon Rückstand',
          },
          balance: {
            en: 'Balance',
            de: 'Saldo',
          },
        },
      },
      reserveFundDevelopment: {
        title: {
          en: 'Development of reserve funds ',
          de: 'Entwicklung der Instandhaltungsrücklage ',
        },
        accountCode: {
          en: 'Code',
          de: 'Code',
        },
        startBalanceSummary: {
          en: 'Starting Balance',
          de: 'Anfangsbestand (Soll)',
        },
        endBalanceSummary: {
          en: 'End Balance',
          de: 'Endbestand (Soll)',
        },
        bridgeOfDebitHeader: {
          en: 'Bridge of the Debit Amount to the actual available reserve funds',
          de: 'Überleitung Soll-Betrag zum Ist-Betrag der verfügbaren Instandhaltungsrückstellung',
        },
        bridgeOfDebitHmPrevious: {
          en: 'Deficits on housemoney payments from previous years',
          de: 'Rückstände auf Hausgeldzahlungen (gesamt) aus den Vorjahren',
        },
        bridgeOfDebitHmCurrent: {
          en: 'Deficits on housemoney pamynets from the current year',
          de: 'Rückstände auf Hausgeldzahlungen (gesamt) aus dem aktuellen Jahr',
        },
        deficitOwnersHm: {
          en: 'Deficits of Owners on house money payments',
          de: 'Rückstände von Eigentümern auf Hausgeldzahlungen',
        },
        availableReserveFunds: {
          en: 'Available reserve funds',
          de: 'Verfügbare Instandhaltungsrücklage',
        },
      },
      laborCosts: {
        HOUSEHOLD_RELATED_SERVICES: {
          en: 'Household related services',
          de: 'Haushaltsnahe DL',
        },
        TECHNICIAN_SERVICE: {
          en: 'Technician service',
          de: 'Dl.anteil Handwerkerrechnungen',
        },
        MARGINAL_EMPLOYMENT: {
          en: 'Marginal employment',
          de: 'Geringf. Beschäftigung',
        },
        INSURABLE_EMPLOYMENT: {
          en: 'Insurable employment',
          de: 'SV.pfl. Beschäftigung',
        },
      },
      unitResults: {
        title: {
          en: 'Result of settlement',
          de: 'Ergebnis der Abrechnung',
        },
        fields: {
          expensesTotal: {
            en: 'Unit costs',
            de: 'Kosten gem. Einzelabrechnung',
          },
          minusIncomeTotal: {
            en: 'Unit Income',
            de: 'Einnahmen gem. Einzelabrechnung',
          },
          unitsReserveFund: {
            en: 'Units planned added value to RF',
            de: 'Beitragsverpflichtung zur Instandhaltungsrücklage',
          },
          settlementSum: {
            en: 'Settlement sum',
            de: 'Abrechnungssumme',
          },
          houseMoneyAndReserveFund: {
            en: 'Units planned added value to RF and its HM',
            de: 'Hausgeld-Soll gem. Einzelwirtschaftsplan',
          },
          settlementResults: {
            en: 'Settlement result',
            de: 'Abrechnungsspitze',
          },
          ownersDebt: {
            en: 'Your debt',
            de: 'Ihr Rückstand auf den Wirtschaftsplan',
          },
          ownersAdditionalPayment: {
            en: 'Your additional payment',
            de: 'Ihr Gutbetrag auf den Wirtschaftsplan ',
          },
          balance: {
            en: 'Balance',
            de: 'Saldo',
          },
          summaryTitle: {
            en: 'Total',
            de: 'Summe',
          },
        },
      },
    },
    economicPlan: {
      navigationGroups: {
        overview: {
          en: 'Overview',
          de: 'Übersicht',
        },
      },
      title: {
        en: 'Economic plan',
        de: 'Wirtschaftsplan',
      },
      create: {
        en: 'Create economic plan for...',
        de: 'Wirtschaftsplan erstellen für...',
      },
      table: {
        headers: {
          epId: {
            en: 'EP-ID',
            de: 'WP-ID',
          },
          propertyManager: {
            en: 'Property manager',
            de: 'Objektbetreuer',
          },
          hgaStatus: {
            en: 'HGA Status',
            de: 'HGA Status',
          },
          etvStatus: {
            en: 'ETV Status',
            de: 'ETV Status',
          },
          epStatus: {
            en: 'EP Status',
            de: 'WP Status',
          },
        },
      },
      editPage: {
        createButton: {
          en: 'Create',
          de: 'Erstellen',
        },
        editButton: {
          en: 'Edit',
          de: 'Bearbeiten',
        },
        open: {
          en: 'Expand',
          de: 'Aufklappen',
        },
        close: {
          en: 'Collapse',
          de: 'Zuklappen',
        },
      },
      overview: {
        buttonSend: {
          en: 'Send economic plan',
          de: 'WP versenden',
        },
        buttonActivate: {
          en: 'Activate',
          de: 'Aktivieren',
        },
      },
      activation: {
        activate: {
          en: 'Activate now',
          de: 'Jetzt aktivieren',
        },
        title: {
          en: 'Economic Plan Activation',
          de: 'Aktivierung Wirtschaftsplan',
        },
        statusChange: {
          en: 'Status change',
          de: 'Status Änderung',
        },
        status: {
          en: 'Economic plan status',
          de: 'Wirtschaftsplan Status',
        },
        decided: {
          en: 'Decided',
          de: 'Beschlossen',
        },
        validFrom: {
          en: 'New house money and reserve funds valid from',
          de: 'Neue Hausgelder gültig ab',
        },
      },
      propertyAccountBalances: {
        totalAmountTitle: {
          en: 'Total Amounts',
          de: 'Gesamtsummen',
        },
        expenses: {
          title: {
            en: 'Expenses/Income',
            de: 'Ausgaben/Einnahmen',
          },
          subTitles: {
            applicableExpenses: {
              en: 'Applicable costs',
              de: 'Umlagefähige Kosten',
            },
            notApplicableExpenses: {
              en: 'Not applicable costs',
              de: 'Nicht umlagefähige Kosten',
            },
          },
          applicableExpenses: {
            en: 'Applicable expenses',
            de: 'Umlagefähige Kosten',
          },
          notApplicableExpenses: {
            en: 'Not applicable expenses',
            de: 'Nicht umlagefähige Kosten',
          },
          subtotal: {
            en: 'Subtotal',
            de: 'ZwischenSumme',
          },
          summary: {
            costs: {
              en: 'Costs',
              de: 'Kosten',
            },
          },
          total: {
            en: 'Total tender',
            de: 'Summe Voranschlag',
          },
        },
        income: {
          title: {
            en: 'Income',
            de: 'Einnahmen',
          },
          summary: {
            costs: {
              en: 'Costs',
              de: 'Kosten',
            },
          },
        },
        table: {
          headers: {
            account: {
              en: 'Account',
              de: 'Sachkonto',
            },
            distributionKey: {
              en: 'Distribution key',
              de: 'Verteilerschlüssel',
            },
            shares: {
              en: 'Shares',
              de: 'Gesamtmenge',
            },
            costs: {
              en: 'costs',
              de: 'Kosten',
            },
            income: {
              en: 'Income',
              de: 'Einnahmen',
            },
          },
        },
        reserveFunds: {
          title: {
            en: 'Added value to reserve funds',
            de: 'Zuführung zu den Instandhaltungsrücklagen',
          },
          summaryTitle: {
            en: 'Reserve funds',
            de: 'Erhaltungsrücklage',
          },
        },
        results: {
          title: {
            en: 'Result',
            de: 'Ergebnis',
          },
        },
        totalAmount: {
          title: {
            en: 'Total Amount',
            de: 'Gesamtsummen',
          },
          table: {
            headers: {
              total: {
                en: 'Total',
                de: 'Gesamtmenge',
              },
            },
            fields: {
              expenses: {
                en: 'Expenses',
                de: 'Ausgaben',
              },
              income: {
                en: 'Income',
                de: 'Einnahmen',
              },
              reserveFunds: {
                en: 'Added value to reserve funds',
                de: 'Zuführungen zu den Instandhaltungsrücklagen',
              },
            },
          },
          total: {
            en: 'Total',
            de: 'Gesamt',
          },
        },
        incomeExpenseOverview: {
          table: {
            headers: {
              account: {
                en: 'Account',
                de: 'Sachkonto',
              },
              distributionKey: {
                en: 'Distribution key',
                de: 'Verteilerschlüssel',
              },
              settlementAmount: {
                en: 'Settlement amount',
                de: 'ABR.Menge',
              },
              totalAmount: {
                en: 'Total amount',
                de: 'Gesmatmenge',
              },
            },
          },
        },
      },
      unitAccountBalances: {
        expenses: {
          title: {
            en: 'Expenses',
            de: 'Ausgaben',
          },
          subTitles: {
            applicableExpenses: {
              en: 'Applicable costs',
              de: 'Umlagefähige Kosten',
            },
            notApplicableExpenses: {
              en: 'Not applicable costs',
              de: 'Nicht umlagefähige Kosten',
            },
          },
          applicableExpenses: {
            en: 'Applicable expenses',
            de: 'Umlagefähige Kosten',
          },
          notApplicableExpenses: {
            en: 'Not applicable expenses',
            de: 'Nicht umlagefähige Kosten',
          },
          subtotal: {
            en: 'Subtotal',
            de: 'ZwischenSumme',
          },
          summary: {
            totalCosts: {
              en: 'Total Costs',
              de: 'Gesamtkosten',
            },
            yourShare: {
              en: 'Your share',
              de: 'Ihr anteil',
            },
          },
          total: {
            en: 'Expenses tender',
            de: 'Ausgaben Voranschlag',
          },
        },
        income: {
          title: {
            en: 'Income',
            de: 'Einnahmen',
          },
          summary: {
            totalCosts: {
              en: 'Total Income',
              de: 'Gesamteinnahmen',
            },
            yourShare: {
              en: 'Your share',
              de: 'Ihr anteil',
            },
          },
          total: {
            en: 'Income tender',
            de: 'Einnahmen Voranschlag',
          },
        },
        table: {
          headers: {
            account: {
              en: 'Account',
              de: 'Sachkonto',
            },
            distributionKey: {
              en: 'Distribution key',
              de: 'Verteilerschlüssel',
            },
            settlementAmount: {
              en: 'Settlement amount',
              de: 'Abrechnungsmengen',
            },
            expenses: {
              en: 'Expenses',
              de: 'Ausgaben',
            },
            total: {
              en: 'Total',
              de: 'Gesamt',
            },
            yourShare: {
              en: 'Your share',
              de: 'Ihr anteil',
            },
          },
        },
        ownershipDues: {
          title: {
            en: 'New ownership dues',
            de: 'Neufestsetzung der Beiträge',
          },
          total: {
            en: 'Total',
            de: 'Gesamt',
          },
        },
        totalAmount: {
          title: {
            en: 'Total amount',
            de: 'Gesamtsummen',
          },
          table: {
            headers: {
              total: {
                en: 'Total',
                de: 'Gesamt',
              },
              share: {
                en: 'You share',
                de: 'Ihr Anteil',
              },
            },
          },
        },
        reserveFunds: {
          title: {
            en: 'Added value to reserve funds',
            de: 'Zuführungen zu den Instandhaltungsrücklagen',
          },
          summary: {
            total: {
              en: 'Total',
              de: 'Gesamt',
            },
            share: {
              en: 'Your share',
              de: 'Ihr Anteil',
            },
          },
          table: {
            settlementAmount: {
              en: 'Settlement amount',
              de: 'Abrechnungsmengen',
            },
            contribution: {
              en: 'Contribution',
              de: 'Zuführung',
            },
            headers: {
              account: {
                en: 'Account',
                de: 'Sahkonto',
              },
              distribution: {
                en: 'Distribuion',
                de: 'Verteilung',
              },
              total: {
                en: 'Total',
                de: 'Gesamt',
              },
              share: {
                en: 'Your share',
                de: 'Ihr Anteil',
              },
            },
          },
          total: {
            en: 'Total',
            de: 'Gesamt',
          },
        },
      },
    },
    wka: {
      title: {
        en: 'WKA',
        de: 'Wärmekostenabrechnungen',
      },
    },
    reportOverview: {
      autocompleteEntire: {
        en: 'Total WEG',
        de: 'Gesamt WEG',
      },
      autocompleteUnit: {
        en: 'Unit #',
        de: 'Einheit #',
      },
    },
    contacts: {
      subNavigationBar: {
        name: {
          en: 'Contacts',
          de: 'Kontakte',
        },
        items: {
          contacts: {
            en: 'People',
            de: 'Personen',
          },
          companies: {
            en: 'Company',
            de: 'Unternehmen',
          },
        },
      },
      searchPlaceholder: {
        en: 'Search',
        de: 'Suchen',
      },
      contact: {
        add: {
          en: 'Add new Contact',
          de: 'Neuen Kontakt anlegen',
        },
        table: {
          name: {
            en: 'Name',
            de: 'Name',
          },
          role: {
            en: 'Role',
            de: 'Rolle',
          },
          company: {
            en: 'Company',
            de: 'Firma',
          },
          companyRole: {
            en: 'Company Role',
            de: 'Firmenrolle',
          },
          email: {
            en: 'E-mail',
            de: 'E-mail',
          },
          phoneOffice: {
            en: 'Company phone',
            de: 'Tel. geschäftlich',
          },
          phoneMobile: {
            en: 'Mobile phone',
            de: 'Tel. mobil',
          },
          phonePrivate: {
            en: 'Private phone',
            de: 'Tel. privat',
          },
        },
      },
      company: {
        add: {
          en: 'Add new company',
          de: 'Neues Unternehmen anlegen',
        },
        table: {
          managementCompany: {
            en: 'Management Company',
            de: 'Verwaltungsunternehmen',
          },
          companyName: {
            en: 'Name',
            de: 'Name',
          },
          tradeRegisterNumber: {
            en: 'Trade register number',
            de: 'Registrierungsnummer',
          },
          vatId: {
            en: 'VAT-ID',
            de: 'USt-IdNr',
          },
          numberOfBankAccounts: {
            en: 'Number of bank accounts',
            de: 'Bankkonten',
          },
          address: {
            en: 'Address',
            de: 'Adressen',
          },
          numberOfEmployees: {
            en: 'Number of employees',
            de: 'Mitarbeiter',
          },
          website: {
            en: 'Homepage',
            de: 'Webseite',
          },
        },
      },
    },
    contactEditing: {
      header: {
        titleNew: {
          en: 'Add new person',
          de: 'Neuen Kontakt anlegen',
        },
        titleEdit: {
          en: 'Edit person',
          de: 'Kontakt bearbeiten',
        },
        close: {
          en: 'Close',
          de: 'Schließen',
        },
        save: {
          en: 'Save',
          de: 'Speichern',
        },
      },
      contactSection: {
        title: {
          en: 'Contact data',
          de: 'Kontaktdaten',
        },
        personSection: {
          title: {
            en: 'Person',
            de: 'Person',
          },
          fields: {
            title: {
              en: 'Title',
              de: 'Titel',
            },
            titleOptions: {
              prof: {
                en: 'Prof.',
                de: 'Prof.',
              },
              profDr: {
                en: 'Prof. Dr.',
                de: 'Prof. Dr.',
              },
              dr: {
                en: 'Dr.',
                de: 'Dr.',
              },
            },
            salutation: {
              title: {
                en: 'Salutation',
                de: 'Anrede',
              },
              mr: {
                en: 'Mr.',
                de: 'Herr',
              },
              mrs: {
                en: 'Mrs.',
                de: 'Frau',
              },
            },
            firstName: {
              en: 'First name',
              de: 'Vorname',
            },
            lastName: {
              en: 'Last name',
              de: 'Nachname',
            },
            birthday: {
              en: 'Birthday',
              de: 'Geburtsdatum',
            },
            birthdayPlaceholder: {
              en: 'DD.MM.YYYY',
              de: 'TT.MM.JJJJ',
            },
            note: {
              en: 'Note',
              de: 'Notiz',
            },
          },
        },
        phoneSection: {
          title: {
            en: 'Contact',
            de: 'Kontakte',
          },
          fields: {
            email: {
              en: 'E-Mail',
              de: 'E-Mail',
            },
            phoneBusiness: {
              en: 'Company phone',
              de: 'Tel. geschäftlich',
            },
            phoneMobile: {
              en: 'Mobile phone',
              de: 'Tel. mobil',
            },
            phonePrivate: {
              en: 'Private phone',
              de: 'Tel. privat',
            },
            fax: {
              en: 'Fax',
              de: 'Fax',
            },
          },
        },
        addressSection: {
          title: {
            en: 'Address',
            de: 'Adresse',
          },
          mailingAddress: {
            en: 'Mailing address',
            de: 'Postanschrift',
          },
          invoicingAddress: {
            en: 'Invoicing address',
            de: 'Rechnungsanschrift',
          },
          fields: {
            recipient: {
              en: 'Recipient',
              de: 'Empfänger',
            },
            street: {
              en: 'Street',
              de: 'Straße',
            },
            number: {
              en: 'Number',
              de: 'Hausnummer',
            },
            additional: {
              en: 'Additional',
              de: 'Addresszusatz',
            },
            postalCode: {
              en: 'Postal code',
              de: 'PLZ',
            },
            location: {
              en: 'Location',
              de: 'Ort',
            },
            country: {
              title: {
                en: 'Country',
                de: 'Land',
              },
            },
            add: {
              en: 'Add different invoicing address',
              de: 'Abweichende Rechnungsanschrift',
            },
            personDuplicate: {
              en: 'This person exists already in the system. A duplicate cannot be saved.',
              de: 'Diese Person ist im System schon angelegt. Ein Duplikat kann nicht gespeichert werden.',
            },
            companyDuplicate: {
              en: 'This company exists already in the system. A duplicate cannot be saved.',
              de: 'Dieses Unternehmen ist im System schon angelegt. Ein Duplikat kann nicht gespeichert werden.',
            },
          },
        },
        bankSection: {
          title: {
            en: 'Bank connection',
            de: 'Bankverbindung',
          },
          optional: {
            en: 'Optional',
            de: 'Optional',
          },
          fields: {
            owner: {
              en: 'Account owner',
              de: 'Konteninhaber',
            },
            institute: {
              en: 'Bank',
              de: 'Bank',
            },
            iban: {
              en: 'IBAN',
              de: 'IBAN',
            },
            bic: {
              en: 'BIC',
              de: 'BIC',
            },
            addButton: {
              en: 'Add additional bank account',
              de: 'Weiteres Konto hinzufügen',
            },
          },
        },
        contactSection: {
          title: {
            en: 'Contact via',
            de: 'Kontaktieren via',
          },
          dispatchTypes: {
            PORTAL: {
              en: 'Portal',
              de: 'Portal',
            },
            EPOST: {
              en: 'E-POST',
              de: 'E-POST',
            },
            MANUAL: {
              en: 'Manual',
              de: 'Manuell',
            },
          },
        },
      },
      companySection: {
        title: {
          en: 'Company data',
          de: 'Firmendaten (optional)',
        },
        dataSection: {
          title: {
            en: 'Data',
            de: 'Daten',
          },
          fields: {
            company: {
              en: 'Company',
              de: 'Firma',
            },
            role: {
              title: {
                en: 'Role',
                de: 'Rolle (Mehrfachauswahl)',
              },
            },
            add: {
              en: 'Assign additional company',
              de: 'Weitere Firma zuweisen',
            },
          },
        },
      },
    },
    contactDetails: {
      header: {
        edit: {
          en: 'Edit',
          de: 'Bearbeiten',
        },
        delete: {
          en: 'Delete',
          de: 'Löschen',
        },
      },
      contactData: {
        title: {
          en: 'Contact data',
          de: 'Kontaktdaten',
        },
        fields: {
          title: {
            en: 'Title',
            de: 'Titel',
          },
          salutation: {
            en: 'Salutation',
            de: 'Anrede',
          },
          firstName: {
            en: 'First name',
            de: 'Vorname',
          },
          lastName: {
            en: 'Last name',
            de: 'Nachname',
          },
          birthday: {
            en: 'Date of birth',
            de: 'Geburtsdatum',
          },
          email: {
            en: 'E-mail',
            de: 'E-mail',
          },
          officeTel: {
            en: 'Company phone',
            de: 'Tel. geschäftlich',
          },
          mobileTel: {
            en: 'Mobile phone',
            de: 'Tel. mobil',
          },
          privateTel: {
            en: 'Private phone',
            de: 'Tel. privat',
          },
          fax: {
            en: 'Fax',
            de: 'Fax',
          },
          contactVia: {
            en: 'Contact via',
            de: 'Kontaktieren via',
          },
          address: {
            en: 'Address',
            de: 'Addresse',
          },
          mailingAddress: {
            en: 'Mailing address',
            de: 'Postanschrift',
          },
          invoicingAddress: {
            en: 'Invoicing address',
            de: 'Rechnungsanschrift',
          },
          note: {
            en: 'Note',
            de: 'Notiz',
          },
        },
      },
      companyData: {
        title: {
          en: 'Company data',
          de: 'Firmendaten',
        },
        fields: {
          name: {
            en: 'Company name',
            de: 'Firmenname',
          },
          address: {
            en: 'Company Address',
            de: 'Firmenaddresse',
          },
          role: {
            en: 'Role',
            de: 'Rolle',
          },
        },
      },
      bankInformation: {
        title: {
          en: 'Bank Information',
          de: 'Bank Information',
        },
        smallTitle: {
          en: 'Account',
          de: 'Konto',
        },
        fields: {
          accountHolder: {
            en: 'Account holder',
            de: 'Kontoinhaber',
          },
          bank: {
            en: 'Bank',
            de: 'Bank',
          },
          iban: {
            en: 'Iban',
            de: 'Iban',
          },
          bic: {
            en: 'Bic',
            de: 'Bic',
          },
          currency: {
            en: 'Currency',
            de: 'Währung',
          },
        },
      },
      invoicesSection: {
        title: {
          en: 'Invoices',
          de: 'Rechnungen',
        },
        button: {
          en: 'Go to Invoices',
          de: 'Zu den Rechnungen',
        },
      },
      messagesSection: {
        title: {
          en: 'Messages',
          de: 'Nachrichten',
        },
        button: {
          en: 'Go to Messages',
          de: 'Zu den Nachrichten',
        },
      },
      documentsSection: {
        title: {
          en: 'Documents',
          de: 'Dokumente',
        },
        button: {
          en: 'Go to Documents',
          de: 'Zu den Dokumenten',
        },
      },
      paymentsSection: {
        title: {
          en: 'Payments',
          de: 'Zahlungslauf',
        },
        button: {
          en: 'Go to Payments',
          de: 'Zum Zahlungslauf',
        },
      },
    },
    companyEditing: {
      header: {
        titleNew: {
          en: 'Add new company',
          de: 'Neues Unternehmen anlegen',
        },
        titleEdit: {
          en: 'Edit company',
          de: 'Unternehmen bearbeiten',
        },
        cancel: {
          en: 'Cancel',
          de: 'Abbrechen',
        },
        save: {
          en: 'Save',
          de: 'Anlegen',
        },
      },
      contactSection: {
        title: {
          en: 'Contact Data',
          de: 'Kontaktdaten',
        },
        infoSection: {
          title: {
            en: 'Information',
            de: 'Informationen',
          },
          fields: {
            name: {
              en: 'Company name',
              de: 'Firmenname',
            },
            registrationNumber: {
              en: 'Trade register number',
              de: 'Registernummer',
            },
            vatId: {
              en: 'VAT-ID',
              de: 'USt-IdNr',
            },
            website: {
              en: 'Website',
              de: 'Webseite',
            },
            note: {
              en: 'Note',
              de: 'Notiz',
            },
          },
        },
      },
      bankSection: {
        title: {
          en: 'Bank connection',
          de: 'Bankverbindung',
        },
        data: {
          title: {
            en: 'Bank connection',
            de: 'Bankverbindung',
          },
          fields: {
            owner: {
              en: 'Account owner',
              de: 'Konteninhaber',
            },
            institute: {
              en: 'Bank',
              de: 'Bank',
            },
            iban: {
              en: 'IBAN',
              de: 'IBAN',
            },
            bic: {
              en: 'BIC',
              de: 'BIC',
            },
            addButton: {
              en: 'Add additional bank account',
              de: 'Weiteres Konto hinzufügen',
            },
          },
        },
      },
      employeeSection: {
        title: {
          en: 'Employees (optional)',
          de: 'Mitarbeiterzuweisung (optional)',
        },
        data: {
          title: {
            en: 'Data',
            de: 'Daten',
          },
          fields: {
            person: {
              en: 'Person',
              de: 'Person',
            },
            role: {
              en: 'Role',
              de: 'Rolle',
            },
            add: {
              en: 'Add additional person',
              de: 'Weitere Person zuweisen',
            },
          },
        },
      },
    },
    companyDetails: {
      header: {
        edit: {
          en: 'Edit',
          de: 'Bearbeiten',
        },
      },
      companyData: {
        title: {
          en: 'Information',
          de: 'Informationen',
        },
        fields: {
          name: {
            en: 'Company name',
            de: 'Firmenname',
          },
          registerNr: {
            en: 'Trade register number',
            de: 'Registernummer',
          },
          vatId: {
            en: 'VAT ID',
            de: 'USt.IdNr.',
          },
          website: {
            en: 'Homepage',
            de: 'Webseite',
          },
          address: {
            en: 'Address',
            de: 'Adresse',
          },
        },
      },
      employeeTable: {
        title: {
          en: 'Employees',
          de: 'Mitarbeiter',
        },
        addEmployee: {
          en: 'Add employee',
          de: 'Mitarbeiter hinzufügen',
        },
      },
    },
    directDebitList: {
      title: {
        en: 'Direct Debits',
        de: 'Lastschriftzahlungen',
      },
      table: {
        headers: {
          directDebitHrId: {
            en: 'Direct Debit-ID',
            de: 'Lastschriftzahlungs-ID',
          },
          businessPartnerIban: {
            en: 'Business partner IBAN',
            de: 'Geschäftspartner IBAN',
          },
          businessPartnerName: {
            en: 'Business partner name',
            de: 'Geschäftspartner Name',
          },
          createdBy: {
            en: 'Created by',
            de: 'Erstellt von',
          },
          refNr: {
            en: 'Direct debit reference number',
            de: 'Lastschriftzahlungs referenz nummer',
          },
          propertyHrId: {
            en: 'Property-ID',
            de: 'Objekt-ID / Objekt-NR',
          },
          propertyName: {
            en: 'Property name',
            de: 'Objektbezeichnung',
          },
          state: {
            en: 'State',
            de: 'Status',
          },
          executionDate: {
            en: 'Execution date',
            de: 'Ausführungsdatum',
          },
          amount: {
            en: 'Amount',
            de: 'Betrag',
          },
          statement: {
            en: 'Statement',
            de: 'Verwendungszweck',
          },
          counterpartName: {
            en: 'Debitor',
            de: 'Schuldner',
          },
          counterpartIban: {
            en: 'Debitor-iban',
            de: 'Schuldner-Iban',
          },
          creditorAccountName: {
            en: 'Creditor Account Name',
            de: 'Gläubiger',
          },
          propertyIban: {
            en: 'Sender IBAN',
            de: 'Absender IBAN',
          },
          propertyBic: {
            en: 'Sender-BIC',
            de: 'Absender-BIC',
          },
          propertyBankCode: {
            en: 'Bank code',
            de: 'Bankleitzahl',
          },
          propertyAccountInstitute: {
            en: 'Sender Bank',
            de: 'Absender Bank',
          },
          executeButton: {
            en: 'Execute',
            de: 'Jetzt ausführen',
          },
          execute: {
            en: 'Execute',
            de: 'Zahlungslauf',
          },
          createdDate: {
            en: 'Created on',
            de: 'Erstellt am',
          },
          mandateId: {
            en: 'Mandate ID',
            de: 'Mandatsreferenznummer',
          },
          propertyIdInternal: {
            en: 'Property-Nr.',
            de: 'Objekt-Nr.',
          },
          creditorId: {
            en: 'Creditor ID',
            de: 'Gläubiger-ID',
          },
          updatedBy: {
            en: 'Updated by',
            de: 'Aktualisiert von',
          },
          updatedDate: {
            en: 'Updated on',
            de: 'aktualisiert am',
          },
        },
      },
      filter: {
        administrationTypes: {
          en: 'Administration type',
          de: 'Verwaltungsart',
        },
        directDebitHrId: {
          en: 'Direct Debit-ID',
          de: 'Lastschriftzahlungs-ID',
        },
        propertyHrId: {
          en: 'Property-ID',
          de: 'Objekt-ID',
        },
        createdBy: {
          en: 'Created by',
          de: 'Erstellt von',
        },
        supervisorName: {
          en: 'Administrator',
          de: 'Objektbetreuer',
        },
        accountantName: {
          en: 'Accountant',
          de: 'Objektbuchhalter',
        },
        counterpartName: {
          en: 'Business partner name',
          de: 'Geschäftspartner Name',
        },
        counterpartIban: {
          en: 'Business partner IBAN',
          de: 'Geschäftspartner IBAN',
        },
        propertyName: {
          en: 'Property name',
          de: 'Objektbezeichnung',
        },
        state: {
          en: 'State',
          de: 'Status',
        },
        createdDate: {
          en: 'Created on',
          de: 'Erstellt am',
        },
        createdOnFrom: {
          en: 'Created from',
          de: 'Erstellt von',
        },
        createdOnTo: {
          en: 'Created until',
          de: 'Erstellt bis',
        },
        minExecutionDate: {
          en: 'Execution date from',
          de: 'Ausführungsdatum von',
        },
        maxExecutionDate: {
          en: 'Execution date until',
          de: 'Ausführungsdatum bis',
        },
        minAmount: {
          en: 'Amount from',
          de: 'Betrag von',
        },
        maxAmount: {
          en: 'Amount to',
          de: 'Betrag bis',
        },
        amount: {
          en: 'Amount',
          de: 'Betrag',
        },
        statement: {
          en: 'Statement',
          de: 'Verwendungszweck',
        },
        propertyIdInternalList: {
          en: 'Property-Nr.',
          de: 'Objekt-Nr.',
        },
        propertyIban: {
          en: 'Sender IBAN',
          de: 'Absender IBAN',
        },
        propertyBic: {
          en: 'Sender BIC',
          de: 'Absender BIC',
        },
        propertyAccountInstitute: {
          en: 'Sender Bank',
          de: 'Absender Bank',
        },
        managementCompany: {
          en: 'Management company',
          de: 'Verwaltungsfirma',
        },
      },
    },
    accountBooking: {
      title: {
        en: 'Account booking',
        de: 'Sachkonten-Buchung',
      },
      header: {
        book: {
          en: 'Book',
          de: 'Buchen',
        },
        cancel: {
          en: 'Cancel',
          de: 'Abbrechen',
        },
      },
      form: {
        booking: {
          en: 'The posting',
          de: 'Die Buchung',
        },
        accountDebit: {
          en: 'Account-Debit',
          de: 'Konto-Soll',
        },
        accountCredit: {
          en: 'Account-Credit',
          de: 'Konto-Haben',
        },
        amount: {
          en: 'Amount',
          de: 'Betrag',
        },
        addVat: {
          en: 'Add VAT',
          de: 'MwSt hinzufügen',
        },
        removeVat: {
          en: 'Remove VAT',
          de: 'MwSt verbergen',
        },
        laborCost: {
          en: 'Labor cost',
          de: 'Lohnkosten',
        },
        addLaborCost: {
          en: 'Add labor costs',
          de: 'Lohnanteil',
        },
        removeLaborCost: {
          en: 'Remove labor costs',
          de: 'Lohnkosten verbergen',
        },
        laborCostType: {
          en: 'Labor cost type',
          de: 'Lohnkostenart',
        },
        laborCostTypes: {
          HOUSEHOLD_RELATED_SERVICES: {
            en: 'Household related services',
            de: 'Haushaltsnahe DL',
          },
          TECHNICIAN_SERVICE: {
            en: 'Technician service',
            de: 'Dl.anteil Handwerkerrechnungen',
          },
          MARGINAL_EMPLOYMENT: {
            en: 'Marginal employment',
            de: 'Geringf. Beschäftigung',
          },
          INSURABLE_EMPLOYMENT: {
            en: 'Insurable employment',
            de: 'SV.pfl. Beschäftigung',
          },
        },
        account: {
          en: 'Account',
          de: 'Sachkonto',
        },
        bookingText: {
          en: 'Booking text',
          de: 'Buchungstext',
        },
        overwriteBookingDate: {
          en: 'Overwrite booking date',
          de: 'Buchungsdatum überschreiben',
        },
        bookingDate: {
          en: 'Booking date',
          de: 'Buchungsdatum',
        },
        splitAmount: {
          en: 'Add new booking',
          de: 'Neue Buchung hinzufügen',
        },
        applyRuleInFuture: {
          en: 'From now on use this schema for all similar transactions.',
          de: 'Buchungslogik ab sofort für alle gleichartigen Transaktionen anwenden.',
        },
        applyRuleForPast: {
          en: 'Execute this schema for all similar und unmatched transactions in the past (same economic year)',
          de: 'Buchungslogik für gleichartige, nicht zugewiesene und zurückliegende Transaktionen im aktuellen Wirtschaftsjahr anwenden.',
        },
        amountLabel: {
          en: 'VAT amount',
          de: 'MwSt Betrag',
        },
        percentageLabel: {
          en: 'VAT in %',
          de: 'MwSt in %',
        },
        netAmountLabel: {
          en: 'Net amount',
          de: 'Nettobetrag',
        },
        accountArticleLabel: {
          en: 'An overview of all accounts is provided in this support article.',
          de: 'Eine Übersicht über alle Konten finden Sie in diesem Support Artikel.',
        },
        vatEligibilityPercentage: {
          en: 'Eligible VAT share',
          de: 'Anteil anrechenbare USt.',
        },
        vatEligibilityAmount: {
          en: 'Eligible VAT amount',
          de: 'Anrechenbare USt.',
        },
        vatEligibilityTooltip: {
          en: 'No pre-filled values as here creditor and debitor bookings are possible.',
          de: 'Kein vorausgewählter Wert vorhanden, da hier Kreditor- und Debitorbuchungen möglich sind.',
        },
        vatEligibilityTooltipLink: {
          en: 'Learn more in our support article.',
          de: 'Erfahren Sie mehr in unserem Supportartikel.',
        },
      },
    },
    templateEditor: {
      title: {
        en: 'Template',
        de: 'Vorlage',
      },
      save: {
        en: 'Save',
        de: 'Speichern',
      },
    },
  },
  pdfViewer: {
    withdraw: {
      en: 'Withdraw',
      de: 'Abheben',
    },
    safeArea: {
      en: 'Keep content within the safe area.',
      de: 'Halten Sie den Inhalt innerhalb des Sicherheitsbereichs.',
    },
  },
  notifications: {
    noChangeMsg: {
      message: {
        en: 'Nothing changed',
        de: 'Nichts hat sich verändert',
      },
    },
    pdfViewer: {
      failedToDisplay: {
        en: 'Failed to display document.',
        de: 'Fehler bei der Anzeige des Dokuments.',
      },
      failedToLoad: {
        en: 'Failed to load document.',
        de: 'Fehler beim Laden des Dokuments.',
      },
    },
    failedToDownloadPdf: {
      message: {
        en: 'Failed to download document because of validation errors',
        de: 'Fehler beim Herunterladen des Dokuments aufgrund von Validierungsfehlern',
      },
      invalidDataMessage: {
        en: 'Failed to download document because of validation errors',
        de: 'Fehler beim Herunterladen des Dokuments aufgrund von Validierungsfehlern',
      },
    },
    accountContext: {
      loadAccountError: {
        message: {
          en: 'Account loading error',
          de: 'Fehler beim Laden des Kontos',
        },
        description: {
          en: 'Could not load accounts',
          de: 'Konten konnten nicht geladen werden',
        },
      },
    },
    authContext: {
      forbiddenOperation: {
        message: {
          en: 'Limited Access',
          de: 'Eingeschränkter Zugriff',
        },
        description: {
          en: 'You are not authorized to perform this operation. Please contact your domain administrator.',
          de: 'Sie haben keine Berechtigung, diesen Vorgang auszuführen. Bitte wenden Sie sich an Ihren Administrator.',
        },
      },
      tokenExpired: {
        message: {
          en: 'Your session has expired',
          de: 'Ihre Sitzung ist abgelaufen',
        },
        description: {
          en: 'Please login again.',
          de: 'Bitte melden Sie sich erneut an.',
        },
      },
      loginError: {
        message: {
          en: 'Authentication error.',
          de: 'Authentifizierungsfehler.',
        },
        description: {
          en: 'Please make sure you are using the correct domain, email address and password.',
          de: 'Bitte stellen Sie sicher, dass Sie die richtige Domain, E-Mail Adresse und das richtige Passwort verwenden.',
        },
      },
    },
    templateContext: {
      loadError: {
        message: {
          en: 'Template could not be found.',
          de: 'Vorlage konnte nicht gefunden werden.',
        },
      },
      saveError: {
        message: {
          en: 'Template could not be saved.',
          de: 'Vorlage konnte nicht gespeichert werden.',
        },
      },
      saveSuccess: {
        message: {
          en: 'Template saved.',
          de: 'Vorlage wurde gespeichert.',
        },
      },
    },
    contactEditingContext: {
      loadError: {
        message: {
          en: 'Contact could not be found.',
          de: 'Kontakt konnte nicht gefunden werden.',
        },
        description: {
          en: 'Cannot load Contact.',
          de: 'Kontakt kann nicht geladen werden.',
        },
      },
      saveError: {
        message: {
          en: 'Contact could not be saved.',
          de: 'Kontakt konnte nicht gespeichert werden.',
        },
        description: {
          en: 'Cannot save Contact.',
          de: 'Kontakt kann nicht gespeichert werden.',
        },
      },
      validationError: {
        message: {
          en: 'There are validation errors.',
          de: 'Es sind Validierungsfehler aufgetreten.',
        },
        differentSection: {
          en: 'There are validation errors in a different section.',
          de: 'Es sind Validierungsfehler in einem anderen Sektion aufgetreten.',
        },
      },
      editError: {
        message: {
          en: 'Contact could not be saved.',
          de: 'Kontakt konnte nicht gespeichert werden.',
        },
        description: {
          en: 'Cannot edit Contact.',
          de: 'Kontakt kann nicht bearbeitet werden.',
        },
      },
      success: {
        message: {
          en: 'Saved successfully',
          de: 'Erfolgreich gespeichert',
        },
      },
    },
    serviceCompanyEditingContext: {
      loadError: {
        message: {
          en: 'Service company could not be found.',
          de: 'Dienstleistungsunternehmen konnte nicht gefunden werden.',
        },
        description: {
          en: 'Cannot load Service company.',
          de: 'Dienstleistungsunternehmen kann nicht geladen werden.',
        },
      },
      saveError: {
        message: {
          en: 'Service company could not be saved.',
          de: 'Dienstleistungsunternehmen konnte nicht gespeichert werden.',
        },
        description: {
          en: 'Cannot save Service company.',
          de: 'Dienstleistungsunternehmen kann nicht gespeichert werden.',
        },
      },
      editError: {
        message: {
          en: 'Service company could not be edited.',
          de: 'Dienstleistungsunternehmen konnte nicht bearbeitet werden.',
        },
        description: {
          en: 'Cannot edit Service company.',
          de: 'Dienstleistungsunternehmen kann nicht bearbeitet werden.',
        },
      },
      nameError: {
        message: {
          en: 'Service company could not be saved.',
          de: 'Dienstleistungsunternehmen konnte nicht gespeichert werden.',
        },
        description: {
          en: 'There is already a service company with that name.',
          de: 'Es gibt schon einen Dienstleistungsunternehmen mit dieser Name.',
        },
      },
    },
    managementCompaniesContext: {
      loadError: {
        message: {
          en: 'Loading error.',
          de: 'Ladefehler.',
        },
        description: {
          en: 'Could not load management companies.',
          de: 'Verwaltungsunternehmen konnten nicht geladen werden.',
        },
      },
    },
    invoiceEditingContext: {
      loadError: {
        message: {
          en: 'Invoice could not be found.',
          de: 'Rechnung konnte nicht gefunden werden.',
        },
        description: {
          en: 'Cannot load invoice.',
          de: 'Rechnung kann nicht geladen werden.',
        },
      },
      uploadError: {
        message: {
          en: 'Upload failed',
          de: 'Upload fehlgeschlagen',
        },
        description: {
          en: 'Something went wrong during upload.',
          de: 'Beim Hochladen ist ein Fehler aufgetreten.',
        },
      },
      createError: {
        message: {
          en: 'Create failed',
          de: 'Erstellung fehlgeschlagen',
        },
      },
      updateError: {
        message: {
          en: 'Update failed',
          de: 'Update fehlgeschlagen',
        },
        description: {
          en: 'Cannot update invoice',
          de: 'Rechnung kann nicht aktualisiert werden',
        },
      },
      updateValidationError: {
        message: {
          en: 'Updated. There are validation errors.',
          de: 'Aktualisiert. Es sind Validierungsfehler aufgetreten.',
        },
        description: {
          en: 'Please check field validations before marking the invoice Ready for payment.',
          de: 'Bitte überprüfen Sie die Eingabefelder, bevor Sie die Rechnung in den Zahlungslauf geben.',
        },
      },
      duplicateInvoiceError: {
        message: {
          en: 'This invoice is a duplicate of invoice',
          de: 'Diese Rechnung ist ein Duplikat der Rechnung',
        },
      },
      updateSuccess: {
        message: {
          en: 'Invoice update successful.',
          de: 'Daten erfolgreich gespeichert.',
        },
      },
      invoiceApproveSuccess: {
        message: {
          en: 'Payment(s) successfully created',
          de: 'Zahlung(en) erfolgreich erstellt',
        },
      },
      invoiceApproveError: {
        validationError: {
          message: {
            en: 'Approval failed',
            de: 'Freigabe fehlgeschlagen',
          },
          description: {
            en: 'Please fill out the required fields',
            de: 'Bitte füllen Sie die nötigen Felder aus',
          },
        },
        generalError: {
          message: {
            en: 'Approval failed',
            de: 'Freigabe fehlgeschlagen',
          },
          description: {
            en: 'Something went wrong while trying to approve',
            de: 'Beim Genehmigen ist ein Fehler aufgetreten',
          },
        },
      },
      invalidPaymentTypeWithoutCounterpartIban: {
        en: 'Invoices without counterpart bank account can only be created with no payments or standing orders without payments.',
        de: 'Rechnungen ohne Bankkonto des Rechnungsstellers können nur angelegt werden, wenn ‘Keine Zahlung’ oder ‘Dauerauftrag ohne Zahlung’ ausgewählt wurde.',
      },
      invalidPaymentStatement: {
        en: 'Payment statement is too long, must contain a maximum amount of 255 characters.',
        de: 'Verwendungszweck ist zu lang, er darf maximal 255 Zeichen beinhalten.',
      },
    },
    invoiceListContext: {
      loadError: {
        message: {
          en: 'Resource loading error',
          de: 'Fehler beim Laden der Ressource',
        },
        description: {
          en: 'Cannot load invoices.',
          de: 'Rechnungen können nicht geladen werden.',
        },
      },
      deleteError: {
        message: {
          en: 'Cannot delete invoice.',
          de: 'Rechnung kann nicht gelöscht werden.',
        },
      },
      deleteSuccess: {
        message: {
          en: 'Invoice deleted successfully',
          de: 'Rechnung wurde erfolgreich gelöscht.',
        },
      },
      rejectionError: {
        message: {
          en: 'Failed to reject invoices',
          de: 'Rechnungen konnten nicht abgelehnt werden',
        },
        description: {
          en: 'Related payments are not in NEW state',
          de: 'Verwandte Zahlungen sind nicht im NEUEN Status',
        },
      },
      rejectionSuccess: {
        message: {
          en: 'Invoices rejected successfully',
          de: 'Rechnungen werden erfolgreich abgelehnt',
        },
      },
      deletionError: {
        message: {
          en: 'Failed to delete invoices',
          de: 'Rechnungen konnten nicht gelöscht werden',
        },
        description: {
          en: 'Related payments are not in NEW state',
          de: 'Verwandte Zahlungen sind nicht im NEUEN Status',
        },
      },
      deletionSuccess: {
        message: {
          en: 'Invoices deleted successfully',
          de: 'Rechnungen werden erfolgreich gelöscht.',
        },
      },
      revertSuccess: {
        en: 'Invoice reverted successfully',
        de: 'Rechnung erfolgreich storniert.',
      },
      revertError: {
        en: 'Failed to revert invoice',
        de: 'Rechnung konnte nicht storniert werden.',
      },
      stoppedSuccess: {
        en: 'Invoice stopped recurring successfully',
        de: 'Wiederkehrende Rechnung erfolgreich beendet.',
      },
      stoppedError: {
        en: 'Failed to stop recurring invoice',
        de: 'Wiederkehrende Rechnung konnte nicht gestoppt werden.',
      },
      copySuccess: {
        en: 'Copy of invoice successfully created',
        de: 'Rechnung erfolgreich kopiert.',
      },
      copyError: {
        en: 'Failed to copy invoice',
        de: 'Rechnung konnte nicht kopiert werden.',
      },
    },
    paymentCreationContext: {
      saveError: {
        message: {
          en: 'Payment could not be saved.',
          de: 'Überweisung konnte nicht gespeichert werden.',
        },
        description: {
          en: 'Cannot save payment.',
          de: 'Überweisung kann nicht gespeichert werden.',
        },
      },
      saveValidationError: {
        message: {
          en: 'Please fill all required fields.',
          de: 'Bitte alle notwendigen Felder ausfüllen.',
        },
        description: {
          en: 'Cannot save payment.',
          de: 'Überweisung kann nicht gespeichert werden.',
        },
      },
      saveSuccess: {
        message: {
          en: 'The payment was created successfully.',
          de: 'Die Zahlung wurde erfolgreich erstellt.',
        },
        description: {
          en: 'The payment is in the payment run list.',
          de: 'Die Zahlung befindet sich in der Zahlungslaufliste.',
        },
      },
    },
    postingListContext: {
      loadError: {
        message: {
          en: 'Error loading postings',
          de: 'Fehler beim laden von Sachkontenbuchungen',
        },
      },
      deleteError: {
        message: {
          en: 'Posting deletion failed',
          de: 'Buchung konnte nicht gelöscht werden',
        },
      },
      deleteSuccess: {
        message: {
          en: 'Posting was deleted',
          de: 'Buchung wurde erfolgreich gelöscht',
        },
      },
      revertError: {
        message: {
          en: 'Posting reversion failed',
          de: 'Buchung konnte nicht storniert werden',
        },
      },
      revertSuccess: {
        message: {
          en: 'Posting was reversed',
          de: 'Buchung wurde erfolgreich storniert',
        },
      },
    },
    standingOrderContext: {
      loadError: {
        message: {
          en: 'Resource loading error',
          de: 'Fehler beim Laden der Ressource',
        },
        description: {
          en: 'Cannot load standing orders.',
          de: 'Daueraufträge können nicht geladen werden.',
        },
      },
      saveSuccess: {
        message: {
          en: 'The standing order was created successfully.',
          de: 'Der Dauerauftrag wurde erfolgreich angelegt.',
        },
        description: {
          en: 'The first execution is',
          de: 'Die erste Ausführung erfolgt',
        },
      },
      updateSuccess: {
        message: {
          en: 'The standing order was updated successfully.',
          de: 'Der Dauerauftrag wurde erfolgreich aktualisiert.',
        },
        description: {
          en: 'The first execution is',
          de: 'Die erste Ausführung erfolgt',
        },
      },
      stopSuccess: {
        message: {
          en: 'The standing order was stopped successfully.',
          de: 'Der Dauerauftrag wurde erfolgreich abgebrochen.',
        },
      },
      stopError: {
        message: {
          en: 'Failed to stop standing order',
          de: 'Dauerauftrag konnte nicht abgebrochen werden',
        },
      },
      saveError: {
        message: {
          en: 'Standing order could not be saved.',
          de: 'Dauerauftrag konnte nicht gespeichert werden.',
        },
        description: {
          en: 'Cannot save standing order.',
          de: 'Dauerauftrag kann nicht gespeichert werden.',
        },
      },
      saveValidationError: {
        message: {
          en: 'Please fill all required fields.',
          de: 'Bitte alle notwendigen Felder ausfüllen.',
        },
        description: {
          en: 'Cannot save standing order.',
          de: 'Dauerauftrag kann nicht gespeichert werden.',
        },
      },
    },
    assignDebitContext: {
      success: {
        en: 'Assignment successful',
        de: 'Überweisung zugewiesen',
      },
      saveError: {
        en: 'Booking could not be made',
        de: 'Buchung konnte nicht gemacht werden',
      },
    },
    directDebitMandateListContext: {
      loadError: {
        message: {
          en: 'Resource loading error.',
          de: 'Fehler beim Laden der Ressource.',
        },
        description: {
          en: 'Cannot load direct debit mandates.',
          de: 'Lastschriftmandaten können nicht geladen werden.',
        },
      },
    },
    accountBalancesListContext: {
      propertyLoadError: {
        message: {
          en: 'Error loading properties.',
          de: 'Objekte konnten nicht geladen werden.',
        },
      },
      accountBalancesLoadError: {
        message: {
          en: 'Error loading account balances.',
          de: 'Kontostand konnte nicht geladen werden.',
        },
      },
    },
    propertyEditingContext: {
      loadError: {
        message: {
          en: 'Resource loading error.',
          de: 'Fehler beim Laden der Ressource.',
        },
        description: {
          en: 'Cannot load property.',
          de: 'Objekt kann nicht geladen werden.',
        },
      },
      contractLoadError: {
        en: 'Cannot load contract',
        de: 'Vertrag kann nicht geladen werden',
      },
      saveError: {
        message: {
          en: 'Property could not be saved.',
          de: 'Objekt konnte nicht gespeichert werden.',
        },
        description: {
          en: 'Cannot save property.',
          de: 'Objekt kann nicht gespeichert werden.',
        },
      },
      duplicateNameError: {
        en: (name: string) => `Property with name ${name} already exists.`,
        de: (name: string) => `Objekt mit doppelter Name ${name} ist bereits vorhanden.`,
      },
      saveBankAccountError: {
        en: (iban: string) => `Bank account ${iban} can not be deleted.`,
        de: (iban: string) => `Bankkonto ${iban} kann nicht gelöscht werdedn.`,
      },
      saveValidationError: {
        message: {
          en: 'Saved property with missing or invalid fields.',
          de: 'Objekt wurde mit fehlenden oder nicht validen Werten gespeichert.',
        },
        description: {
          en: 'Please check field validations before marking the property Ready for Invoice',
          de: 'Bitte überprüfen Sie die Eingabefelder, bevor Sie das Objekt bebuchen.',
        },
      },
      saveSuccess: {
        message: {
          en: 'Property saved successfully.',
          de: 'Objekt erfolgreich gespeichert.',
        },
      },
      offBoardError: {
        message: {
          en: 'An error occurred.',
          de: 'Ein Fehler ist aufgetreten.',
        },
        description: {
          en: 'Property could not be offboarded.',
          de: 'Objekt konnte nicht deaktiviert werden.',
        },
      },
      offBoardSuccess: {
        message: {
          en: 'Success',
          de: 'Erfolg.',
        },
        description: {
          en: 'Property was offboarded successfully.',
          de: 'Objekt wurde erfolgreich deaktiviert.',
        },
      },
      accountSubdivisionWarning: {
        part1: {
          en: 'Bookings from account ',
          de: 'Buchungen aus Konto ',
        },
        part2: {
          en: ' will be moved to its subaccount.',
          de: ' werden in das erstellte Unterkonto verschoben.',
        },
      },
    },
    propertyListContext: {
      loadError: {
        message: {
          en: 'Resource loading error.',
          de: 'Fehler beim Laden der Ressource.',
        },
        description: {
          en: 'Cannot load properties.',
          de: 'Objekte können nicht geladen werden.',
        },
      },
      deleteError: {
        message: {
          en: 'Property could not be deleted.',
          de: 'Objekt konnte nicht gelöscht werden.',
        },
      },
      deletePropertyWithContractError: {
        en: 'Property with contracts cannot be deleted.',
        de: 'Objekt mit Verträgen können nicht gelöscht werden.',
      },
      deleteSuccess: {
        message: {
          en: 'Property successfully deleted.',
          de: 'Objekt erfolgreich gelöscht.',
        },
      },
    },
    hgaContext: {
      loadError: {
        message: {
          en: 'Cannot load House money settlement.',
          de: 'HGA können nicht geladen werden.',
        },
      },
    },
    paymentListContext: {
      loadError: {
        message: {
          en: 'Resource loading error',
          de: 'Fehler beim Laden der Ressource',
        },
        description: {
          en: 'Cannot load payments.',
          de: 'Zahlungen können nicht geladen werden.',
        },
      },
      uploadError: {
        message: {
          en: 'Failed uploading file',
          de: 'Fehler beim Hochladen der Datei',
        },
        description: {
          en: 'Cannot upload file. Please try again.',
          de: 'Datei kann nicht hochgeladen werden. Bitte versuche es erneut.',
        },
      },
      rejectionError: {
        message: {
          en: 'Failed to reject payments',
          de: 'Zahlungen konnten nicht abgelehnt werden',
        },
        description: {
          en: 'Related payments are not in NEW state',
          de: 'Verwandte Zahlungen sind nicht im NEUEN Status',
        },
      },
      rejectionSuccess: {
        message: {
          en: 'Payments rejected successfully.',
          de: 'Zahlungen werden erfolgreich abgelehnt.',
        },
      },
      deletionError: {
        message: {
          en: 'Failed to delete payments',
          de: 'Zahlungen konnten nicht gelöscht werden',
        },
        description: {
          en: 'Related payments are not in NEW state',
          de: 'Verwandte Zahlungen sind nicht im NEUEN Status',
        },
      },
      deletionSuccess: {
        message: {
          en: 'Payments deleted successfully.',
          de: 'Zahlungen werden erfolgreich gelöscht.',
        },
      },
      stateChange: {
        success: {
          en: 'Status changed successfully',
          de: 'Status erfolgreich verändert',
        },
        error: {
          en: 'An error occurred',
          de: 'Ein Fehler ist aufgetreten',
        },
      },
      executeErrorUpdateInProgress: {
        description: {
          en: 'The account is currently locked as it is in the process of download.',
          de: 'Ausführung nicht möglich, da aktuell noch Transaktionen abgerufen werden.',
        },
      },
      loadDownloadDataError: {
        message: {
          en: 'Failed loading information about the payments',
          de: 'Fehler beim Laden der Informationen zu den Zahlungen',
        },
        description: {
          en: 'There are some payments which are not in NEW state',
          de: 'Es gibt einige Zahlungen, die nicht im NEUEN  Status sind',
        },
      },
    },
    directDebitListContext: {
      loadError: {
        message: {
          en: 'Resource loading error',
          de: 'Fehler beim Laden der Ressource',
        },
        description: {
          en: 'Cannot load direct debits.',
          de: 'Lastschriftzahlungen können nicht geladen werden.',
        },
      },
      executeError: {
        message: {
          en: 'Error executing direct debit.',
          de: 'Fehler beim Ausführen der Lastschriftzahlung.',
        },
      },
    },
    bankAccountListContext: {
      loadError: {
        message: {
          en: 'Error loading bank accounts',
          de: 'Fehler beim Laden der Bankkonten',
        },
      },
    },
    accountSalesListContext: {
      accountLoadError: {
        message: {
          en: 'Error loading account sales',
          de: 'Fehler beim Laden des Kontoverkaufs',
        },
      },
      toAssignLoadError: {
        message: {
          en: 'Could not load number of unassigned transactions',
          de: 'Anzahl nicht zugeordneter Transaktionen konnte nicht geladen werden',
        },
      },
    },
    bankTransactionListContext: {
      message: {
        en: 'Error loading transactions',
        de: 'Fehler beim Laden der Überweisungen',
      },
      transactionAssignSuccess: {
        en: 'Transaction assigned successfully',
        de: 'Überweisung erfolgreich zugewiesen',
      },
      transactionAssignError: {
        en: 'Failed to assign transaction',
        de: 'Überweisung konnte nicht zugewiesen werden',
      },
      transactionLoadError: {
        en: 'Failed to load transaction',
        de: 'Fehler beim Laden der Überweisung',
      },
    },
    contactListContext: {
      loadError: {
        message: {
          en: 'Resource loading error',
          de: 'Fehler beim Laden der Ressource',
        },
        description: {
          en: 'Cannot load contacts.',
          de: 'Kontaktekönnen nicht geladen werden.',
        },
      },
    },
    serviceCompanyListContext: {
      loadError: {
        message: {
          en: 'Resource loading error',
          de: 'Fehler beim Laden der Ressource',
        },
        description: {
          en: 'Cannot load service companies.',
          de: 'Dienstleistungsunternehmen nicht geladen werden.',
        },
      },
    },
    genericContext: {
      loadError: {
        message: {
          en: 'Resource loading error',
          de: 'Fehler beim Laden der Ressource',
        },
      },
    },
    bankConnectionContext: {
      loadError: {
        message: {
          en: 'Resource loading error',
          de: 'Fehler beim Laden der Ressource',
        },
        description: {
          en: 'Cannot load bank connections.',
          de: 'Fehler beim Laden von Bankverbindungen.',
        },
      },
      updateSuccess: {
        message: {
          en: 'Connection updated successfully',
          de: 'Verbindung erfolgreich aktualisiert',
        },
      },
      updateError: {
        message: {
          en: 'Could not update the connection',
          de: 'Fehler beim Aktualisieren der Bankverbindung',
        },
      },
    },
    unitListContext: {
      loadError: {
        message: {
          en: 'Resource loading error',
          de: 'Fehler beim Laden der Ressource',
        },
        description: {
          en: 'Cannot load units.',
          de: 'Einheiten können nicht geladen werden.',
        },
      },
    },
    assignCreditContext: {
      saveError: {
        en: 'Booking could not be made',
        de: 'Buchung konnte nicht gemacht werden',
      },
      incomplete: {
        en: 'Please fill out the required fields',
        de: 'Bitte füllen Sie die nötigen Felder aus',
      },
      success: {
        en: 'Assignment successful',
        de: 'Überweisung zugewiesen',
      },
    },
    distributionKeyContext: {
      loadError: {
        message: {
          en: 'Resource loading error.',
          de: 'Fehler beim Laden der Ressource.',
        },
        description: {
          en: 'Cannot load distribution key.',
          de: 'Verteilerschlüssel kann nicht geladen werden.',
        },
      },
      updateSuccess: {
        message: {
          en: 'Distribution key updated successfully',
          de: 'Verteilerschlüssel erfolgreich aktualisiert',
        },
      },
      saveError: {
        message: {
          en: 'Distribution key could not be saved.',
          de: 'Verteilerschlüssel konnte nicht gespeichert werden.',
        },
        description: {
          en: 'Cannot save distribution key.',
          de: 'Verteilerschlüssel kann nicht gespeichert werden.',
        },
      },
      validationError: {
        message: {
          en: 'Distribution key could not be saved.',
          de: 'Verteilerschlüssel konnte nicht gespeichert werden.',
        },
        checkboxLogic: {
          en: 'Please check apportioned only if "HGA" is selected.',
          de: 'Bitte nur dann zuteilen, wenn "HGA" ausgewählt ist.',
        },
        description1: {
          en: 'The sum of house money settlement percentages is ',
          de: 'Die Hausgeldabrechnungprozentsumme ist ',
        },
        description2: {
          en: ' The sum of economic plan percentages is ',
          de: ' Die Wirtschaftsplanprozentsumme ist ',
        },
      },
    },
    accountsBalanceContext: {
      loadError: {
        en: 'Failed to load account balances',
        de: 'Laden der Sachkonten fehlgeschlagen',
      },
    },
    resolutionRecordContext: {
      loadError: {
        en: 'Failed to load resolutions',
        de: 'Fehler beim Laden der Auflösungen',
      },
      saveValidationError: {
        message: {
          en: 'There are validation errors.',
          de: 'Es sind Validierungsfehler aufgetreten.',
        },
      },
      saveError: {
        message: {
          en: 'Resolution record could not be saved',
          de: 'Beschluss konnte nicht gespeichert werden',
        },
      },
    },
    houseMoneySettlementContext: {
      loadHouseMoneySettlementError: {
        message: {
          en: 'Failed to load house money settlement',
          de: 'Fehler beim Laden der HGA',
        },
      },
      houseMoneySettlementNotFound: {
        en: 'House money settlement not found.  Please try to recreate it.',
        de: 'HGA nicht gefunden. Versuchen Sie bitte eine erneute Erstellung.',
      },
      statusChangeError: {
        en: 'Failed to change status to: ',
        de: 'Status konnte nicht verändert werden zum: ',
      },
      status: {
        DRAFT: {
          en: 'Draft',
          de: 'Entwurf',
        },
        PREPARED: {
          en: 'Prepared',
          de: 'Vorbereitet',
        },
        FINALIZED: {
          en: 'Finalized',
          de: 'Final',
        },
      },
    },
    accountBookingContext: {
      saveSuccess: {
        message: {
          en: 'Bookings created successfully.',
          de: 'Buchungen erfolgreich erstellt.',
        },
      },
      saveError: {
        message: {
          en: 'Bookings could not be created',
          de: 'Buchungen konnten nicht erstellt werden',
        },
      },
      validationError: {
        message: {
          en: 'Missing or invalid fields',
          de: 'Fehlende oder nicht valide Werte',
        },
      },
      loadAccountError: {
        messageNotFound: {
          en: ' account not found',
          de: ' Konto nicht gefunden',
        },
        messageAccountMissing: {
          en: ' account not found',
          de: ' Konto nicht gefunden',
        },
      },
    },
    propertyContext: {
      loadError: {
        message: {
          en: 'Resource loading error.',
          de: 'Fehler beim Laden der Ressource.',
        },
        description: {
          en: 'Cannot load property.',
          de: 'Objekt kann nicht geladen werden.',
        },
      },
    },
    ownerChangeHook: {
      saveSuccess: {
        message: {
          en: 'New owner saved successfully',
          de: 'Neuer Eigentümer erfolgreich gespeiert',
        },
        description: {
          en: 'New owner takes over on ',
          de: 'Neuer Eigentümer übernimmt an',
        },
      },
      validationError: {
        message: {
          en: 'Missing or invalid fields',
          de: 'Fehlende oder nicht valide Werte',
        },
      },
      changeDateValidationError: {
        message: {
          en: 'Owner change date can not be in the past',
          de: 'Eigentümerwechsel darf nicht in der Vergangenheit liegen',
        },
      },
      saveError: {
        message: {
          en: 'Owner could not be changed',
          de: 'Eigentümer konnte nicht geändert werden ',
        },
        'owner.change.in.execution.for.unit': {
          en: 'Other owner change in progress for current unit.',
          de: 'Anderer Besitzer wechselt für die aktuelle Einheit.',
        },
      },
      sendOutError: {
        message: {
          en: 'Could not send out letter.',
          de: 'Brief konnte nicht gesendet werden.',
        },
      },
      sendSuccess: {
        message: {
          en: 'Letter sent out successfully.',
          de: 'Brief erfolgreich versendet.',
        },
      },
      loadExecutedDirectDebitsError: {
        message: {
          en: 'Cannot load executed direct debits.',
          de: 'Ausgeführte Lastschriftzahlungen können nicht geladen werden.',
        },
      },
    },
    watermarkHook: {
      error: {
        en: 'Failed to load watermarks',
        de: 'Briefpapiere konnten nicht geladen werden',
      },
      noWatermark: {
        en: 'No watermark',
        de: 'Kein Briefpapier anwenden',
      },
      defaultWatermark: {
        en: 'Default',
        de: 'Standard'
      }
    },
    usePropertyList: {
      loadError: {
        message: {
          en: 'Resource loading error',
          de: 'Fehler beim Laden der Ressource',
        },
        description: {
          en: 'Cannot load properties.',
          de: 'Objekte können nicht geladen werden.',
        },
      },
    },
    useCoverLetterTemplateOptions: {
      loadError: {
        message: {
          en: 'Failed to load invitation letter',
          de: 'Fehler beim laden des Einladungsanschreiben',
        },
      },
    },
    loadAccountDistributionKeyError: {
      message: {
        en: 'Cannot load distribution key.',
        de: 'Verteilerschlüssel kann nicht geladen werden.',
      },
    },
  },
  validations: {
    defaultError: {
      en: 'Field is incorrect.',
      de: 'Das Feld ist falsch.',
    },
    mandatory: {
      en: 'Field is required',
      de: 'Feld ist erforderlich.',
    },
    notNull: {
      en: 'Field is required',
      de: 'Feld ist erforderlich.',
    },
    notEmpty: {
      en: 'Field is required',
      de: 'Feld ist erforderlich.',
    },
    postalCodeSize: {
      en: 'Field is invalid. Size must be 5.',
      de: 'Postleitzahl muss fünfstellig sein.',
    },
    tooSmall: {
      en: 'Field is invalid. Value must be higher.',
      de: 'Das Feld ist ungültig. Wert muss höher sein.',
    },
    tooBig: {
      en: 'Value must be smaller.',
      de: 'Wert muss kleiner sein.',
    },
    invalidIbanPattern: {
      en: 'Invalid IBAN.',
      de: 'Ungültige IBAN.',
    },
    duplicateIban: {
      en: 'IBAN already used.',
      de: 'IBAN bereits verwendet.',
    },
    duplicateBankAccountId: {
      en: 'Bank Account ID already used.',
      de: 'Bankkonto-ID bereits verwendet.',
    },
    isDeleted: {
      en: 'Entity is deleted.',
      de: 'Entität wird gelöscht.',
    },
    invalidBicPattern: {
      en: 'Invalid BIC.',
      de: 'Ungültige BIC.',
    },
    invalidDirectDebitMandatePattern: {
      en: 'Invalid Mandate ID pattern.',
      de: 'Ungültiges Mandatsreferenz-format.',
    },
    dateMustBeInFuture: {
      en: 'Date must be in future',
      de: 'Datum muss in der Zukunft liegen.',
    },
    dateMustBeInPast: {
      en: 'Date must be in past',
      de: 'Datum muss in der Vergangenheit liegen.',
    },
    invalidDateRange: {
      en: 'Invalid date range.',
      de: 'Ungültiger Datumsbereich.',
    },
    invalidDate: {
      en: 'Date is invalid.',
      de: 'Datum ist ungültig.',
    },
    sameIBAN: {
      en: 'Sender IBAN same as recipient.',
      de: 'Sender IBAN ist dieselbe wie beim Empfänger.',
    },
    invalidEndDate: {
      en: 'End date cannot be after start date.',
      de: 'Erste Ausführung nach letzter Ausführung.',
    },
    invalidTimeRange: {
      en: 'Invalid time range',
      de: 'Ungültiger Zeitbereich',
    },
    splitAmountTooHigh: {
      en: 'Split amount sum is too high.',
      de: 'Summe der Teilbeträge übersteigt Gesamtsumme.',
    },
    apportionedOnlyWhenHouseMoneySettlement: {
      en: 'Apportioned should be selected only if House money settlement is selected. ',
      de: 'Umlage sollte nur gewählt werden, wenn die Hausgeldabrechnung gewählt ist. ',
    },
    sumOfPercentagesNotOneHundred: {
      en: 'Sum of percentages is not 100%. ',
      de: 'Die Summe der Prozentsätze ist nicht 100%. ',
    },
    mustBeUnique: {
      en: 'Value must be unique',
      de: 'Der Wert muss eindeutig sein',
    },
    invalidLaborCost: {
      en: 'Amount too high',
      de: 'Betrag zu hoch',
    },
    invalidPhoneNumber: {
      en: 'Invalid phone number',
      de: 'Ungültige Telefonnummer',
    },
    nonNumericValues: {
      en: 'Field must not contain non-numeric values',
      de: 'Das Feld darf keine nicht numerischen Werte enthalten',
    },
    notLeafAccount: {
      en: 'Account is divided.',
      de: 'Sachkonto ist unterteilt.',
    },
    emptyAccountCode: {
      en: 'Invalid account',
      de: 'Ungültiges Konto',
    },
    invalidPaymentTypeForOffboardedProperty: {
      en: 'Standing order is forbidden for offboarded property',
      de: 'Daueraufträge sind für abgegebene Objekte nicht erlaubt',
    },
  },
  enums: {
    distributionMode: {
      MEA: {
        en: 'MEA',
        de: 'MEA',
      },
      UNITS: {
        en: 'Units',
        de: 'Einheiten',
      },
      PERSONS: {
        en: 'People',
        de: 'Personen',
      },
      HEATING_AREA: {
        en: 'Heating area',
        de: 'Heizfläche',
      },
      AREA: {
        en: 'Area',
        de: 'Fläche',
      },
      INDIVIDUAL: {
        en: 'Individual',
        de: 'Individuell',
      },
      DISTRIBUTION_VALUE: {
        en: 'Distribution value',
        de: 'Verteilungswert',
      },
      HEATING_COST: {
        en: 'Heating cost',
        de: 'lt. Heizkostenabr.',
      },
      DIRECT_COST: {
        en: 'Direct cost',
        de: 'Direkte Kosten',
      },
      UNDEFINED: {
        en: 'Undefined',
        de: 'Nicht definiert',
      },
    },
  },
};
