import moment from 'moment';
import { useContext, useMemo } from 'react';
import { PropertyListContext } from 'contexts/PropertyListContext';
import { PropertyBankAccountProjectionDtoPropertyAccountTypesEnum } from 'api/accounting';
import { EconomicPlanContext } from '../../services/EconomicPlanContext';
import { economicPlanTranslations } from '../../economicPlanTranslations';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { useFindOverlappingEconomicPlans } from '../../services/useFindOverlappingEconomicPlans';

export const useEconomicPlanActivationModal = () => {
  const economicPlanContext = useContext(EconomicPlanContext);
  const propertyListContext = useContext(PropertyListContext);

  if (economicPlanContext === undefined) {
    throw new Error('useEconomicPlanActivationModal must be used within an EconomicPlanContext');
  }

  if (propertyListContext === undefined) {
    throw new Error('useEconomicPlanActivationModal must be used within a PropertyListProvider');
  }

  const { economicPlan } = economicPlanContext;
  const { selectedProperty } = useContext(PropertyListContext);

  const { findOverlappingEconomicPlans } = useFindOverlappingEconomicPlans();
  const { tl } = useContext(LanguageContext);


  return useMemo(() => {
    if (!economicPlan.data?.startDate) {
      return { listItems: [], hasHouseMoneyBankAccount: false };
    }

    const economicPlanStartDate = moment(economicPlan.data?.startDate);
    const economicPlanEndDateOrToday = moment(economicPlan.data?.endDate);
    const hasHouseMoneyBankAccount = (selectedProperty.data.bankAccounts
      ?.filter(ba => ba.propertyAccountTypes.includes(PropertyBankAccountProjectionDtoPropertyAccountTypesEnum.HOUSE)) ?? [])
      .length > 0;

    const startOfNextMonth = moment().set('date', 1).add(1, 'month');
    const today = moment();
    const explanations:string[] = [];

    if (economicPlanStartDate.isBefore(today)) {
      if (findOverlappingEconomicPlans().length > 0) {
        explanations.push(...tl(economicPlanTranslations.economicPlan.activationModal.economicPlanPastCorrectionsActivationText)(economicPlanStartDate.format('DD.MM.YYYY'), economicPlanEndDateOrToday.format('DD.MM.YYYY')));
      } else {
        explanations.push(...tl(economicPlanTranslations.economicPlan.activationModal.economicPlanPastActivationText)(economicPlanStartDate.format('DD.MM.YYYY'), economicPlanEndDateOrToday.format('DD.MM.YYYY')));
      }
    } else {
      explanations.push(...tl(economicPlanTranslations.economicPlan.activationModal.economicPlanFutureActivationText)(economicPlanStartDate.format('DD.MM.YYYY')));
    }

    if (typeof economicPlan?.data?.endDate === 'undefined' || moment(economicPlan.data.endDate).isAfter(today)) {
      if (hasHouseMoneyBankAccount) {
        const firstExchangeGenerationMonth = moment.max(economicPlanStartDate, startOfNextMonth);
        explanations.push(tl(economicPlanTranslations.economicPlan.activationModal.postingGenerationText)(firstExchangeGenerationMonth?.format('DD.MM.YYYY')));
      } else {
        explanations.push(tl(economicPlanTranslations.economicPlan.activationModal.missingHouseMoneyBankAccountListItem));
      }
    }

    return {
      listItems: explanations,
      hasHouseMoneyBankAccount,
    };
  }, [economicPlan.data?.startDate, selectedProperty.data.bankAccounts, findOverlappingEconomicPlans]);
};
